import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import { useNavigatorLanguage } from '@/plugins/Composables/useNavigatorLanguage'

import AuthRoutes from './_auth'
import GuestRoutes from './_guests'
import AdminRoutes from './_admin'
import UserRoutes from './_user'
import KolAdminRoutes from './_kolAdmin'
import CreatorRoutes from './_creator'
import StaticRoutes from './_staticRoutes'
const DEFAULT_TRANSITION = 'fade';

const routes = [
    ...AuthRoutes,
    ...CreatorRoutes,
    ...GuestRoutes,
    ...AdminRoutes,
    ...KolAdminRoutes,
    ...UserRoutes,
    // {
    //   path: '/about',
    //   name: 'about',
    // beforeEnter: [authMiddleware, roleMiddleware],
    // meta: {
    //   authorize: [Roles.SuperAdmin, Roles.User]
    // },
    //   component: () => import('../views/AboutView.vue')
    // }
    ...StaticRoutes,

    // will match everything and put it under `$route.params.pathMatch`
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/Errors/404.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    base: import.meta.env.BASE_URL,

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes,
})
const { setLanguage } = useNavigatorLanguage()


router.beforeResolve((to, from, next) => {
    setLanguage()

    next()
})

router.beforeEach((to, from, next) => {

    let transitionName = to.meta.transition || from.meta.transition;

    if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }
    to.meta.transition = transitionName || DEFAULT_TRANSITION;

    next();
})

router.afterEach((to, from) => {
    NProgress.done()
    // useLoadingStore().setLoading(false)
    let transitionName = to.meta.transition || from.meta.transition;

    if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }
    to.meta.transition = transitionName || DEFAULT_TRANSITION;

})
export default router