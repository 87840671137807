const Roles = {
    SuperAdmin: 'Super Admin',
    User: 'User',
    Creator: 'Creator',
    CreatorManager: 'Creator Manager',
    AccountManager: 'Account Manager',
    Guest: 'Guest',
}

function findUserRole(_role) {
    return Object.keys(Roles).find((key) => Roles[key] === _role)
}

function toAvailableFilterArray() {
    return Object.entries(Roles)
        .map(([key, value]) => {
            return {
                value: value,
                title: value,
            }
        })
        .sort(function (a, b) {
            if (a.title > b.title) {
                return 1
            }
            if (a.title < b.title) {
                return -1
            }
            return 0
        })
}

const RolesHelper = {
    Roles,
    toAvailableFilterArray,
    findUserRole,
}

export default RolesHelper