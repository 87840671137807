import { NOTIFICATION_TYPE, useNotificationStore } from '@/stores/notification'

import i18n from '@/translation'
const { t } = i18n.global

class NotificationService {
    /**
     * @description Fire Confirmation Sweetalert Modal
     * @param message | String | message you to display
     * @param body | String | message you to display
     * @param confirm | String | text of the button to confirm
     * @param cancel | String | text of the button to cancel
     * @returns {Promise}
     */
    static async notifyUserConfirmation(
        message = '',
        confirm = 'Submit',
        cancel = 'Cancel',
        body = ''
    ) {
        const notificationStore = useNotificationStore()

        return new Promise((resolve, reject) => {
            function onConfirm(result) {
                resolve(result)
            }

            function onClose() {
                reject('closed')
            }

            notificationStore.showNotification({
                type: NOTIFICATION_TYPE.WARNING,
                asToast: false,
                content: message,
                body: body,
                submitText: confirm,
                cancelText: cancel,
                onConfirm: onConfirm,
                onClose: onClose,
            })
        })
    }

    /**
     * @description Fire Error Sweetalert Modal
     * @returns {Promise}
     * @param message
     * @param confirm
     */
    static async notifyUserError(message = '', confirm = '') {
        if (confirm === '') {
            confirm = t('globals.tryAgain')
        }
        const notificationStore = useNotificationStore()

        return new Promise((resolve) => {
            function onConfirm(result) {
                resolve(result)
            }

            function onClose(result) {
                resolve(result)
            }

            notificationStore.showNotification({
                type: NOTIFICATION_TYPE.ERROR,
                asToast: false,
                content: message,
                submitText: confirm,
                onConfirm: onConfirm,
                onClose: onClose,
            })
        })
    }

    /**
     * @description Fire Error Sweetalert Modal
     * @param errors
     * @param confirm
     * @returns {Promise}
     */
    static async notifyUserErrors(errors = [], confirm = '') {
        if (confirm === '') {
            confirm = t('globals.tryAgain')
        }
        if (typeof errors === 'object') {
            errors = Object.values(errors)
        }

        const content =
            errors.length > 1
                ? errors
                      .map((error) => `<li key=${error}>${error}</li>`)
                      .join('')
                : errors[0]

        const notificationStore = useNotificationStore()

        return new Promise((resolve) => {
            function onConfirm(result) {
                resolve(result)
            }

            function onClose(result) {
                resolve(result)
            }

            notificationStore.showNotification({
                type: NOTIFICATION_TYPE.ERROR,
                asToast: false,
                content: content,
                submitText: confirm,
                onConfirm: onConfirm,
                onClose: onClose,
            })
        })
    }

    /**
     * @description Fire Error Sweetalert Modal
     * @returns {Promise}
     * @param message
     * @param options
     */
    static async notifyUserSuccess(message = '', options = {}) {
        const notificationStore = useNotificationStore()

        return new Promise((resolve) => {
            function onConfirm(result) {
                resolve(result)
            }

            function onClose(result) {
                resolve(result)
            }

            notificationStore.showNotification({
                type: NOTIFICATION_TYPE.SUCCESS,
                asToast: true,
                content: message,
                submitText: options?.onConfirm ? 'Go' : null,
                onConfirm: options?.onConfirm ?? onConfirm,
                onClose: options?.onClose ?? onClose,
            })
        })
    }

    /**
     * @description Fire Error Sweetalert Modal
     * @returns {Promise}
     * @param message
     * @param options
     */
    static async notifyUserInfo(message = '', options = {}) {
        const notificationStore = useNotificationStore()

        return new Promise((resolve) => {
            function onConfirm(result) {
                resolve(result)
            }

            function onClose(result) {
                resolve(result)
            }

            notificationStore.showNotification({
                type: NOTIFICATION_TYPE.INFO,
                asToast: false,
                content: message,
                submitText: options?.onConfirm ? 'Go' : null,
                onConfirm: options?.onConfirm ?? onConfirm,
                onClose: options?.onClose ?? onClose,
            })
        })
    }
}

export default NotificationService