<template>
    <div
        v-show="!hidden"
        class="col position-relative h-100 justify-content-bottom"
        :class="{'' : $breakpoints.lgAndUp.value, 'd-flex': !hidden}"
        style="/*background-color: #18181a;*/"
    >
        <div class="conversation-container" :class="{'justify-content-end': userStore.user.role === 'Guest'}">
            <div v-if="userStore.user.role !== 'Guest'" class="conversation-section">
                <!-- Title -->
                <div v-if="$breakpoints.lgAndUp.value" class="conversation-content pt-3">
                    <h4 class="card-title text-gray text-center">Chat</h4>
                    <!-- <button
                        type="button"
                        class="text-gray-500 fs-5 position-absolute top-0 end-0 mt-2 me-3"
                        @click="hidden"
                    >
                        <i class="fa fa-close menu-close"></i>
                    </button> -->
                </div>

                <!-- Conversation box -->
                <div
                    id="conversation-box"
                    ref="conversationBox"
                    class="conversation-scrollable-content conversation-content mt-2 px-2 text-white"
                >
                    <!-- See new messages -->
                    <div
                        v-if="seeLast"
                        class="position-absolute bg-secondary text-center fs-7 border border-black rounded-5 py-2 shadow newMessages"
                    >
                        <button class="p-0 m-0" @click="scrollToBottom('smooth')">
                            {{ t('user.live.seeLast') }}
                            <i class="fa-solid fa-chevron-down ms-2"></i>
                        </button>
                    </div>
                    <!-- Messages -->
                    <template v-if="!!JwtService.getToken()">
                        <div
                            v-for="(mess, index) in messages"
                            id="box"
                            :key="index"
                            :class="{
                                'pe-5': $breakpoints.mdAndDown.value,
                            }"
                            class="d-flex align-items-top py-1 overflow-y-hidden"
                        >
                            <!-- Muted/Unmuted -->
                            <template v-if="mess.type === 'ban' || mess.type === 'unban'">
                                <div class="mx-2" style="width: 35px">
                                    <div class="avatar-cropper">
                                        <img src="/images/menu-masterclass-white.svg" class="avatar-pic" />
                                    </div>
                                </div>
                                <div class="text-start flex-grow-1">
                                    <span class="me-3 text-gray">Bot</span>
                                    <span
                                        :class="
                                            mess.type === 'ban'
                                                ? 'fw-light fst-italic  fs-7 text-danger'
                                                : 'fw-light fst-italic fs-7  text-secondary'
                                        "
                                    >
                                        {{ mess.text }}
                                        <span v-if="mess.type === 'ban'" class="fst-normal">🤬</span>
                                        <span v-else class="fst-normal">😁</span>
                                    </span>
                                </div>
                            </template>

                            <!-- Joined/Left -->
                            <template v-if="mess.type === 'joined' || mess.type === 'left'">
                                <div class="mx-2" style="width: 35px">
                                    <div class="avatar-cropper">
                                        <img src="/images/menu-masterclass-white.svg" class="avatar-pic" />
                                    </div>
                                </div>
                                <div class="text-start flex-grow-1">
                                    <span class="me-3 text-gray">Bot</span>
                                    <span
                                        :class="
                                            mess.type === 'joined'
                                                ? 'fw-light fst-italic  fs-7 text-success'
                                                : 'fw-light fst-italic fs-7  text-warning'
                                        "
                                    >
                                        {{ mess.text }}
                                        <span v-if="mess.type === 'joined'" class="fst-normal">👍</span>
                                        <span v-else class="fst-normal">👎</span>
                                    </span>
                                </div>
                            </template>

                            <!-- Comment -->
                            <template v-if="mess.type === 'comment'">
<!--                                <div class="mx-2" style="width: 35px">-->
<!--                                    <div class="avatar-cropper">-->
<!--                                        <img-->
<!--                                            :src="mess.avatar -->
<!--                                                ? mess.avatar -->
<!--                                                : 'https://ui-avatars.com/api/?name=' + mess.name + '.png&background=random'-->
<!--                                            "-->
<!--                                            class="avatar-pic"-->
<!--                                        />-->
<!--                                    </div>-->
<!--                                </div>-->
                                <div class="text-start ps-1 flex-grow-1 overflow-hidden">
                                    <span class="me-1" :class="mess.isCreator ? 'text-tertiary fw-bold' : 'text-gray-500'">
                                        {{ mess.name }}:
                                    </span>
                                    <br v-if="$breakpoints.mdAndDown.value" />

                                    {{ mess.text }}
                                </div>
                                <div v-if="isCreator && userStore.user.id !== mess.ikid" class="actions me-2">
                                    <button
                                        v-if="!bannedUsers.find((ban) => ban.id === mess.ikid)"
                                        @click="banUser(mess.ikid); $emit('user:banned', mess)"
                                    >
                                        <i class="fa-solid fa-volume-xmark text-danger"></i>
                                    </button>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>

                <!-- Chat box -->
                <div class="conversation-content p-2 position-relative">


                    <!-- showNotLoggedMessage -->
                    <div
                        v-if="showNotLoggedMessage"
                        class="d-flex justify-content-center py-1 overflow-y-hidden position-absolute start-0 alert alert-info w-100"
                        style="bottom: 40px"
                    >
                        {{ $t('user.live.notStarted') }}
                    </div>
                    <div
                        v-if="showRateLimitMessage"
                        class="d-flex justify-content-center py-1 overflow-y-hidden position-absolute start-0 alert alert-warning w-100"
                        style="bottom: 40px"
                    >
                        {{ $t('user.live.chatRateLimit') }}
                    </div>
                    <div v-if="emojiVisible" class="card shadow emoji-picker">
                        <div class="card-body p-1 ms-2">
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💯')">💯</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🔢')">🔢</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😀')">😀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😬')">😬</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😁')">😁</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😂')">😂</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤣')">🤣</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥳')">🥳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😃')">😃</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😄')">😄</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😅')">😅</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😆')">😆</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😇')">😇</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😉')">😉</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😊')">😊</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙂')">🙂</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙃')">🙃</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('☺️')">☺️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😋')">😋</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😌')">😌</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😍')">😍</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥰')">🥰</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😘')">😘</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😗')">😗</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😙')">😙</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😚')">😚</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😜')">😜</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤪')">🤪</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤨')">🤨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧐')">🧐</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😝')">😝</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😛')">😛</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤑')">🤑</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤓')">🤓</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😎')">😎</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤩')">🤩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤡')">🤡</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤠')">🤠</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤗')">🤗</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😏')">😏</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😶')">😶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😐')">😐</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😑')">😑</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😒')">😒</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙄')">🙄</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤔')">🤔</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤥')">🤥</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤭')">🤭</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤫')">🤫</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤬')">🤬</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤯')">🤯</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😳')">😳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😞')">😞</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😟')">😟</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😠')">😠</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😡')">😡</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😔')">😔</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😕')">😕</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙁')">🙁</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('☹')">☹</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😣')">😣</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😖')">😖</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😫')">😫</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😩')">😩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥺')">🥺</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😤')">😤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😮')">😮</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😱')">😱</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😨')">😨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😰')">😰</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😯')">😯</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😦')">😦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😧')">😧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😢')">😢</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😥')">😥</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤤')">🤤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😪')">😪</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😓')">😓</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥵')">🥵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥶')">🥶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😭')">😭</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😵')">😵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😲')">😲</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤐')">🤐</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤢')">🤢</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤧')">🤧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤮')">🤮</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😷')">😷</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤒')">🤒</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤕')">🤕</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🥴')">🥴</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😴')">😴</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💤')">💤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💩')">💩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😈')">😈</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👿')">👿</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👹')">👹</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👺')">👺</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💀')">💀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👻')">👻</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👽')">👽</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤖')">🤖</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😺')">😺</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😸')">😸</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😹')">😹</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😻')">😻</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😼')">😼</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😽')">😽</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙀')">🙀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😿')">😿</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('😾')">😾</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤲')">🤲</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙌')">🙌</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👏')">👏</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👋')">👋</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤙')">🤙</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👍')">👍</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👎')">👎</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👊')">👊</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('✊')">✊</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤛')">🤛</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤜')">🤜</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('✌')">✌</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👌')">👌</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('✋')">✋</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤚')">🤚</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👐')">👐</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💪')">💪</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙏')">🙏</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦶')">🦶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦵')">🦵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤝')">🤝</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('☝')">☝</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👆')">👆</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👇')">👇</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👈')">👈</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👉')">👉</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🖕')">🖕</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🖐')">🖐</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤟')">🤟</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤘')">🤘</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤞')">🤞</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🖖')">🖖</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('✍')">✍</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤳')">🤳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💅')">💅</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👄')">👄</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦷')">🦷</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👅')">👅</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👂')">👂</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👃')">👃</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👁')">👁</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👀')">👀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧠')">🧠</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👤')">👤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👥')">👥</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🗣')">🗣</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👶')">👶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧒')">🧒</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👦')">👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👧')">👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧑')">🧑</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨')">👨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩')">👩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👱‍♀️')">👱‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👱')">👱</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧔')">🧔</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧓')">🧓</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👴')">👴</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👵')">👵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👲')">👲</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧕')">🧕</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👳‍♀️')">👳‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👳')">👳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👮‍♀️')">👮‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👮')">👮</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👷‍♀️')">👷‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👷')">👷</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💂‍♀️')">💂‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💂')">💂</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🕵️‍♀️')">🕵️‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🕵')">🕵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍⚕️')">👩‍⚕️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍⚕️')">👨‍⚕️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🌾')">👩‍🌾</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🌾')">👨‍🌾</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🍳')">👩‍🍳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🍳')">👨‍🍳</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🎓')">👩‍🎓</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🎓')">👨‍🎓</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🎤')">👩‍🎤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🎤')">👨‍🎤</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🏫')">👩‍🏫</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🏫')">👨‍🏫</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🏭')">👩‍🏭</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🏭')">👨‍🏭</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍💻')">👩‍💻</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍💻')">👨‍💻</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍💼')">👩‍💼</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍💼')">👨‍💼</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🔧')">👩‍🔧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🔧')">👨‍🔧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🔬')">👩‍🔬</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🔬')">👨‍🔬</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🎨')">👩‍🎨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🎨')">👨‍🎨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🚒')">👩‍🚒</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🚒')">👨‍🚒</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍✈️')">👩‍✈️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍✈️')">👨‍✈️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍🚀')">👩‍🚀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍🚀')">👨‍🚀</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍⚖️')">👩‍⚖️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍⚖️')">👨‍⚖️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦸‍♀️')">🦸‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦸‍♂️')">🦸‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦹‍♀️')">🦹‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🦹‍♂️')">🦹‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤶')">🤶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🎅')">🎅</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧙‍♀️')">🧙‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧙‍♂️')">🧙‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧝‍♀️')">🧝‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧝‍♂️')">🧝‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧛‍♀️')">🧛‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧛‍♂️')">🧛‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧟‍♀️')">🧟‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧟‍♂️')">🧟‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧞‍♀️')">🧞‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧞‍♂️')">🧞‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧜‍♀️')">🧜‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧜‍♂️')">🧜‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧚‍♀️')">🧚‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧚‍♂️')">🧚‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👼')">👼</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤰')">🤰</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤱')">🤱</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👸')">👸</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤴')">🤴</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👰')">👰</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤵')">🤵</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🏃‍♀️')">🏃‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🏃')">🏃</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🚶‍♀️')">🚶‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🚶')">🚶</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💃')">💃</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🕺')">🕺</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👯')">👯</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👯‍♂️')">👯‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👫')">👫</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👬')">👬</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👭')">👭</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙇‍♀️')">🙇‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙇')">🙇</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤦‍♂️')">🤦‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤦‍♀️')">🤦‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤷')">🤷</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🤷‍♂️')">🤷‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💁')">💁</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💁‍♂️')">💁‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙅')">🙅</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙅‍♂️')">🙅‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙆')">🙆</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙆‍♂️')">🙆‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙋')">🙋</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙋‍♂️')">🙋‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙎')">🙎</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙎‍♂️')">🙎‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙍')">🙍</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🙍‍♂️')">🙍‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💇')">💇</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💇‍♂️')">💇‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💆')">💆</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💆‍♂️')">💆‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧖‍♀️')">🧖‍♀️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('🧖‍♂️')">🧖‍♂️</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💑')">💑</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍❤️‍👩')">👩‍❤️‍👩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍❤️‍👨')">👨‍❤️‍👨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('💏')">💏</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍❤️‍💋‍👩')">👩‍❤️‍💋‍👩</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍❤️‍💋‍👨')">👨‍❤️‍💋‍👨</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👪')">👪</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👩‍👧')">👨‍👩‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👩‍👧‍👦')">👨‍👩‍👧‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👩‍👦‍👦')">👨‍👩‍👦‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👩‍👧‍👧')">👨‍👩‍👧‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👩‍👦')">👩‍👩‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👩‍👧')">👩‍👩‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👩‍👧‍👦')">👩‍👩‍👧‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👩‍👦‍👦')">👩‍👩‍👦‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👩‍👧‍👧')">👩‍👩‍👧‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👨‍👦')">👨‍👨‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👨‍👧')">👨‍👨‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👨‍👧‍👦')">👨‍👨‍👧‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👨‍👦‍👦')">👨‍👨‍👦‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👨‍👨‍👧‍👧')">👨‍👨‍👧‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👦')">👩‍👦</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👧')">👩‍👧</a></div>
                            <div class="emoji-table"><a href="#" @click.prevent="emoji('👩‍👧‍👦')">👩‍👧‍👦</a></div>
                        </div>
                    </div>
                    <div class="d-flex position-relative">

                        <!-- v-if="muted.find((ban) => ban.id === userStore.user.id)"-->

                        <div
                            v-if="getIsBanned()"
                            class="muted fw-bold flex-grow-1 text-center text-gray border border-gray rounded-2 p-3"
                        >
                            <h6 class="text-white">
                                <i class="fa-solid fa-hourglass-start fs-7 me-2"></i>
                                {{ $t('user.live.mutedTitle') }}
                            </h6>
                            <span class="fw-normal">
                                {{ $t('user.live.mutedHelper') }}
                            </span>
                        </div>
                        <template v-else>
                            <div id="emoji-toggler" class="emoji">
                                <button class="me-1" @click="toggleEmoji">
                                    <i class="fa-regular fa-face-smile text-gray fs-4 mt-1"></i>
                                </button>
                            </div>
                            <div class="input flex-grow-1">
                                <input
                                    ref="input"
                                    v-model="message"
                                    type="text"
                                    class="form-control p-1 px-2 bg-gray border-0"
                                    :maxlength="isCreator ? 1500 : 150"
                                    :max="isCreator ? 1500 : 150"
                                    @keyup.enter="send"
                                />
                            </div>
                            <div class="icon">
                                <button :disabled="disabled" @click="send">
                                    <i class="fa-regular fa-paper-plane text-gray fs-4 mt-1"></i>
                                </button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else class="text-gray text-center px-3 mt-4">
                <span class="btn btn-tertiary btn-lg cursor-pointer" @click.prevent="registerCtaStore.showModal()">
                    <i class="fa-solid fa-lock me-1"></i>
                    {{ $t('messenger.guest.unlockChat') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, watch, nextTick, onUnmounted} from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/user'
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import AvatarHelper from "@/helpers/AvatarHelper";
import {useCreatorStore} from "@/stores/frontend/creator";
// import {useReverbClient} from "@/plugins/Composables/useRevebClient";
import JwtService from "@/services/JwtService";
import {useAblyClient} from "@/plugins/Composables/useAblyClient";

/** Locales **/
const { t } = useI18n()

/** Store **/
const userStore = useUserStore()
const creatorStore = useCreatorStore()
const registerCtaStore = useRegistrationCtaModalStore()

/** Init parameters **/
const props = defineProps({
    isCreator: {
        type: Boolean,
        default: false,
        required: true,
    },
    bannedUsers: {
        type: Array,
        default: () => []
    },
    liveStreamingId: {
        type: String,
        required: true,
    },
    isPublished: {
        type: Boolean,
        default: false
    },
    isBanned: {
        type: Boolean,
        default: false
    },
    hidden: {
        type: Boolean,
        default: true
    }
})

const seeLast = ref(false)
const conversationBox = ref()
const emojiVisible = ref(false)
const message = ref('')
const input = ref(null)
const disabled = ref(false)


// WEBSOCKETS
const emits = defineEmits([
    'update:viewers-count',
    'user:banned'
])
const {
    messages,
    viewersCount,
    sendClientMessage,
    joinPresenceChannel,
    banUser,
    hasJoined,
    setIsBanned,
    getIsBanned,
    isBanned: isBannedReverb,
} = useAblyClient({
    channel: `chat.${useCreatorStore().creator.id}`,
    type: 'presence',
    userId: userStore.user.id,
    autoConnect: !props.isCreator && userStore.user.role !== 'Guest',
})

let unwatchViewers = () => {}
let unwatchPublished = () => {}
let unwatchBanned = () => {}

// CREATOR WATCHERS
if (props.isCreator) {
    // VIEWERS COUNTER
    unwatchViewers = watch(
        viewersCount,
        (_viewers) => {
            emits('update:viewers-count', _viewers)
        }, {
            immediate: true
        }
    )

    // STARTED STREAM
    // unwatchPublished = watch(
    //     () => props.isPublished,
    //     (_published) => {
    //         if (_published) {
    //             joinPresenceChannel(`chat.${useCreatorStore().creator.id}`)
    //         }
    //     }
    // )
}
// USERS WATCHERS
else {

    unwatchBanned = watch(
        isBannedReverb,
        (_isBanned) => {
            emits('user:banned', _isBanned)
        }
    )
    unwatchPublished = watch(
        hasJoined,
        (_joined) => {
            if (_joined) {
                setTimeout(() => {
                    messages.value.push({
                        text: t('creator.liveStreaming.publish.welcome'),
                        createdAt: new Date(),
                        isCreator: true,
                        type: 'comment',
                        ikid: creatorStore.creator.id,
                        avatar: AvatarHelper.getCreatorAvatar(creatorStore.creator.avatar, creatorStore.creator.username),
                        name: creatorStore.creator.username,
                    })
                }, 1000)
            }
        }
    )
}

const showNotLoggedMessage = ref(false)
let timeoutNotLoggedMessage = null

/** Methods **/
const notLoggedMessage = () => {
    showNotLoggedMessage.value = true
    clearTimeout(timeoutNotLoggedMessage)
    timeoutNotLoggedMessage = setTimeout(() => {
        showNotLoggedMessage.value = false
    }, 3000)
}

const showRateLimitMessage = ref(false)
function send() {
    if (disabled.value) {
        showRateLimitMessage.value = true
        return;
    }

    if (!hasJoined.value) {
        notLoggedMessage()
    }
    else if (message.value !== '' && hasJoined.value) {
        let newMessage = {
            text:  message.value,
            createdAt: new Date(),
            isCreator:  props.isCreator,
            type: 'comment',
            ikid: userStore.user.id,
            avatar: AvatarHelper.getAvatar(userStore.avatar, userStore.user.username),
            name: userStore.user.username,
        }

        sendClientMessage(newMessage)

        messages.value.push(newMessage)
        message.value = ''
        nextTick(() => {
            input.value.focus()
        })
    }

    // RATE LIMIT 2 seconds
    if (!props.isCreator) {
        disabled.value = true
        setTimeout(() => {
            disabled.value = false
            showRateLimitMessage.value = false
        }, 2000)
    }
}

function toggleEmoji() {
    emojiVisible.value = !emojiVisible.value
}

function emoji(_emoji) {
    message.value = `${message.value} ${_emoji}`
}

function scrollToBottom(animation) {
    seeLast.value = false
    if (messages.value.length > 0) {
        let element = document.getElementById("conversation-box");
        element.scrollTop = element.scrollHeight;
        // conversationBox.value?.lastElementChild?.scrollIntoView({ behavior: animation })
        // window.scrollTo(0,0)
    }
}

const unwatchMessages = watch(
    messages,
    () => {
        nextTick(() => {
            if (!seeLast.value) {
                scrollToBottom()
            }
            // Check status of users
            // settings(props.liveStreamingId).then((data) => {
            //     if (data.banned !== undefined) {
            //         muted.value = data.banned
            //     }
            // })
        })
    },
    { deep: true }
)

// WEBSOCKETS
onMounted(() => {
    if (userStore.user.role !== 'Guest') {
        const elem = document.querySelector('#conversation-box')
        elem.onscroll = function () {
            let conversationBoxHeight = document.getElementById('conversation-box').clientHeight
            let csScrollHeight = elem.scrollHeight - conversationBoxHeight
            if (elem.scrollTop < csScrollHeight - 100) {
                seeLast.value = true
            } else {
                seeLast.value = false
            }
        }

        setIsBanned(props.isBanned)
    }
})

onUnmounted(() => {
    unwatchMessages()
    if (props.isCreator) {
        unwatchViewers()
    }
    else {
        unwatchPublished()
        unwatchBanned()
    }
})
</script>

<style scoped>
.emoji-picker {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 45px;
    height: 90px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.emoji-table {
    /* border: 1px solid red; */
    width: 36px;
    text-align: center;
    float: left;
}
.btnEmoji {
    cursor: pointer !important;
}

@media (min-width: 650px) {
    div#box:nth-child(odd) {
        /* background-color: rgb(64, 64, 64); */
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
#conversation-box::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#conversation-box {
    /* height: 475px; */
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.newMessages {
    width: 200px;
    left: 50%;
    margin-left: -100px;
    bottom: 70px;
}

.newMessages button {
    color: #333;
    font-weight: bold;
}
.newMessages button:hover {
    color: black;
}
.conversation-container {
    /* give the outermost container a predefined size */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 14px;

    display: flex;
    flex-direction: column;
}

.conversation-section {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    /* for Firefox */
    min-height: 0;
}

.conversation-scrollable-content {
    flex-grow: 1;

    overflow: auto;

    /* for Firefox */
    min-height: 0;
}
.avatar-cropper {
    position: relative;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
}
.avatar-pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>
