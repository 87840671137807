import { computed, ref } from 'vue'
import { useUserStore } from '@/stores/user'
import {useCreatorStore} from "@/stores/frontend/creator";

export const authCreatorRegistrationMiddleware = async (to, from, next) => {

    const {getOnboardingData, isCreator, isAuthenticated} = useUserStore()
    if (isAuthenticated) {
        const {needsOnboarding} = useCreatorStore()

        let onboardingRoute = (isCreator) ? 'kol-admin-onboarding' : 'user-onboarding'

        // Get onboarding information
        const onboardingData = await getOnboardingData(true)

        // If creator and onboarding is not done
        if (isCreator && onboardingData.status === 'pending' && needsOnboarding()) {
            // window.history.forward(1)

            return next({
                name: onboardingRoute,
                query: {
                    step: onboardingData.step,
                },
            })
        }
    }

    return next()
}