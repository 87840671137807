import { defineStore } from 'pinia'
import {ref} from "vue";
import {useUserStore} from "@/stores/user";
import RolesHelper from "@/helpers/Roles";
import router from "@/router";
import {useCreatorStore} from "@/stores/frontend/creator";
import {useLoadingStore} from "@/stores/frontend/loading";

const EMPTY_MODAL = {
    title: '',
    submit: '',
    cancel: false,
    onSubmit: () => {},
    onCancel: () => {},
}

const specificCreator = import.meta.env.VITE_CREATOR_NO_SUB
const eventID = import.meta.env.VITE_CREATOR_NO_SUB_EVENT_ID

export const useRegistrationCtaModalStore = defineStore('registrationCtaModal', () => {

    const hasActiveModal = ref(false)

    const modal = ref(EMPTY_MODAL)
    const errors = ref({})

    const userStore = useUserStore()

    let onDisplayCallbacks = []
    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    let onHideCallbacks = []
    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    function showModal(creator = null) {
        // console.log(userStore.user.role)

        if (creator !== null){
            useUserStore().setSelectedCreator(creator)
        }

        if (
            userStore.user.role === RolesHelper.Roles.User
            && (
                !userStore.user.has_subscription
                || (
                    userStore.user.has_subscription
                    && userStore.user.creators_subscribed_to.length >= 1
                    && !userStore.user.creators_subscribed_to.find((creator) => creator.id === useCreatorStore().creator.id)
                )
            )
            && !userStore.user.is_creator
        ){

            useLoadingStore().setLoading(true, true)
            // if (creator !== null && (!useCreatorStore().creator?.id || useCreatorStore().creator?.id === null)){
            //     useUserStore().setSelectedCreator(creator)
            // }

            // if (parseInt(useCreatorStore().creator.id) !== parseInt(specificCreator)){
            setTimeout(() => {
                router.push({
                    name: 'payment',
                    params: {
                        creatorSlug: useCreatorStore().creator.slug
                    }
                })
            }, 400)
            // SPECIFIC NOT NEEDED ANYMORE
            // }
            // else {
            //     router.push({
            //         name: 'user-live-event-view',
            //         params: {
            //             uuid: eventID
            //         }
            //     })
            // }

        }
        else {
            hasActiveModal.value = true
            onDisplayCallbacks.forEach((callback) => callback())
        }
    }

    function hideModal() {
        hasActiveModal.value = false
        errors.value = {}

        onHideCallbacks.forEach((callback) => callback())
    }

    function setErrors(values) {
        errors.value = values
    }

    const isSubmitting = ref(false)
    function setSubmitting(_submitting = true) {
        isSubmitting.value = _submitting
    }

    const isSubmitted = ref(false)
    function setSubmitted(_submitted = true) {
        isSubmitted.value = _submitted
    }

    return {
        hasActiveModal,
        modal,
        errors,
        isSubmitted,

        setErrors,
        onDisplay,
        onHide,
        showModal,
        hideModal,
        setSubmitting,
        setSubmitted,
    }
})
