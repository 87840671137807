<template>
    <base-field
        :id="id"
        :name="name"
        :label="label"
        :error-message="errorMessage"
        :is-inline="props.inline"
        :is-switch="isSwitch"
        :class-name="isSwitch ? 'form-check form-switch' : ''"
        :required="required"
    >
        <input
            :id="id"
            v-model="value"
            type="checkbox"
            class="form-check-input"
            :class="[{ 'is-invalid': errorMessage }]"
            :name="name"
            :role="isSwitch ? 'switch' : ''"
            @blur="handleBlur"
        />
    </base-field>
</template>

<script setup>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
import BaseField from '@/components/Modals/Fields/BaseField.vue'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    inline: {
        type: Boolean,
        default: false,
    },
    isSwitch: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(['update:modelValue']);

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur } = useField(name, props.rules, {
    initialValue: props.modelValue,
    valueProp: props.modelValue,
    syncVModel: true,
})
</script>
