import {isAuthChecker} from "@/router/middleware/isAuthChecker";
import {authMiddleware} from "@/router/middleware/authMiddleware";
import {roleMiddleware} from "@/router/middleware/roleMiddleware";
import RolesHelper from "@/helpers/Roles";

const auth = [
    {
        path: '/',
        component: () => import('@/components/Layout/AuthLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                beforeEnter: [isAuthChecker],
                component: () => import('@/views/Auth/Login.vue'),
            },
            {
                path: 'account-verification',
                name: 'account-verification',
                component: () => import('@/views/Auth/AccountVerification.vue'),
            },
            {
                path: 'verify',
                name: 'verify',
                component: () => import('@/views/Auth/Verify.vue'),
            },
            {
                path: 'creator-select',
                name: 'creator-select',
                beforeEnter: [authMiddleware, roleMiddleware], //roleMiddleware
                meta: {
                    authorize: [RolesHelper.Roles.User],
                },
                component: () => import('@/views/Auth/CreatorSelect.vue'),
            },
            {
                path: 'blocked/:reason?',
                name: 'blocked',
                component: () => import('@/views/Auth/Blocked.vue'),
            },
        ],
    },
    {
        path: '/register',
        name: 'register',
        // component: () => import('@/views/Auth/Register.vue'),
        component: () => import('@/views/Auth/RegisterV2.vue'),
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import('@/views/Auth/Forgot.vue'),
    },
    {
        path: '/code',
        name: 'code',
        component: () => import('@/views/Auth/Code.vue'),
    },
    {
        path: '/reset',
        name: 'reset',
        component: () => import('@/views/Auth/Reset.vue'),
    },
]

export default auth
