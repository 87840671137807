<template>
    <div class="row workout-container p-3">
        <div v-if="!$breakpoints.mdAndDown.value" class="col-md-8 col-xs-12">
            <div class="header-content">
                <i class="fa-solid fa-star fs-6 text-secondary me-2"></i>
                <h1 class="workout-title">{{ props.title }}</h1>
            </div>
        </div>
        <div v-if="($breakpoints.mdAndDown.value && mobileSearch) || $breakpoints.lgAndUp.value" class="col-lg-4 col-12">
            <Form @submit="submitSearch">
                <BaseInput
                    v-model="search"
                    :placeholder="searchText"
                    name="searchString"
                    type="text"
                    :is-grouped="true"
                >
                    <template #postfix>
                        <button class="btn btn-primary rounded-end-3" type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                    </template>
                </BaseInput>
            </Form>
        </div>
    </div>
</template>

<script setup>
import BaseInput from '@/components/Form/BaseInput.vue'
import { Form } from 'vee-validate'
import {computed, ref} from 'vue'

import {useI18n} from "vue-i18n";

const { t } = useI18n()

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    mobileSearch: {
        type: Boolean,
        default: false,
    },
    subTitle: {
        type: String,
        default: '',
    },
    searchTitle: {
        type: String,
        default: '',
    },
})
const emit = defineEmits(['submit:search'])

const search = ref(null)

const searchText = computed(() => {
    return props.searchTitle !== ''
        ? props.searchTitle
        : t('user.workouts.search.placeholder')

})

const submitSearch = (value) => {
    emit('submit:search', value.searchString)
}
</script>

<style scoped>
.header-content {
    display: flex;
    align-items: center;
}
.header-content svg {
    width: 1.25rem;
    height: 1.125rem;
    margin-right: 0.5rem;
}
.workout-container {
    /* background: var(--masterclass-cover-background-color); */
    display: flex;
    align-items: center;
}
.workout-title {
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: var(--bs-dark);
    margin: 0;
}
</style>