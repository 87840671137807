<template>
    <div class="position-relative d-inline">
        <router-link
            v-if="isLink"
            :to="{path: `/${slug}`}"
            @mouseover="isHoverShown = true"
            @mouseleave="isHoverShown = false"
        >
            @{{slug}}
        </router-link>
        <span v-else class="link-body-emphasis">@{{slug}}</span>

        <div v-if="isLink && isHoverShown" class="position-absolute start-0" style="top: 1.5em; z-index: 55; width: 250px">
            <div class="card">
                 <div class="card-body d-flex align-items-center">
                     <!-- AVATAR -->
                     <div class="flex-grow-0 flex-shrink-0" style="flex-basis: 70px;">
                         <div class="avatar-cropper" style="max-height: 50px;max-width: 50px">
                             <img
                                 :src="creator.avatar"
                                 class="avatar-pic"
                                 alt="avatar">
                         </div>
                     </div>
                     <div class="d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center">
                         <div class="fs-6 fw-medium">
                             {{creator.username}}
                         </div>
                         <div class="fs-7">
                             <i>{{creator.slug}}</i>
                         </div>
                     </div>

                 </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";

defineProps({
    slug: {
        type: String,
        required: true,
    },
    creator: {
        type: Object,
        default: null,
    },
    isLink: {
        type: Boolean,
        default: false,
    }
})

const isHoverShown = ref(false)

</script>

<style lang="scss" scoped>
</style>
