<template>
    <div :style="{ height: height }"></div>
</template>

<script setup>
const props = defineProps({
    size: [String, Number],
})

const height = !isNaN(props.size) ? props.size + 'rem' : props.size
</script>
