import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'

export const NOTIFICATION_TYPE = {
    SUCCESS: 'SUCCESS',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    INFO: 'info',
}

const EMPTY_NOTIFICATION = {
    type: NOTIFICATION_TYPE.SUCCESS,
    asToast: false,
    content: '',
    submitText: '',
    cancelText: null,
    onConfirm: () => {},
    onClose: () => {},
}

export const useNotificationStore = defineStore('notification', () => {
    const hasActiveNotification = ref(false)
    const notification = ref(EMPTY_NOTIFICATION)
    let onDisplayCallbacks = []
    let onHideCallbacks = []
    const currentCounter = ref([])

    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    function showNotification(displayedNotification) {
        hasActiveNotification.value = true
        notification.value = displayedNotification
        onDisplayCallbacks.forEach((callback) => callback())
    }

    function hideNotification() {
        hasActiveNotification.value = false
        notification.value = EMPTY_NOTIFICATION

        onHideCallbacks.forEach((callback) => callback())
    }

    /**
     * Get all available User Topics notification
     * @returns {Promise<axios.AxiosResponse>}
     */
    function availableTopicsUser() {
        return ApiService.get('/enum/topics-notification-user')
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all available User Direct notification
     * @returns {Promise<axios.AxiosResponse>}
     */
    function availableDirectNotification(isCreator = false) {
        let url = isCreator ? 'direct-notification-creator' : 'direct-notification-user'
        return ApiService.get(`/enum/${url}`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all available User Direct notification
     * @returns {Promise<axios.AxiosResponse>}
     */
    function updateMyNotification(data) {
        return ApiService.put('/my/notifications', data)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get User notification settings
     * @param creatorUlid
     * @returns {Promise<axios.AxiosResponse>}
     */
    function notificationSettings(creatorUlid, isCreator) {
        let url = isCreator ? `/my/notifications/settings` : `/my/notifications/settings/${creatorUlid}`
        return ApiService.get(url)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        hasActiveNotification,
        currentCounter,
        notification,
        onDisplay,
        onHide,
        showNotification,
        hideNotification,

        availableTopicsUser,
        availableDirectNotification,
        updateMyNotification,
        notificationSettings,
    }
})