import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref, computed, watch } from 'vue'
import { useUserStore } from '@/stores/user'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'

const DEFAULT_WORKOUT_CREATING = {
    hasStarted: false,

    id: null,
    status: 'active',
    type: 'workout',
    rejected_reason: null,
    title: {},
    description: {},
    cover: null,
    is_scheduled: 0,
    is_paid: 0,
    is_pinned: 0,
    published_at: null,
    price: 0,
    workout: {
        id: null,
        category: null,
        level: null,
        calories_spend_in_kcals: null,
        average_heart_beat_per_minute: null,
        fat_burning_in_grams: null,
        recommended_work_out_time_per_week: 2,
        total_duration_in_seconds: null,
        body_parts: [],
        locations: [],
        goals: [],
        highlight: [],
        required_equipments: [],
        tips: [],
    },

    files: [],
}

export const useWorkoutStore = defineStore('workout', () => {
    const errors = ref([])

    const userStore = useUserStore()

    /**
     * Creating a workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    const creatingWorkout = ref(DEFAULT_WORKOUT_CREATING)
    const creatingWorkoutInStorage = localStorage.getItem('kol-private-workout-creating')
    if (creatingWorkoutInStorage) {
        const storageCreatingWorkout = JSON.parse(creatingWorkoutInStorage)
        creatingWorkout.value = {
            ...creatingWorkout.value,
            ...storageCreatingWorkout,
        }
    }
    watch(
        () => creatingWorkout,
        (state) => {
            localStorage.setItem('kol-private-workout-creating', JSON.stringify(state.value))
        },
        { deep: true }
    )

    const isCreating = computed(() => creatingWorkout.value.hasStarted)

    /**
     * Start a workout
     *
     * @param {Object} data Workout data
     * @returns {Promise<Object>}
     */
    function startWorkoutForm(data) {
        return new Promise((resolve) => {
            creatingWorkout.value = {
                ...creatingWorkout.value,
                ...data,
                hasStarted: true,
            }
            resolve(creatingWorkout.value)
        })
    }

    /**
     * Update a workout form
     *
     * @param {Object} data Workout data
     * @returns {Promise<Object>}
     */
    function updateWorkoutForm(data) {
        return startWorkoutForm(data)
    }

    /**
     * Reset workout form
     *
     * @returns {Promise<Object>}
     */
    function resetWorkoutForm() {
        return new Promise((resolve) => {
            creatingWorkout.value = DEFAULT_WORKOUT_CREATING
            resolve(creatingWorkout.value)
        })
    }

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all series or specific one by type
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(query = {}, cursor = null) {
        // if (!query.filter) {
        //     query.filter = {}
        // }
        // query.filter.type = 'workout'
        // // query.per_page = 1
        
        // let apiUrl = `creator/${userStore.selectedCreator.id}/series`

        // if (cursor != null) {
        //     apiUrl += '?cursor=' + cursor
        // }
        // let queryFilter = ''
        // if (query) {
        //     queryFilter = QueryParamBuilder.encode(query)
        // }

        // init params
        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('creator/' + userStore.selectedCreator.id + '/series' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all series episodes by type video
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listEpisodes(query = {}, cursor = null) {
        // if (!query.filter) {
        //     query.filter = {}
        // }
        // query.filter.type = 'video'
        // query.filter.series = 'workout'

        // let apiUrl = `creator/${userStore.selectedCreator.id}/episodes`
        // if (cursor != null) {
        //     apiUrl += '?cursor=' + cursor
        // }
        // let queryFilter = ''
        // if (query) {
        //     queryFilter = QueryParamBuilder.encode(query)
        // }

        // init params
        let queryFilter = ''

        query.per_page = 3

        if (cursor != null) {
            query.cursor = cursor
        }

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('creator/' + userStore.selectedCreator.id + '/episodes' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function post(params) {
        return ApiService.post('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item with form upload
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function create(params) {
        return ApiService.postFormData('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(params) {
        params._method = 'PUT'
        return ApiService.postFormData('series/' + params.id, params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(id) {
        return ApiService.get('series', id)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getByIdForCreator(seriesId, creatorId = userStore.selectedCreator.id) {
        return ApiService.get(`creator/${creatorId}/series/${seriesId}?type=workout`)
            .then((result) => {
                return result
            })
            .catch((error) => {
                throw error
            })
    }
    async function likedWorkouts(query = {}) {
        return ApiService.getAll('liked/workout', {
            params: query,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like/Unlike workout
     * @returns {Promise<axios.AxiosResponse>|Promise<Response>}
     */
    async function like(serieId) {
        // return ApiService.post('series/' + serieId + '/toggle-like')
        // Use beacon to not wait for response
        return ApiService.sendPostBeacon('series/' + serieId + '/toggle-like')
        .then((response) => {
            return response.json()
        })
        .then((data) => {
            return {data}
        })
        .catch(({ data }) => {
            throw data.message
        })
    }

    /**
     * Delete a workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(workoutId) {
        return ApiService.delete('series/' + workoutId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        list,
        listEpisodes,
        create,
        post,
        update,
        getById,
        getByIdForCreator,
        softDelete,
        like,
        setErrors,
        likedWorkouts,

        isCreating,
        creatingWorkout,
        startWorkoutForm,
        updateWorkoutForm,
        resetWorkoutForm,
    }
})