import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'

export const useMasterclassStore = defineStore('masterclass', () => {
    const errors = ref([])

    const userStore = useUserStore()

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all series or specific one by type
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(query = {}, cursor = null) {
        // if (!query.filter) {
        //     query.filter = {}
        // }
        // query.filter.type = 'masterclass'
        // let apiUrl = `creator/${userStore.selectedCreator.id}/series`
        // if (cursor != null) {
        //     apiUrl += '?cursor=' + cursor
        // }
        // let queryFilter = ''
        // if (query) {
        //     queryFilter = QueryParamBuilder.encode(query)
        // }

        let queryFilter = ''

        query.per_page = 3
        
        if (cursor != null) {
            query.cursor = cursor
        }

        query.per_page = query.per_page ? query.per_page : 3
        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('creator/' + userStore.selectedCreator.id + '/series' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch((e) => {
                throw e.message
            })
    }

    /**
     * Get all series episodes by type video
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function listEpisodes(query = {}, cursor = null) {
        // if (!query.filter) {
        //     query.filter = {}
        // }
        // query.filter.type = 'video'
        // query.filter.series = 'masterclass'

        // let apiUrl = `creator/${userStore.selectedCreator.id}/episodes`
        // if (cursor != null) {
        //     apiUrl += '?cursor=' + cursor
        // }
        // let queryFilter = ''
        // if (query) {
        //     queryFilter = QueryParamBuilder.encode(query)
        // }

        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('creator/' + userStore.selectedCreator.id + '/episodes' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function post(params) {
        return ApiService.post('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item with form upload
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function create(params) {
        return ApiService.postFormData('series', params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(params) {
        params._method = 'PUT'
        return ApiService.postFormData('series/' + params.id, params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(id) {
        return ApiService.get('series', id)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getByIdForCreator(seriesId, creatorId = userStore.selectedCreator.id) {
        return ApiService.get(`creator/${creatorId}/series/${seriesId}?type=masterclass`)
            .then((result) => {
                return result
            })
            .catch((error) => {
                throw error
            })
    }

    async function likedMasterclass(query = {}) {
        return ApiService.getAll('liked/workout', {
            params: query,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like/Unlike workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function like(serieId) {
        return ApiService.post('series/' + serieId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a workout
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(workoutId) {
        return ApiService.delete('series/' + workoutId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        list,
        listEpisodes,
        create,
        post,
        update,
        getById,
        getByIdForCreator,
        softDelete,
        like,
        setErrors,
        likedMasterclass,
    }
})