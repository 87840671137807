import { defineStore } from 'pinia'
import { ref } from 'vue'

const EMPTY_SHARE = {
    data: null,
    creator: false,
    link: null,
    network_link: null,
}

export const useShareStore = defineStore('share', () => {

    const hasActive = ref(false)
    const shareContent = ref(EMPTY_SHARE)
    let onDisplayCallbacks = []
    let onHideCallbacks = []

    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    /**
     * Show comments of media
     */
    function show(displayed) {
        hasActive.value = true
        shareContent.value = {...displayed}
        onDisplayCallbacks.forEach((callback) => callback())
    }

    /**
     * Hide comments of media
     */
    function hide() {
        hasActive.value = false
        onHideCallbacks.forEach((callback) => callback())
    }

    return {
        hasActive,
        shareContent,

        onDisplay,
        onHide,
        show,
        hide,
    }
})