import axios from 'axios'
import router from '@/router'
import JwtService from '@/services/JwtService'
import NotificationService from '@/services/NotificationService'
import { useLoadingStore } from '@/stores/frontend/loading'
import { useUserStore } from '@/stores/user'
// import {useI18n} from "vue-i18n";
// const {t} = useI18n()
import i18n from '@/translation'
const { t } = i18n.global

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    static axios
    static headers


    /**
     * @description initialize vue axios
     */
    static init() {
        axios.defaults.withCredentials = true

        let api = axios.create({
            baseURL: import.meta.env.VITE_API_BASE_URL,
            headers: {
                Accept: 'application/json',
                'Accept-Language': localStorage.getItem('user-preferred-lang') ?? 'en',
            },
        })

        api.interceptors.request.use(
            (config) => {
                const token = JwtService.getToken()
                const siteUuid = import.meta.env.VITE_SITE_UUID
                config.headers['Accept-Language'] = localStorage.getItem('user-preferred-lang') ?? 'en'

                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                if (siteUuid && siteUuid !== null) {
                    config.headers['X-SITE-FROM'] = siteUuid
                }
                let deviceId = localStorage.getItem('device_id')
                if (deviceId) {
                    config.headers['X-DEVICE-ID'] = deviceId
                }
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )
        api.interceptors.response.use(
            (response) => {
                if (
                    response.status === 200 ||
                    response.status === 201 ||
                    response.status === 204
                ) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            async (error) => {
                if (error.response?.status) {
                    useLoadingStore().setLoading(false)
                    switch (error.response.status) {
                        case 400:
                            break
                        case 401:
                            setTimeout(() => {
                                // console.log(401)
                                useUserStore().purgeAuth()
                                router.replace({
                                    path: '/',
                                    query: {
                                        redirect: router.currentRoute.fullPath,
                                    },
                                })
                            }, 500)
                            break
                        case 403:
                            // console.log("Unauthorised");
                            break
                        case 404:
                            // console.log("not exists");
                            // return Promise.reject(error.response);
                            break

                        case 422:
                            if (
                                typeof error.response.data.errors === 'object'
                            ) {
                                error.response.data.errors = Object.values(
                                    error.response.data.errors
                                )
                            }

                            let errors = [
                                ...error.response.data.errors.flatMap(
                                    (error) => {
                                        return error
                                    }
                                ),
                            ]
                            await NotificationService.notifyUserErrors(errors, t('globals.tryAgain'))

                            return Promise.reject({ data: { message: '422' } })

                            break
                        case 425:
                            // console.log(error.response.data)
                            // await ErrorService.notifyUserError(error.response.data.errors)
                            return Promise.reject({ data: { message: '425' } })
                            break
                        case 429:
                            console.log('429!!')
                            // await ErrorService.notifyUserError(error.response.data.errors)
                            break
                        case 451:
                                // console.log('451!!')
                                let status = (error.response.data.status !== undefined) ? error.response.data.status : 'in-review'
                                await router.push({name: "blocked", params: {reason: status}})
                                break
                        case 502:
                            setTimeout(() => {
                                router.replace({
                                    path: '/',
                                    query: {
                                        redirect: router.currentRoute.fullPath,
                                    },
                                })
                            }, 1000)
                    }
                    return Promise.reject(error.response)
                }
            }
        )
        ApiService.axios = api

        return this
    }

    static withBearer(bearer = '') {
        ApiService.axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${bearer}`
                return config
            },
            (error) => {
                return Promise.reject(error)
            }
        )
        return this
    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    static async getAll(resource = '', params = []) {
        return ApiService.axios.get(resource, params)
    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param id
     */
    static async get(resource = '', id = '') {
        if (!ApiService.axios) {
            ApiService.init()
        }
        if (id) {
            return ApiService.axios.get(`${resource}/${id}`)
        } else {
            return ApiService.axios.get(`${resource}`)
        }
    }

    /**
     * @description set the POST HTTP request
     * @param resource
     * @param params
     * @param config
     * @returns Promise<AxiosResponse>
     */
    static async post(resource = '', params = {}, config = {}) {
        return ApiService.axios.post(`${resource}`, params, config)
    }

    /**
     * @description set the POST Form Data HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    static async postFormData(resource = '', params = {}) {
        // ApiService.axios.interceptors.request.use(
        //     (config) => {
        //         config.headers['Content-Type'] = 'multipart/form-data'
        //         return config
        //     },
        //     (error) => {
        //         return Promise.reject(error)
        //     }
        // )
        return ApiService.axios.post(`${resource}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    }

    /**
     * @description send the UPDATE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param slug
     * @param params
     */
    static async update(resource = '', slug = '', params = {}) {
        return ApiService.axios.put(`${resource}/${slug}`, params)
    }

    /**
     * @description send the UPDATE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    static async put(resource = '', params = {}) {
        return ApiService.axios.put(`${resource}`, params)
    }


    /**
     * @description send the UPDATE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     */
    static async patch(resource = '', params = {}) {
        return ApiService.axios.patch(`${resource}`, params)
    }

    /**
     * @description Send the DELETE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     */
    static async delete(resource = '') {
        return ApiService.axios.delete(resource)
    }


    static buildBeaconHeaders() {
        let headers = {
            'content-type': 'application/json',
            Accept: 'application/json',
            'Accept-Language': localStorage.getItem('user-preferred-lang') ?? 'en',
        }
        const token = JwtService.getToken()
        const siteUuid = import.meta.env.VITE_SITE_UUID
        if (token) {
            headers.Authorization = `Bearer ${token}`
        }
        if (siteUuid && siteUuid !== null) {
            headers['X-SITE-FROM'] = siteUuid
        }
        let deviceId = localStorage.getItem('device_id')
        if (deviceId) {
            headers['X-DEVICE-ID'] = deviceId
        }
        return headers
    }
    /**
     * Send a POST beacon without waiting for result
     * @param resource
     * @param data
     * @returns {Promise<Response>}
     */
    static sendPostBeacon(resource = '', data = {}) {
        let baseURL = import.meta.env.VITE_API_BASE_URL

        return fetch(`${baseURL}/${resource}`, {
            keepalive: true,
            method: 'POST',
            headers: this.buildBeaconHeaders(),
            body: JSON.stringify(data),
        });
    }

    /**
     * Send a PUT beacon without waiting for result
     * @param resource
     * @param data
     * @returns {Promise<Response>}
     */
    static sendPutBeacon(resource = '', data = {}) {
        let baseURL = import.meta.env.VITE_API_BASE_URL

        return fetch(`${baseURL}/${resource}`, {
            keepalive: true,
            method: 'PUT',
            headers: this.buildBeaconHeaders(),
            body: JSON.stringify(data),
        });
    }
}

export default ApiService