import { defineStore } from 'pinia'
import { ref } from 'vue'
import i18n from '@/translation'
import { useI18n } from 'vue-i18n'
import { useWorkoutStore } from '@/stores/workout'
import { useUserStore } from '@/stores/user'
import TranslationsHelper from '@/helpers/TranslationsHelper'

const EMPTY_MODAL = {
    title: null,
    cover: null,
    content: null,
    subtitle: null,
    type: null,
    cancel: false,
    onSubmit: () => {},
    extra: {},
}

export const useViewModalStore = defineStore('view-modal', () => {
    const hasActiveModal = ref(false)
    const modal = ref(EMPTY_MODAL)
    const defaultValues = ref({})
    const errors = ref({})
    let onDisplayCallbacks = []
    let onHideCallbacks = []

    const viewOptions = ref(null)

    const workoutStore = useWorkoutStore()

    const currentLocale = i18n.global.locale.value
    const { t } = useI18n()

    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    function showFormModal(displayedModal) {
        hasActiveModal.value = true
        modal.value = displayedModal
        onDisplayCallbacks.forEach((callback) => callback())
    }

    function setModal(_modal) {
        modal.value = _modal
    }

    function hideFormModal() {
        hasActiveModal.value = false
        modal.value = EMPTY_MODAL
        defaultValues.value = {}
        errors.value = {}

        onHideCallbacks.forEach((callback) => callback())
    }

    function setErrors(values) {
        errors.value = values
    }

    /**
     * This function is used to view the episode in the list of episodes and show next / prev episode
     * @param episode
     * @param show boolean
     * @param isPreview
     * @returns {boolean}
     */
    async function viewEpisodeInList(episode, show = true, isPreview = false) {
        const isValidEpisode = (_episode) => {
            if (
                (_episode.video_url === '' && _episode.checked_visibility !== 'subscriber') ||
                _episode.video_url === 'processing' ||
                _episode.video_url === 'pending' ||
                _episode.video_url === 'failed'
            ) {
                return false
            }
            return true
        }

        if (!isValidEpisode(episode)) {
            return false
        }

        const series = await workoutStore.getById(episode.series_id)

        // sort by episode number
        const episodes = series.data.episodes
            .filter((item) => isValidEpisode(item))
            .sort((a, b) => (a.episode_number > b.episode_number ? 1 : -1))

        // console.log(episodes)

        const nextEpisode = episodes[episodes.findIndex((item) => item.id === episode.id) + 1] ?? null
        const prevEpisode = episodes[episodes.findIndex((item) => item.id === episode.id) - 1] ?? null

        const modalToSet = {
            episode_id: episode.id,
            title: TranslationsHelper.getLocalisedContent(episode.title, currentLocale),
            subtitle: t('user.workouts.episodes.label') + ' ' + episode.episode_number,
            cover: episode.cover,
            content: episode.video_url,
            type: 'video',
            extra: {
                nextEpisode,
                prevEpisode,
                currentEpisode: episode,
                episodes: episodes,
                isPreview: useUserStore().inPreviewMode,
                repetition: episode.repetition,
                resting_episode_time: episode.resting_episode_time,
                resting_repeat_time: episode.resting_repeat_time,
            },
        }

        if (show) {
            showFormModal(modalToSet)
            return true
        }

        setModal(modalToSet)
        return true
    }

    const setViewOptions = (_options) => {
        viewOptions.value = _options
    }

    return {
        hasActiveModal,
        modal,
        defaultValues,
        errors,
        viewOptions,
        setErrors,
        onDisplay,
        onHide,
        showFormModal,
        hideFormModal,
        setModal,
        viewEpisodeInList,
        setViewOptions,
    }
})
