import { useUserStore } from '@/stores/user'
import {useCreatorStore} from "@/stores/frontend/creator";

export const roleMiddleware = (to, from, next) => {
    const { authorize } = to.meta
    const store = useUserStore()
    if (authorize?.length && !authorize.includes(store.role) && to.name !== 'user-dashboard-with-slug') {
        if (to.meta.shouldRedirect) {
            return !!to.meta.shouldCreatorSlugParam
                ? next({ name: to.meta.shouldRedirect, params: {creatorSlug: useCreatorStore().creator.slug} })
                : next({ name: to.meta.shouldRedirect })
        }
        return next({ path: '/404' })
    }
    next()
}