export const staticPages = {
    gtcu: {
        en: {
            "title": "Terms of Use",
            "pageTitle": "General Terms and Conditions of Use",
            "view": "gtcu"
        },
        fr: {
            "title": "Conditions d'utilisation",
            "pageTitle": "Conditions Générales d'Utilisation",
            "view": "gtcu"
        },
        es: {
            "title": "Términos de Uso",
            "pageTitle": "Términos y Condiciones Generales de Uso",
            "view": "gtcu"
        }
    },
    "gtcs": {
        en: {
            "title": "Terms of Sale",
            "pageTitle": "General Terms and Conditions of Sale",
            "view": "gtcs"
        },
        fr: {
            "title": "Conditions de vente",
            "pageTitle": "Conditions Générales de Vente",
            "view": "gtcs"
        },
        es: {
            "title": "Términos de Venta",
            "pageTitle": "Términos y Condiciones Generales de Venta",
            "view": "gtcs"
        }
    },
    "privacy-policy": {
        en: {
            "title": "Privacy policy",
            "pageTitle": "Privacy policy",
            "view": "privacy-policy"
        },
        fr: {
            "title": "Politique de confidentialité",
            "pageTitle": "Politique de confidentialité",
            "view": "privacy-policy"
        },
        es: {
            "title": "Política de privacidad",
            "pageTitle": "Política de privacidad",
            "view": "privacy-policy"
        }
    },
    "content-policy": {
        en: {
            "title": "Content policy",
            "pageTitle": "Content policy",
            "view": "content-policy"
        },
        fr: {
            "title": "Politique de contenu",
            "pageTitle": "Politique de contenu",
            "view": "content-policy"
        },
        es: {
            "title": "Política de contenido",
            "pageTitle": "Política de contenido",
            "view": "content-policy"
        }
    },
    "cookie-policy": {
        en: {
            "title": "Cookie policy",
            "pageTitle": "Cookie policy",
            "view": "cookie-policy"
        },
        fr: {
            "title": "Politique de cookies",
            "pageTitle": "Politique de cookies",
            "view": "cookie-policy"
        },
        es: {
            "title": "Política de cookies",
            "pageTitle": "Política de cookies",
            "view": "cookie-policy"
        }
    },
    "legal-notice": {
        en: {
            "title": "Legal Notice",
            "pageTitle": "Legal Notice",
            "view": "legal-notice"
        },
        fr: {
            "title": "Mentions légales",
            "pageTitle": "Mentions légales",
            "view": "legal-notice"
        },
        es: {
            "title": "Aviso legal",
            "pageTitle": "Aviso legal",
            "view": "legal-notice"
        }
    }
}

export const getRouteNameForStaticPage = (page, lang) => {
    return `static-${lang}-${page}`
}