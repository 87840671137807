<template>
    <BaseField :id="id" :name="name" :label="label" :required="required">
        <div v-for="value in filteredValues" :key="value" class="badge badge-lg badge-dark badge-spacer">
            {{ value }}

            <button @click.prevent="deleteTag(value)">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    width="20"
                    height="20"
                    fill="white"
                >
                    <path
                        d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                        fill="white" />
                </svg>
            </button>
        </div>
        <PrimaryButton :on-click="addTag" :is-big="false" style="font-size: small">
            + {{ props.buttonText !== '' ? props.buttonText : $t('tag.addTag') }}
        </PrimaryButton>
    </BaseField>
</template>

<script setup>
import BaseField from '@/components/Modals/Fields/BaseField.vue'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import { string } from 'yup'
import { useFormModalStore } from '@/stores/formModal'
import i18n from '@/translation'
import { computed, ref, toRaw, watch } from 'vue'

const formModalStore = useFormModalStore()
const { t } = i18n.global

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    formTitle: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    buttonText: {
      type: String,
      default: ''
    },
    errorMessage: {
        type: String,
        default: '',
    },
    list: {
        type: Array,
        required: true,
    },
    language: {
        type: String,
        default: i18n.global.locale.value,
    },
    resourceParams: {
        type: Object,
        default: () => { },
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:tags'])

const values = ref(props.list)

watch(() => props.list, (tags) => {
    values.value = tags
})

const formTitle = computed(() => {
    return props.formTitle !== '' ? props.formTitle : t('tag.modal.title')
})

const filteredValues = computed(() => {
    return values.value ? toRaw(values.value) : []
})

function addTag(event) {
    event.preventDefault()

    formModalStore.showFormModal({
        title: formTitle.value,
        fields: [
            {
                type: 'autocomplete',
                name: 'tag',
                label: t('tag.modal.fields.tag.label'),
                list: props.list,
                rules: string()
                    .required()
                    .label(t('tag.modal.fields.tag.name')),
                resourceModel: 'tags',
                resourceParams: {
                    filter: { ...props.resourceParams },
                    language: null,
                },
                resourceLabel: 'name',
            },
        ],
        submit: t('tag.modal.submit'),
        onSubmit: processAddTag
    })
}

function processAddTag(data) {
    const newList = values.value && values.value.filter((value) => {
        return !!value
    })

    newList.push(data.tag)

    values.value = newList

    emits('update:tags', {
        type: props.name,
        tags: toRaw(values.value)
    })

    formModalStore.hideFormModal()
}

function deleteTag(tag) {
    const newList = values.value && values.value.filter((value) => {
        return value !== tag
    })

    values.value = newList
    emits('update:tags', {
        type: props.name,
        tags: toRaw(values.value)
    })
}
</script>

<style scoped>
.badge-spacer {
    margin-right: 0.5rem;
}
</style>