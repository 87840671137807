<template>
    <div :class="[className]">
        <label
            v-if="props.label"
            class="form-label form-label-modal"
            :for="props.id"
        >
            <template v-if="linkLabel !== ''">
                <i18n-t tag="p" class="name mb-0" :keypath="label" scope="global">
                    <router-link :to="{name: routeLabel}" class="text-dark" target="_blank">
                        {{ $t(linkLabel) }}
                        <i class="fa-solid fa-up-right-from-square fs-7 ms-2"></i>
                        <span v-if="props.required" class="text-danger ms-2">*</span>
                    </router-link>
                </i18n-t>
            </template>
            <template v-else>
                {{ props.label }}
                <span v-if="props.required" class="text-danger">*</span>
            </template>

        </label>
        <slot />

        <div v-if="props.errorMessage" class="invalid-feedback">
            {{ props.errorMessage }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    routeLabel: {
        type: String,
        default: '',
    },
    linkLabel: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    className: {
        type: String,
        default: 'form-group form-group-modal',
    },
    isSwitch: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
})
</script>

<style scoped>
.form-label-modal {
    width: 100%;
}
.form-group-modal {
    margin-bottom: 1.25rem;
}
</style>