import { useUserStore } from '@/stores/user'
import JwtService from '@/services/JwtService'
import {useCreatorStore} from "@/stores/frontend/creator";

export const isAuthChecker = (to, from, next) => {
    const { redirectionRoute } = useUserStore()

    // TODO check the issue with the store .isAuthenticated
    const isAuthenticated = !!JwtService.getToken()

    if (isAuthenticated) {
        if (redirectionRoute.includes('-with-slug')){
            return next({ name: redirectionRoute, params: {creatorSlug: useCreatorStore().creator.slug} })
        }
        return next({ name: redirectionRoute })
    }
    return next()
}