import JwtService from '@/services/JwtService'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import AwsS3 from '@uppy/aws-s3'
import GoldenRetriever from '@uppy/golden-retriever'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import UppyEN from '@uppy/locales/lib/en_US'
import UppyFR from '@uppy/locales/lib/fr_FR'
import UppyES from '@uppy/locales/lib/es_ES'

import * as VideoThumbnails from '@/plugins/videoThumbnails'

import {useUserStore} from "@/stores/user";

const DEFAULT_OPTIONS = {
    id: 'uppy',
    autoProceed: true,
    debug: false,
    locale: '',
    onBeforeFileAdded: () => true,
    restrictions: {},
}


class UppyService {
    static init(instanceId = 'uppy', options = {}, withGoldenRetriever = true) {
        let uppyLocale = ''
        switch (useUserStore().currentLang) {
            case 'en':
                uppyLocale = UppyEN
                break;
            case 'es':
                uppyLocale = UppyES
                break;
            case 'fr':
                uppyLocale = UppyFR
                break;
        }

        let opts = {
            ...DEFAULT_OPTIONS,
            ...options,
            id: instanceId,
            locale: uppyLocale,
            restrictions: {
                minFileSize: options?.minFileSize,
                maxFileSize: options?.maxFileSize,
                maxTotalFileSize: options?.maxTotalFileSize,
                maxNumberOfFiles: options?.maxNumberOfFiles,
                allowedFileTypes: options?.allowedFileTypes,
            }
        }

        let uppy = new Uppy(opts)
        .use(AwsS3, {
            shouldUseMultipart: true,
            limit: 4,
            companionUrl: import.meta.env.VITE_API_BASE_URL,
            getTemporarySecurityCredentials: false,
            companionHeaders: {
                'uppy-auth-token': `Bearer ${JwtService.getToken()}`,
                Authorization: `Bearer ${JwtService.getToken()}`,
                'X-SITE-FROM': import.meta.env.VITE_SITE_UUID,
            },
            getChunkSize(file) {
                return file !== null ? Math.ceil(file.size / 20000) : 20000
            },
            partSize: 20000,
        })
        .use(ThumbnailGenerator, {
            waitForThumbnailsBeforeUpload: true,
        })
        .on('file-added', (file) => {
            if (file.type.includes('video')) {
                const thumbnailCount = 1;
                const thumbnails = new window.VideoThumbnails({
                    count : thumbnailCount,
                    maxWidth : 400,
                    maxHeight : 400
                });
                //Captured a thumb
                thumbnails.on('capture', function (image) {
                    uppy.setFileState(file.id, { preview: image })
                })
                thumbnails.capture(file.data);
            }
        })

        if (withGoldenRetriever) {
            // uppy.use(GoldenRetriever, {
            //     serviceWorker: true,
            // })
        }
        return uppy
    }

    static retryUpload(uppy) {
        const files = uppy.getFiles()
        if (files.length > 0) {
            // Waiting load blob from service worker, maybe set custom play/cancel UI to process
            setTimeout(() => {
                try {
                    uppy.emit('restore-confirmed')
                    return
                } catch {
                    uppy.cancelAll()
                }
            }, 3000)
        }
    }

    static registerServiceWorker() {
        if ('serviceWorker' in navigator) {
            return navigator.serviceWorker
                .register('/sw.js') // path to your bundled service worker with GoldenRetriever service worker
                .then((registration) => {
                    // console.log(
                    //     'ServiceWorker registration successful with scope: ',
                    //     registration.scope
                    // )
                })
                .catch((error) => {
                    console.log(`Registration failed with ${error}`)
                })
        }
    }
}

export default UppyService