import {ref} from 'vue'

export function useLocalLoading() {

    const loading = ref(false)

    /**
     * Set loading state
     * @param {boolean} _loading Loading State
     * @returns {void}
     */
    const setLoading = (_loading) => {
        loading.value = _loading
    }

    // expose managed state as return value
    return { loading, setLoading }
}