import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import { useUserStore } from '@/stores/user'

export const useRecipesStore = defineStore('recipes', () => {
    const errors = ref([])
    const userStore = useUserStore()
    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all recipes or specific one
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(id = '', query = {}, cursor = null) {
        // init params
        let queryFilter = ''

        // Cursor
        if (cursor) {
            query.cursor = cursor
        }

        query.per_page = 3

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.get(`recipes${id !== '' ? `/${id}` : ''}` + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all recipes or specific one
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function item(type, id) {
        return ApiService.get('recipe/' + id + '/' + type)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function recipeCategories() {
        return ApiService.get('enum/recipe-category')
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function recipeTypes(_for) {
        return ApiService.getAll('enum/recipe-type', {
            params: {
                for: _for,
            },
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function recipeLevels() {
        return ApiService.get('enum/recipe-level')
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function physicalGoals() {
        return ApiService.get('enum/recipe-physical-goal')
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function create(data) {
        return ApiService.post('recipes', data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createAllergen(recipeId, data) {
        return ApiService.post('recipe/' + recipeId + '/allergens', {
            ...data,
            recipe_id: recipeId,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createIngredient(recipeId, data) {
        return ApiService.post('recipe/' + recipeId + '/ingredients', {
            ...data,
            recipe_id: recipeId,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createStep(recipeId, data) {
        return ApiService.post('recipe/' + recipeId + '/steps', {
            ...data,
            recipe_id: recipeId,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createNutrition(recipeId, data) {
        return ApiService.post('recipe/' + recipeId + '/nutritions', {
            ...data,
            recipe_id: recipeId,
        })
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function update(recipeId, data) {
        return ApiService.patch('recipes/' + recipeId, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updateIngredient(recipeId, id, data) {
        return ApiService.patch('recipe/' + recipeId + '/ingredients/' + id, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updateNutrition(recipeId, id, data) {
        return ApiService.patch('recipe/' + recipeId + '/nutritions/' + id, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updateStep(recipeId, id, data) {
        return ApiService.patch('recipe/' + recipeId + '/steps/' + id, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updateAllergen(recipeId, id, data) {
        return ApiService.patch('recipe/' + recipeId + '/allergens/' + id, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a recipe
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(recipeId) {
        return ApiService.delete('recipes/' + recipeId)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function deleteNutrition(recipeId, id) {
        return ApiService.delete('recipe/' + recipeId + '/nutritions/' + id)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function deleteIngredient(recipeId, id) {
        return ApiService.delete('recipe/' + recipeId + '/ingredients/' + id)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function deleteAllergen(recipeId, id) {
        return ApiService.delete('recipe/' + recipeId + '/allergens/' + id)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function like(recipeId) {
        return ApiService.post('recipes/' + recipeId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all recipes by creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function creatorListing(query = {}, cursor = null) {
        if (!query.filter) {
            query.filter = {}
        }
        query.preview_mode = userStore.inPreviewMode ? 1 : 0
        query.user_mode = userStore.user.is_creator ? 1 : 0
        let queryFilter = ''

        query.cursor = cursor
        query.per_page = 3

        queryFilter = QueryParamBuilder.encode(query)
        const apiUrl = `creator/${userStore.selectedCreator.id}/recipes`
        return ApiService.getAll(apiUrl + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get a recipe by creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function creatorShow(recipeId) {
        const apiUrl = `creator/${userStore.selectedCreator.id}/recipes`
        return ApiService.get(apiUrl, recipeId)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Activate selected recipes by creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function creatorSelectRecipe(data) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/recipes/activate`

        return ApiService.post(apiUrl, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Activate all recipes by creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function creatorActivateAllRecipe(data) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/recipes/activate-all`

        return ApiService.post(apiUrl, data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Activate all recipes by creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function creatorDisableRecipe(recipeId) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/recipes/${recipeId}`

        return ApiService.delete(apiUrl)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        list,
        item,
        setErrors,

        recipeCategories,
        recipeTypes,
        recipeLevels,
        physicalGoals,

        create,
        createAllergen,
        createIngredient,
        createStep,
        createNutrition,

        update,
        updateIngredient,
        updateNutrition,
        updateStep,
        updateAllergen,

        softDelete,
        deleteNutrition,
        deleteIngredient,
        deleteAllergen,

        like,
        creatorListing,
        creatorShow,
        creatorSelectRecipe,
        creatorActivateAllRecipe,
        creatorDisableRecipe,
    }
})