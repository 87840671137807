import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

export const THEME_MODE_LS_KEY = 'theme_mode_value'

export const useThemeStore = defineStore('theme', () => {
    const themeMode = ref(
        localStorage.getItem(THEME_MODE_LS_KEY)
            ? localStorage.getItem(THEME_MODE_LS_KEY)
            : 'light'
    )
    const isDarkMode = computed(() => themeMode.value === 'dark')
    const currentTheme = computed(() =>
        localStorage.getItem(THEME_MODE_LS_KEY)
            ? localStorage.getItem(THEME_MODE_LS_KEY)
            : 'light'
    )

    function init() {
        document.documentElement.setAttribute(
            'data-bs-theme',
            currentTheme.value
        )
    }

    function setThemeMode(theme = 'light', temporary = false) {
        document.documentElement.setAttribute('data-bs-theme', theme)

        if (!temporary) {
            localStorage.setItem(THEME_MODE_LS_KEY, theme)
            themeMode.value = theme
        }
    }

    function toggleThemeMode() {
        const theme = themeMode.value === 'light' ? 'dark' : 'light'
        setThemeMode(theme)
    }

    return {
        themeMode,
        isDarkMode,
        currentTheme,

        init,
        setThemeMode,
        toggleThemeMode,
    }
})