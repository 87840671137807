import { creatorFinderMiddleware } from '@/router/middleware/creatorFinderMiddleware'
import { isAuthChecker } from '@/router/middleware/isAuthChecker'
import {useThemeStore} from "@/stores/theme";
import {authCreatorRegistrationMiddleware} from "@/router/middleware/authCreatorRegistrationMiddleware";
import ApiService from "@/services/ApiService";

const guests = [
    {
        path: '',
        name: 'home',
        beforeEnter: [/*isAuthChecker,*/ (to, from, next) => {
            useThemeStore().setThemeMode('light', true)
            window.scrollTo(0,0)
            next()
        }],
        component: () => import('@/views/Guests/HomePageV2/HomePageUserLayout.vue'),
    },
    {
        path: '/creator',
        name: 'home-creator',
        beforeEnter: [/*isAuthChecker,*/ (to, from, next) => {
            useThemeStore().setThemeMode('light', true)
            window.scrollTo(0,0)
            next()
        }],
        component: () => import('@/views/Guests/HomePageV2/HomePageLayout.vue'),
    },
    // {
    //     path: '/test-ws',
    //     name: 'test-ws',
    //     component: () => import('@/views/TestReverb.vue'),
    // },
    // {
    //     path: '',
    //     name: 'home',
    //     beforeEnter: [/*isAuthChecker,*/ (to, from, next) => {
    //         useThemeStore().setThemeMode('dark')
    //         window.scrollTo(0,0)
    //         next()
    //     }],
    //     component: () => import('@/views/Guests/HomePage/HomeIndex.vue'),
    // },
    // {
    //     path: '/',
    //     component: () => import('@/components/Layout/GuestLayout.vue'),
    //     children: [
    //         {
    //             path: '/early-access',
    //             name: 'early-access',
    //             component: () => import('@/views/Guests/EarlyAccessView.vue'),
    //         },
    //     ],
    // },
    {
        path: '/creator/register',
        name: 'registration',
        component: () => import('@/views/Guests/Registration.vue'),
    },
    {
        path: '/referral',
        // component: () => import('@/components/Layout/GuestLayout.vue'),
        children: [
            {
                path: 'creator/:ulid',
                name: 'referral-registration',
                component: () => import('@/views/Guests/Registration/CreatorEmailReferral.vue'),
                beforeEnter: [
                    (to, from, next) => {
                        return ApiService.get(`verify-manager/${to.params.ulid}`)
                        .then(() => next())
                        .catch(() => {
                            next({
                                name: 'NotFound',
                                params: { pathMatch: to.path.substring(1).split('/') },
                                query: to.query,
                                hash: to.hash,
                            })
                        })
                    }
                ],
                props: true,
            },
        ],
    },
    {
        path: '/registration',
        // component: () => import('@/components/Layout/GuestLayout.vue'),
        children: [
            {
                path: 'creator',
                name: 'creator-registration',
                component: () => import('@/views/Guests/Registration/CreatorEmail.vue'),
                beforeEnter: [authCreatorRegistrationMiddleware],
            },
            {
                path: 'creator/:uuid',
                name: 'creator-validation',
                component: () => import('@/views/Guests/Registration/CreatorCode.vue'),
            },
            {
                path: 'creator/:uuid/password/:code',
                name: 'creator-password',
                component: () => import('@/views/Guests/Registration/CreatorPassword.vue'),
            },
        ],
    },
    {
        path: '/:creatorSlug',
        component: () => import('@/components/Layout/GuestLayout.vue'),
        beforeEnter: [creatorFinderMiddleware], //roleMiddleware
        children: [
            {
                path: 'registration-password',
                name: 'registration-password',
                component: () => import('@/views/Guests/CreateKolFirstPassword.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/',
        component: () => import('@/components/Layout/GuestLayout.vue'),
        children: [
            {
                path: 'landing-registration-password',
                name: 'landing-registration-password',
                component: () => import('@/views/Guests/UserRegisterFirstPassword.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/search/creator',
        name: 'search-creator',
        component: () => import('@/views/Guests/SearchCreator/SearchCreator.vue'),
        props: true,
    },
]

export default guests