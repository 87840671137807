const socialNetworkType = {
    Twitter: {
        label: 'Twitter',
        icon: 'fa-brands fa-twitter',
    },
    X: {
        label: 'X',
        icon: 'fa-brands fa-x-twitter',
    },
    Tumblr: {
        label: 'Tumblr',
        icon: 'fa-brands fa-tumblr',
    },
    Vero: {
        label: 'Vero',
        icon: 'fa-brands fa-vero',
        svg: '/images/social-networks/vero.svg',
    },
    Linkedin: {
        label: 'Linkedin',
        icon: 'fa-brands fa-linkedin',
    },
    Viadeo: {
        label: 'Viadeo',
        icon: 'fa-brands fa-viadeo',
    },
    Slack: {
        label: 'Slack',
        icon: 'fa-brands fa-slack',
    },
    'Facebook Workplace': {
        label: 'Facebook Workplace',
        icon: 'fa-brands fa-facebook-workplace',
        svg: '/images/social-networks/facebook-workplace.svg',
    },
    Xing: {
        label: 'Xing',
        icon: 'fa-brands fa-xing',
    },
    Meetup: {
        label: 'Meetup',
        icon: 'fa-brands fa-meetup',
    },
    Eventbrite: {
        label: 'Eventbrite',
        icon: 'fa-brands fa-eventbrite',
        svg: '/images/social-networks/eventbrite.svg',
    },
    Instagram: {
        label: 'Instagram',
        icon: 'fa-brands fa-instagram',
    },
    Facebook: {
        label: 'Facebook',
        icon: 'fa-brands fa-facebook',
    },
    Pinterest: {
        label: 'Pinterest',
        icon: 'fa-brands fa-pinterest',
    },
    Flickr: {
        label: 'Flickr',
        icon: 'fa-brands fa-flickr',
    },
    '500px': {
        label: '500px',
        icon: 'fa-brands fa-500px',
    },
    Giphy: {
        label: 'Giphy',
        icon: 'fa-brands fa-giphy',
        svg: '/images/social-networks/giphy.svg',
    },
    Gifycat: {
        label: 'Gifycat',
        icon: 'fa fa-globe',
    },
    MSQR: {
        label: 'MSQR',
        icon: 'fa fa-globe',
    },
    Prisma: {
        label: 'Prisma',
        icon: 'fa-brands fa-prisma',
        svg: '/images/social-networks/prisma.svg',
    },
    'Facebook Moments': {
        label: 'Facebook Moments',
        icon: 'fa-brands fa-facebook',
    },
    Medium: {
        label: 'Medium',
        icon: 'fa-brands fa-medium',
    },
    Youtube: {
        label: 'Youtube',
        icon: 'fa-brands fa-youtube',
    },
    Dailymotion: {
        label: 'Dailymotion',
        icon: 'fa-brands fa-dailymotion',
    },
    Vimeo: {
        label: 'Vimeo',
        icon: 'fa-brands fa-vimeo',
    },
    TikTok: {
        label: 'TikTok',
        icon: 'fa-brands fa-tiktok',
    },
    Periscope: {
        label: 'Periscope',
        icon: 'fa-brands fa-periscope',
    },
    Twitch: {
        label: 'Twitch',
        icon: 'fa-brands fa-twitch',
    },
    Caffeine: {
        label: 'Caffeine',
        icon: 'fa-brands fa-caffeine',
        svg: '/images/social-networks/caffeine.svg',
    },
    Mixer: {
        label: 'Mixer',
        icon: 'fa-brands fa-mixer',
    },
    Houseparty: {
        label: 'Houseparty',
        icon: 'fa-brands fa-houseparty',
        svg: '/images/social-networks/houseparty.svg',
    },
    Discord: {
        label: 'Discord',
        icon: 'fa-brands fa-discord',
    },
    Slideshare: {
        label: 'Slideshare',
        icon: 'fa-brands fa-slideshare',
    },
    Issuu: {
        label: 'Issuu',
        icon: 'fa-brands fa-issuu',
        svg: '/images/social-networks/issuu.svg',
    },
    Scribd: {
        label: 'Scribd',
        icon: 'fa-brands fa-scribd',
    },
    Reddit: {
        label: 'Reddit',
        icon: 'fa-brands fa-reddit',
    },
    '4Chan': {
        label: '4Chan',
        icon: 'fa-brands fa-4chan',
        svg: '/images/social-networks/4chan.svg',
    },
    '9Gag': {
        label: '9Gag',
        icon: 'fa-brands fa-9gag',
        svg: '/images/social-networks/9gag.svg',
    },
    StumbleUpon: {
        label: 'StumbleUpon',
        icon: 'fa-brands fa-stumbleupon',
    },
    Flipboard: {
        label: 'Flipboard',
        icon: 'fa-brands fa-flipboard',
    },
    Yelp: {
        label: 'Yelp',
        icon: 'fa-brands fa-yelp',
    },
    Tripadvisor: {
        label: 'Tripadvisor',
        icon: 'fa-brands fa-tripadvisor',
        svg: '/images/social-networks/tripadvisor.svg',
    },
    Foursquare: {
        label: 'Foursquare',
        icon: 'fa-brands fa-foursquare',
    },
    Swarm: {
        label: 'Swarm',
        icon: 'fa-brands fa-swarm',
        svg: '/images/social-networks/swarm.svg',
    },
    Waze: {
        label: 'Waze',
        icon: 'fa-brands fa-waze',
    },
    Spotify: {
        label: 'Spotify',
        icon: 'fa-brands fa-spotify',
    },
    Deezer: {
        label: 'Deezer',
        icon: 'fa-brands fa-deezer',
    },
    Soundcloud: {
        label: 'Soundcloud',
        icon: 'fa-brands fa-soundcloud',
    },
    'Apple Music': {
        label: 'Apple Music',
        icon: 'fa-brands fa-apple',
    },
    Mixcloud: {
        label: 'Mixcloud',
        icon: 'fa-brands fa-mixcloud',
    },
    Myspace: {
        label: 'Myspace',
        icon: 'fa-brands fa-myspace',
        svg: '/images/social-networks/myspace.svg',
    },
    Ello: {
        label: 'Ello',
        icon: 'fa-brands fa-ello',
    },
    Etsy: {
        label: 'Etsy',
        icon: 'fa-brands fa-etsy',
    },
    Behance: {
        label: 'Behance',
        icon: 'fa-brands fa-behance',
    },
    Dribble: {
        label: 'Dribble',
        icon: 'fa-brands fa-dribble',
        svg: '/images/social-networks/dribble.svg',
    },
    Wattpad: {
        label: 'Wattpad',
        icon: 'fa-brands fa-wattpad',
        svg: '/images/social-networks/wattpad.svg',
    },
    Nextdoor: {
        label: 'Nextdoor',
        icon: 'fa-brands fa-nextdoor',
        svg: '/images/social-networks/nextdoor.svg',
    },
    Mastodon: {
        label: 'Mastodon',
        icon: 'fa-brands fa-mastodon',
    },
    Diaspora: {
        label: 'Diaspora',
        icon: 'fa-brands fa-diaspora',
    },
    Friendica: {
        label: 'Friendica',
        icon: 'fa-brands fa-friendica',
        svg: '/images/social-networks/friendica.svg',
    },
    Voxer: {
        label: 'Voxer',
        icon: 'fa fa-globe',
    },
    Snapchat: {
        label: 'Snapchat',
        icon: 'fa-brands fa-snapchat',
    },
    Snow: {
        label: 'Snow',
        icon: 'fa fa-bolt',
    },
    Dubsmash: {
        label: 'Dubsmash',
        icon: 'fa fa-globe',
    },
    Telegram: {
        label: 'Telegram',
        icon: 'fa-brands fa-telegram',
    },
    Signal: {
        label: 'Signal',
        icon: 'fa-brands fa-signal',
    },
    Symphony: {
        label: 'Symphony',
        icon: 'fa-brands fa-symphony',
        svg: '/images/social-networks/symphony.svg',
    },
    Whisper: {
        label: 'Whisper',
        icon: 'fa fa-globe',
    },
    'WordPress.com': {
        label: 'WordPress.com',
        icon: 'fa-brands fa-wordpress',
    },
    Blogger: {
        label: 'Blogger',
        icon: 'fa-brands fa-blogger',
    },
    Wix: {
        label: 'Wix',
        icon: 'fa-brands fa-wix',
    },
    Skyblog: {
        label: 'Skyblog',
        icon: 'fa fa-globe',
    },
    Overblog: {
        label: 'Overblog',
        icon: 'fa fa-globe',
    },
    'Playstation Network': {
        label: 'Playstation Network',
        icon: 'fa-brands fa-playstation',
    },
    'Xbox Live': {
        label: 'Xbox Live',
        icon: 'fa-brands fa-xbox',
    },
    Steam: {
        label: 'Steam',
        icon: 'fa-brands fa-steam',
    },
    'Facebook Messenger': {
        label: 'Facebook Messenger',
        icon: 'fa-brands fa-facebook-messenger',
    },
    Whatsapp: {
        label: 'WhatsApp',
        icon: 'fa-brands fa-whatsapp',
    },
    WeChat: {
        label: 'WeChat',
        icon: 'fa-brands fa-weixin',
    },
    Skype: {
        label: 'Skype',
        icon: 'fa-brands fa-skype',
    },
    'Google Hangouts': {
        label: 'Google Hangouts',
        icon: 'fa-brands fa-google',
    },
    'Google Meet': {
        label: 'Google Meet',
        icon: 'fa-brands fa-google',
    },
    Viber: {
        label: 'Viber',
        icon: 'fa-brands fa-viber',
    },
    Line: {
        label: 'Line',
        icon: 'fa-brands fa-line',
    },
    Tango: {
        label: 'Tango',
        icon: 'fa fa-globe',
    },
    ooVoo: {
        label: 'Oovoo',
        icon: 'fa fa-globe',
    },
    Nimbuzz: {
        label: 'Nimbuzz',
        icon: 'fa fa-globe',
    },
    'Facebook Spaces': {
        label: 'Facebook Spaces',
        icon: 'fa-brands fa-facebook',
    },
    'Altspace VR': {
        label: 'Altspace VR',
        icon: 'fa fa-globe',
    },
    'Identi.ca': {
        label: 'Identi.ca',
        icon: 'fa fa-globe',
    },
}

function socialNetworkByValueToName() {
    let socialNetworkList = {}

    Object.keys(socialNetworkType).forEach((key) => {
        socialNetworkList[key] = socialNetworkType[key].label
    })

    return socialNetworkList
}

function iconByKey(key) {
    return socialNetworkType[key]?.icon
}

function iconByKeyWithSvg(key) {
    if (!socialNetworkType[key]?.svg && !socialNetworkType[key]?.icon) {
        const keyInKebabCase = key.replace(/\s+/g, '-').toLowerCase()

        return {
            svg: '/images/social-networks/' + keyInKebabCase + '.svg',
        }
    }

    return {
        svg: socialNetworkType[key].svg,
        iconClass: socialNetworkType[key]?.icon,
    }
}

export { socialNetworkType, socialNetworkByValueToName, iconByKey, iconByKeyWithSvg }