<template>
    <div v-if="!loadingStore.loading && language && !isList" class="btn-group my-0" :class="{'w-100 dropup': !isHomePage}">
        <button class="px-0 py-0 btn dropdown-toggle nav-link text-center cursor-pointer d-flex align-items-center" :class="($breakpoints.smAndDown.value) ? 'text-white' : 'text-gray-500'" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
            <FlagIcon :country="(language.code === 'en') ? 'gb' : language.code" class="m-0 me-1 p-0 ps-2 margin-left" />
            <template v-if="!isHomePage">
                <span class="d-inline">
                    {{ language.name_native }}
                </span>
            </template>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" style="z-index: 9999">
            <template v-for="(_lang, key) in globalStore.availableLanguages" :key="`languageSelector${key}`">
                <li v-if="_lang.code !== language.code" class="">
                    <a class="dropdown-item fs-7 d-flex align-items-center" href="javascript:void(0)" @click="submit(_lang.code)">
                        <FlagIcon :country="(_lang.code === 'en') ? 'gb' : _lang.code" class="m-0 me-1 p-0" />
                        {{_lang.name_native}}
                    </a>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="isList">
        <template v-for="(_lang, key) in globalStore.availableLanguages" :key="`languageSelector${key}`">
            <a class="dropdown-item fs-7 d-flex align-items-center" href="javascript:void(0)" @click="submit(_lang.code)">
                <FlagIcon :country="(_lang.code === 'en') ? 'gb' : _lang.code" class="m-0 me-1 p-0" />
                {{_lang.name_native}}
            </a>
        </template>
    </div>
</template>

<script setup>
import {useUserStore} from "@/stores/user";
import {useLoadingStore} from "@/stores/frontend/loading";
import {useGlobalSettingsStore} from "@/stores/globalSettings";
import {computed, onBeforeMount, onMounted, ref} from "vue";
import i18n from '@/translation'
import router from "@/router";
import FlagIcon from 'vue-country-flag-next'
import ApiService from "@/services/ApiService";

const { t } = i18n.global
const userStore = useUserStore()
const loadingStore = useLoadingStore()
const globalStore = useGlobalSettingsStore()

const {isHomePage} = defineProps({
    isHomePage: {
        type: Boolean,
        default: false
    },
    isList: {
        type: Boolean,
        default: false
    },
})


const language = ref(null)


function submit(_code) {
    loadingStore.setLoading(true, true)
    userStore.setLanguage(_code)
    i18n.global.locale.value = _code

    let langId = globalStore.availableLanguages.find((_lang) => _lang.code === _code)

    if (!isHomePage && !userStore.isGuest) {
        ApiService.patch('me/personal-info', {
            preferred_language_id: langId.id
        })
        .then(({data}) => {
            router.go(0)
        })
    }
    else{
        router.go(0)
    }
}
onBeforeMount(() => {
    loadingStore.setLoading(true)
    globalStore.init()
    .then(() => {
        language.value = {
            ...globalStore.availableLanguages.find((l) => {
                return l.code === userStore.preferredLanguage
            })
        }
        loadingStore.setLoading(false)
    })
})
</script>

<style scoped>
.margin-left {
    margin-left: -12px !important;
}
@media (max-width: 767.98px) {
    .margin-left {
        color: white;
        margin-left: -27px !important;
    }
}
</style>