<template>
    <form @submit="onSubmit">
        <slot/>

        <div class="row mt-3">
            <div class="col">
                <button v-if="hasPrevious" class="btn btn-lg rounded-pill btn-outline-dark" type="button" @click="goToPrev">
                    {{ $t('creator.stripeIdVerification.form.button.previous') }}
                </button>
            </div>
            <div class="col text-end">
                <button v-if="!(isLastStep && noSubmit)" class="btn btn-lg rounded-pill btn-tertiary" type="submit">
                    {{ isLastStep ? $t('creator.stripeIdVerification.form.button.submit') : $t('creator.stripeIdVerification.form.button.next') }}
                </button>
            </div>            
        </div>

        <pre v-if="debug" class="mt-5">{{ values }}</pre>
    </form>
</template>

<script setup lang="ts">
import {useForm} from 'vee-validate';
import {computed, provide, ref} from 'vue';

const props = defineProps({
    validationSchema: {
        type: Array,
        required: true,
    },
    initialValues: {
        type: Array,
        required: true,
    },
    debug: {
        type: Boolean,
        default: false,
    },
    noSubmit: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);
const currentStepIdx = ref(0);

// Injects the starting step, child <form-steps> will use this to generate their ids
const stepCounter = ref(0);
provide('STEP_COUNTER', stepCounter);

// Inject the live ref of the current index to child components
// will be used to toggle each form-step visibility
provide('CURRENT_STEP_INDEX', currentStepIdx);

// if this is the last step
const isLastStep = computed(() => {
    return currentStepIdx.value === stepCounter.value - 1;
});

// If the `previous` button should appear
const hasPrevious = computed(() => {
    return currentStepIdx.value > 0;
});

// extracts the individual step schema
const currentSchema = computed(() => {
    return props.validationSchema[currentStepIdx.value];
});
const currentValues = computed(() => {
    return props.initialValues[currentStepIdx.value];
});

const {values, handleSubmit, setValues} = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: currentSchema.value,

    // vee-validate will be aware of computed initial values changes
    // initialValues: currentValues.value,

    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
});

// watch(() => values, (state) => {
//     console.log(state)
// })

// We are using the "submit" handler to progress to next steps
// and to submit the form if its the last step
const onSubmit = handleSubmit((values) => {

    emit('submit', {...values, step: currentStepIdx.value+1});

    // if (!isLastStep.value) {
    //     currentStepIdx.value++;
    //
    //     return;
    // }

    // Let the parent know the form was filled across all steps
    // emit('submit', values);
});

function goToPrev() {
    if (currentStepIdx.value === 0) {
        return;
    }

    currentStepIdx.value--;
}

defineExpose({
    currentSchema,
    currentStepIdx,
    isLastStep,
    values,
    setValues
})
</script>