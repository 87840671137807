<template>
    <div v-if="loadingStore.loading" class="d-flex justify-content-center">
        <SmallLoader></SmallLoader>
    </div>
    <div v-show="!loadingStore.loading" class="row">
        <div class="col-12">
            <Form
                ref="episodeForm"
                class="form-auto-spacer"
                :validation-schema="validationSchema"
                @submit="submit"
                @invalid-submit="onInvalidSubmit"
            >

                <div id="accordion-parent" class="accordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <StarTitle tag="h5">
                                    1 • {{ $t('masterclass.form.episodes.add.steps.information') }}
                                </StarTitle>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordion-parent">
                            <div class="accordion-body">
                                <div class="row">
                                    <ul class="nav nav-tabs mb-4">
                                        <li
                                            v-for="(lang, keyLang) in settingsStore.availableLanguages"
                                            :key="keyLang"
                                            class="nav-item"
                                        >
                                            <a
                                                class="d-flex justify-content-center align-items-center"
                                                :class="navActiveByLang(lang.code)"
                                                href="#"
                                                @click.prevent="switchLang(lang.code)"
                                            >

                                                <div class="p-0 m-0">Version ({{lang.code.toUpperCase()}})</div>
                                                <FlagIcon :country="(lang.code === 'en') ? 'gb' : lang.code" class="m-0 me-1 p-0" />
                                                <!-- {{ $t('globals.edit') + ' ' + lang.name }}-->
                                            </a>
                                        </li>
                                    </ul>
                                    <RequiredInputGuide />
                                    <div class="col-12">
                                        <div class="col-12 col-lg-6 mb-3">
                                            <BaseSelect
                                                id="visibility"
                                                v-model="formData.visibility.visible"
                                                name="visibility"
                                                :model-value="formData.visibility.visible"
                                                :label="$t('workout.exercise.form.create.visibility.label')"
                                                :options="visibilities"
                                                required
                                                @on-change="handleInput"
                                            />
                                        </div>
                                        <BaseInput
                                            id="title"
                                            v-model="formData.title[currentLocale]"
                                            type="text"
                                            name="title"
                                            :data-info="`collapseOne`"
                                            class="mb-4"
                                            :model-value="formData.title[currentLocale]"
                                            :label="
                                                $t('masterclass.form.episodes.add.fields.title.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                            @keyup="handleInput"
                                        />
            <!--                            <BaseInput-->
            <!--                                id="description"-->
            <!--                                v-model="formData.description[currentLocale]"-->
            <!--                                type="textarea"-->
            <!--                                name="description"-->
            <!--                                class="mb-4"-->
            <!--                                :rows="7"-->
            <!--                                :model-value="formData.description[currentLocale]"-->
            <!--                                :label="-->
            <!--                                    $t('masterclass.form.episodes.add.fields.description.label') +-->
            <!--                                    ` (${currentLocale.toUpperCase()})`-->
            <!--                                "-->
            <!--                                required-->
            <!--                            />-->
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="cover"
                                            role="button"
                                            name="cover"
                                            type="file"
                                            :model-value="coverTmp"
                                            :label="$t('masterclass.form.episodes.add.fields.cover.label')"
                                            :placeholder="$t('masterclass.form.add.fields.cover.placeholder')"
                                            required
                                            @update:cover="handleChangeCoverImage"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div v-if="coverImage" class="form-group">
                                            <label class="form-label">
                                                {{ $t('masterclass.form.add.fields.cover.current') }}
                                            </label>
                                            <br />
                                            <img class="img-fluid cover-image" :src="coverImage" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-end mt-3">
                                    <span
                                        class="btn btn-lg btn-dark cursor-pointer"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="true"
                                        aria-controls="collapseTwo"
                                        @click="nextStep"
                                    >
                                        {{ $t('globals.next') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <StarTitle tag="h5">
                                    2 • {{ $t('masterclass.form.episodes.add.steps.content') }}
                                </StarTitle>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion-parent">
                            <div class="accordion-body">
                                <RequiredInputGuide />
                                <div class="row">
                                    <!-- <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="episode_number"
                                            v-model="formData.episode_number"
                                            name="episode_number"
                                            type="number"
                                            class="mb-4"
                                            :model-value="formData.episode_number"
                                            :label="$t('masterclass.form.episodes.add.fields.episodeNumber.label')"
                                            required
                                        />
                                    </div> -->
                                    <div class="col-12 col-lg-6">
                                        <BaseSelect
                                            id="type"
                                            v-model="formData.type"
                                            name="type"
                                            :data-info="`collapseTwo`"
                                            :model-value="formData.type"
                                            :label="$t('masterclass.form.episodes.add.fields.type.label')"
                                            :options="workoutEpisodeTypes"
                                            required
                                            @on-change="handleInput"
                                        />
                                    </div>
                                    <!-- <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="days_before_release"
                                            v-model="formData.days_before_release"
                                            name="days_before_release"
                                            type="number"
                                            :model-value="formData.days_before_release"
                                            :label="$t('masterclass.form.episodes.add.fields.daysBeforeRelease.label')"
                                        />
                                    </div> -->
                                </div>
                                <!-- <div class="row mt-4">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <BaseCheckbox
                                                id="is_paid"
                                                v-model="formData.is_paid"
                                                name="is_paid"
                                                :model-value="formData.is_paid"
                                                :label="$t('masterclass.form.episodes.add.fields.isPaid.label')"
                                                :inline="true"
                                            />
                                            <BaseInput
                                                v-if="formData.is_paid"
                                                id="price"
                                                v-model="formData.price"
                                                type="number"
                                                name="price"
                                                :model-value="formData.price"
                                                :label="$t('masterclass.form.episodes.add.fields.price.label')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <BaseCheckbox
                                                id="is_pinned"
                                                v-model="formData.is_pinned"
                                                name="is_pinned"
                                                :model-value="formData.is_pinned"
                                                :label="$t('masterclass.form.episodes.add.fields.isPinned.label')"
                                            />
                                        </div>
                                    </div>
                                </div> -->
                                <div v-if="!loadingStore.loading" class="row">
                                    <div class="col-12 mt-4">
                                        <ul v-if="formData.type !== 'video'" class="nav nav-tabs mb-4">
                                            <li
                                                v-for="(lang, keyLang) in settingsStore.availableLanguages"
                                                :key="keyLang"
                                                class="nav-item"
                                            >
                                                <a
                                                    class="d-flex justify-content-center align-items-center"
                                                    :class="navActiveByLang(lang.code)"
                                                    href="#"
                                                    @click.prevent="switchLang(lang.code)"
                                                >

                                                    <div class="p-0 m-0">Version ({{lang.code.toUpperCase()}})</div>
                                                    <FlagIcon :country="(lang.code === 'en') ? 'gb' : lang.code" class="m-0 me-1 p-0" />
                                                    <!-- {{ $t('globals.edit') + ' ' + lang.name }}-->
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- <BaseInput
                                            v-if="formData.type !== 'html-content' && formData.type !== 'mixed'"
                                            id="content"
                                            v-model="formData.content[currentLocale]"
                                            type="textarea"
                                            name="content"
                                            class="mb-4"
                                            :rows="7"
                                            :model-value="formData.content[currentLocale]"
                                            :label="
                                                $t('masterclass.form.episodes.add.fields.description.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                        /> -->
                                        <BaseTextEditor
                                            v-if="formData.type !== 'video'"
                                            id="content"
                                            v-model="formData.content[currentLocale]"
                                            toolbar="full"
                                            name="content"
                                            :data-info="`collapseTwo`"
                                            class="mb-4"
                                            :model-value="formData.content[currentLocale]"
                                            :label="
                                                $t('masterclass.form.episodes.add.fields.htmlContent.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                            @update:content="handleUpdateContent"
                                            @keyup="handleInput"
                                        />
                                    </div>
                                    <div v-if="formData.type === 'video' || formData.type === 'mixed'" class="col-12 row">
                                        <div class="col-12 col-lg-6">
                                            <label class="form-label form-label-boolean-check">
                                                {{ $t('masterclass.form.episodes.add.fields.file.video') }}
                                                <span class="text-danger">*</span>
                                            </label>
                                            <Dashboard
                                                :uppy="uppyVideo"
                                                :props="{
                                                    doneButtonHandler: null,
                                                }"
                                            />
                                        </div>
                                        <div
                                            v-if="item && item.video_url !== null && item.video_url !== ''"
                                            class="col-12 col-lg-6"
                                            style="max-height: 400px"
                                        >
                                            <label class="form-label form-label-boolean-check">
                                                {{ $t('masterclass.form.episodes.add.fields.file.current') }}
                                            </label>
                                            <div class="form-group preview">

                                                <PostVideoMedia
                                                    v-if="
                                                        item.video_url !== 'processing' &&
                                                        item.video_url !== 'pending' &&
                                                        item.video_url !== 'failed'
                                                    "
                                                    :id="`masterclass-form-episode-${item.id?? 'create'}`"
                                                    :key="`masterclass-form-episode-${item.id?? 'create'}-viewer'}`"
                                                    is-view
                                                    :video="item.video_url"
                                                    :poster="item.video_poster"
                                                    checked-visibility="normal"
                                                />
                                                <div v-else>
                                                    {{ $t(`masterclass.form.episodes.add.fields.videoStatus.${item.video_url}`) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <span
                                        class="btn btn-lg btn-gray cursor-pointer me-2"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        {{ $t('globals.previous') }}
                                    </span>
                                    <PrimaryButton v-if="!item" :loading="!submitButton.active">
                                        {{ props.submitButtonText }}
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="item" class="col-12 text-end mt-3">
                    <PrimaryButton :loading="!submitButton.active">
                        {{ props.submitButtonText }}
                    </PrimaryButton>
                </div>
            </Form>
        </div>
    </div>
</template>

<script setup>
import { Form } from 'vee-validate'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseSelect from '@/components/Form/BaseSelect.vue'
import BaseInput from '@/components/Form/BaseInput.vue'
import BaseTextEditor from '@/components/Form/BaseTextEditor.vue'
import { useMasterclassEpisodeStore } from '@/stores/masterclassEpisode'
import { useGlobalSettingsStore } from '@/stores/globalSettings'
import { useI18n } from 'vue-i18n'
import i18n from '@/translation'
import { computed, onBeforeMount, onMounted, ref, toRaw, watch } from 'vue'
import { array, boolean, number, object, string } from 'yup'
import NotificationService from '@/services/NotificationService'
import router from '@/router'
import { useRoute } from 'vue-router'
import ApiService from '@/services/ApiService'
import StarTitle from '@/components/Text/Title.vue'
import { useLoadingStore } from '@/stores/frontend/loading'
import VideojsPlayer from '@/components/VideojsPlayer.vue'
import { Dashboard } from '@uppy/vue'
import UppyService from '@/services/UppyService'
import RequiredInputGuide from '@/components/Text/RequiredInputGuide.vue'
import FlagIcon from 'vue-country-flag-next'
import PostVideoMedia from "@/views/Common/Posts/Media/PostVideoMedia.vue";
// import { useDraftStore } from '@/stores/DraftManager/draftStore'
import SmallLoader from "@/components/Layout/Content/SmallLoader.vue";

const { t } = useI18n()
let currentLocale = ref(i18n.global.locale.value)
const settingsStore = useGlobalSettingsStore()
const route = useRoute()
// const draftStore = useDraftStore()
// const draftOptions = ref({
//     type: 'episodes',
//     uuid: route.params.uuid ?? null,
// })
const loadingStore = useLoadingStore()

const props = defineProps({
    isEdit: {
        type: Boolean,
        default: false,
    },
    submitButtonText: {
        type: String,
        required: true,
    },
    item: {
        type: Object,
        default: null,
    },
})

const enums = ref({
    type: [],
})

const uppy = UppyService.init('episodes', {}, false)
const uppyVideo = UppyService.init('video-upload', {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['video/*'],
}, false)

const workoutEpisodeTypes = computed(() => {
    return {
        // audio:"Audio",
        // document: "PDF Document",
        video: t('masterclass.global.contentType.video'),
        'html-content': t('masterclass.global.contentType.html'),
        mixed: t('masterclass.global.contentType.mixed'),
    }
    // return enums.value.type
})

const allowedFileTypes = computed(() => {
    switch (formData.value.type) {
        case 'document':
            return 'application/pdf'
    }
})

let episodeForm = ref({})
let formData = ref({
    id: null,
    series_id: route.params.masterclassId,
    status: 'active',
    type: 'mixed',
    is_pinned: 0,
    is_paid: 0,
    episode_number: null,
    days_before_release: 0,
    price: 0,
    rejected_reason: null,
    title: {},
    description: {},
    content: {},
    cover: null,
    media: null,
    file: null,
    repetition: 0,
    set: 0,
    visibility: {
        visible: 'subscriber',
        price: null,
    },
})
let coverImage = ref(null)
let files = ref({})
let visibilities = ref({
    free: t('workout.exercise.form.create.visibility.everyone'),
    subscriber: t('workout.exercise.form.create.visibility.subscriber'),
})

loadingStore.setLoading(true)
watch(
    () => props.item,
    (item) => {
        
        coverImage.value = item.cover
        files.value = item.files

        formData.value = toRaw(item)
        formData.value.cover = null
        formData.value.series_id = route.params.masterclassId

        loadingStore.setLoading(false)

        videoOptions.value.sources.push({
            src: item.video_url,
            type: 'application/x-mpegURL',
            // withCredentials: true
        })
    }
)

const validationSchema = object({
    title: string().required(t('globals.validations.required')),
    // description: string().required().label(t('masterclass.form.episodes.add.fields.description.label')),
    content: array()
        .when('type', {
            is: (type) => type !== 'video',
            then: (schema) => schema.of(
                string()
                .required()
                .max(65500)
                .label(t('masterclass.form.episodes.add.fields.content.label'))
            ),
            otherwise: (schema) => schema.nullable(),
        }),
    type: string()
        .required()
        .label(t('masterclass.form.episodes.add.fields.type.label'))
        .typeError(t('globals.validations.required')),
    is_paid: boolean().optional().label(t('masterclass.form.episodes.add.fields.isPaid.label')),
    is_pinned: boolean().optional().label(t('masterclass.form.episodes.add.fields.isPinned.label')),
    price: number()
        .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value
        })
        .when('is_paid', {
            is: true,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
        })
        .typeError(t('globals.validations.number')),
    // episode_number: number()
    //     .required()
    //     .label(t('masterclass.form.episodes.add.fields.episodeNumber.label'))
    //     .typeError(t('globals.validations.number')),
    days_before_release: number()
        .nullable()
        .label(t('masterclass.form.episodes.add.fields.daysBeforeRelease.label'))
        .typeError(t('globals.validations.number')),
    rejected_reason: string().nullable().label(t('masterclass.form.episodes.add.fields.rejectedReason.label')),
})

function handleInput() {
    // draftStore.updateDraft(draftOptions.value.type, draftOptions.value.uuid, formData.value)
}

function onInvalidSubmit({ values, errors, results }) {

    if (errors && Object.keys(errors)[0]) {
        let lastSection = document.getElementById("collapseTwo")
        let element = document.getElementById(Object.keys(errors)[0])
        let parent = document.getElementById(element.getAttribute('data-info'))

        if ((parent !== undefined || parent !== null) && element !== undefined) {
            lastSection.classList.remove("show")
            parent.classList.add("show")
            element.scrollIntoView({ behavior: "smooth" })
        }
    }

    // console.log(values) // current form values
    // console.log(errors) // a map of field names and their first error message
    // console.log(results) // a detailed map of field names and their validation results
}

const navActiveByLang = (locale) => {
    return 'nav-link' + (locale === currentLocale.value ? ' active' : '')
}

const switchLang = (locale) => {
    currentLocale.value = locale
    // console.log(locale, currentLocale.value)
}

function handleUpdateContent(content) {
    formData.value.content[currentLocale.value] = content
    // episodeForm.value.setFieldValue(`content`, formData.value.content[currentLocale.value])
}

let coverTmp = ref(null)

function handleChangeCoverImage(file) {
    formData.value.cover = file
    if (file !== null) {
        coverTmp.value = [
            {
                source: file,
                options: {
                    type: 'input',
                },
            },
        ]
    }
}

let fileTmp = ref(null)

function handleChangeFile(file) {
    formData.value.file = file
    if (file !== null) {
        // Enable submit button
        submitButton.value.active = true
        fileTmp.value = [
            {
                source: file,
                options: {
                    type: 'input',
                },
            },
        ]
    }
}

let submitButton = ref({
    active: true,
})

function handleProcessing(_isProcessing = false) {
    submitButton.value.active = !!_isProcessing
}

const videoOptions = ref({
    autoplay: false,
    preload: true,
    // fluid: true,
    // withCredentials: true,
    html5: {
        vhs: {
            // withCredentials: true
        },
    },
    sources: [],
})


/* Form processing */
function submit() {
    handleProcessing()
    if (formData.value.cover !== null) {
        uppy.addFile(formData.value.cover)
        uppy.upload().then((result) => {
            if (result.failed.length <= 0) {
                result.successful.forEach((item) => {
                    const fileUploaded = {
                        path: item?.s3Multipart?.key,
                        name: item?.name,
                        type: item?.type,
                        size: item?.size,
                    }
                    if (item.type.match('video/*')) {
                        formData.value.file = fileUploaded
                    } else {
                        formData.value.cover = fileUploaded
                    }
                })
            }
            updateOrCreateWorkout(formData)
        })
    } else {
        updateOrCreateWorkout(formData)
    }
}

const EMPTY_CONTENT = {
    en: '',
    fr: '',
    es: '',
}

function updateOrCreateWorkout(formData) {

    if (formData.value.type === 'video') {
        formData.value.content = {...EMPTY_CONTENT}
    }
    else {
        formData.value.content = {
            en: formData.value.content['en'],
            es: formData.value.content['es'],
            fr: formData.value.content['fr'],
        }
    }

    if (formData.value.id !== null) {
        loadingStore.setLoading(true)

        useMasterclassEpisodeStore()
            .update(formData.value)
            .then(() => {
                return router.push({
                    name: 'kol-admin-view-masterclass-episodes',
                    params: {
                        masterclassId: route.params.masterclassId,
                    },
                })
            })
            .then(() => {
                NotificationService.notifyUserSuccess(t('masterclass.creator.notification.update.success'))
                loadingStore.setLoading(false)
            })
            .catch((error) => {
                // NotificationService.notifyUserError(error)
            })
            .finally(() => {
                handleProcessing(true)
            })
    } else {
        loadingStore.setLoading(true)
        useMasterclassEpisodeStore()
            .create(formData.value)
            .then(() => {
                return router.push({
                    name: 'kol-admin-view-masterclass-episodes',
                    params: {
                        masterclassId: route.params.masterclassId,
                    },
                })
            })
            .then(() => {
                // Need to remove a draft ?
                // if (draftOptions.value.uuid !== null) {
                //     draftStore.deleteDraft('masterclass', draftOptions.value.uuid)
                // }

                NotificationService.notifyUserSuccess(t('masterclass.creator.notification.add.success'))
                loadingStore.setLoading(false)
            })
            .catch((error) => {
                // NotificationService.notifyUserError(error)
            })
            .finally(() => {
                handleProcessing(true)
            })
    }
}

// Get last episode number
let query = ref({
    filter: {},
    sort: ['-episode_number'],
    page: 1,
    per_page: 1,
})

function lastEpisodeNumber() {
    useMasterclassEpisodeStore()
        .list(route.params.masterclassId, query.value)
        .then((result) => {
            if (formData.value.id === null) {
                formData.value.episode_number = result.data[0] !== undefined ? result.data[0].episode_number + 1 : 1
            }
        })
        .catch(() => {})
}

onBeforeMount(() => {
    ;['type'].map((type) => {
        ApiService.get(`enum/series-episode-type`).then(({ data }) => {
            enums.value[type] = data
        })
    })

    // Get the next episode number
    // lastEpisodeNumber()

    // if ('serviceWorker' in navigator) {
    //     UppyService.registerServiceWorker().then(() => UppyService.retryUpload(uppyVideo))

        uppyVideo.once('upload-progress', () => {
            handleProcessing()
        })
        uppyVideo.once('upload-success', () => {
            submitButton.value.active = true
        })
        uppyVideo.on('complete', (result) => {
            if (result.failed.length <= 0) {
                result.successful.forEach((item) => {
                    const fileUploaded = {
                        path: item?.s3Multipart?.key,
                        name: item?.name,
                        type: item?.type,
                        size: item?.size,
                    }
                    if (item.type.match('video/*')) {
                        formData.value.file = fileUploaded
                    } else {
                        formData.value.cover = fileUploaded
                    }
                })
            }
        })
    // }
})

onMounted(() => {
    if (!props.isEdit) {
        loadingStore.setLoading(false)
    }
//     if (draftOptions.value.uuid === null) {
//         draftStore
//             .startNewDraft(draftOptions.value.type, formData.value)
//             .then((response) => {
//                 draftOptions.value.uuid = response.uuid
//             })
//     }
//     else {
//             draftStore
//             .getOneDraft(draftOptions.value.type, draftOptions.value.uuid)
//             .then((response) => {
//                 formData.value = {
//                     ...response
//                 }
//             })
//     }
})

</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style src="@uppy/drag-drop/dist/style.css"></style>
<style src="@uppy/progress-bar/dist/style.css"></style>

<style scoped>
.cover-image {
    max-height: 250px;
}

.preview .video-js,
.preview video.vjs-tech {
    max-height: 400px !important;
}
</style>

<style>
.uppy-Dashboard-AddFiles-info {
    display: none !important;
}
</style>