<script setup>
import {toRefs} from 'vue';
import {useField} from 'vee-validate';
import BaseField from "@/components/Modals/Fields/BaseField.vue";

const props = defineProps({
    modelValue: {
        type: null,
    },
    name: String,
    value: {
        type: Boolean,
    },
    id: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    linkLabel: {
        type: String,
        default: '',
    },
    routeLabel: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    inline: {
        type: Boolean,
        default: false,
    },
    isSwitch: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const {name} = toRefs(props);
const {checked, handleChange, errorMessage} = useField(name, undefined, {
    type: 'checkbox',
    checkedValue: props.value,
});
</script>

<template>
    <base-field
        :id="id"
        :name="name"
        :label="label"
        :link-label="linkLabel"
        :route-label="routeLabel"
        :error-message="errorMessage"
        :is-inline="props.inline"
        :is-switch="isSwitch"
        :class-name="isSwitch ? 'form-check form-switch' : ''"
        :required="required"
    >
        <input
            :id="id"
            type="checkbox"
            class="form-check-input"
            :class="[{ 'is-invalid': errorMessage }]"
            :name="name"
            :role="isSwitch ? 'switch' : ''"
            :checked="checked"
            :disabled="disabled"
            @change="handleChange(value)"
        />

    </base-field>
</template>