import i18n from '@/translation'

const { t } = i18n.global

const UserMenuConfig = [
    // {
    //     heading: t('user.menu.dashboard'),
    //     routeName: 'user-dashboard',
    //     fontIcon: 'fa-solid fa-house me-3',
    //     image: 'menu-dashboard.svg',
    // },
    {
        heading: t('user.menu.feed'),
        routeName: 'user-dashboard-with-slug',
        fontIcon: 'fa-solid fa-house me-3',
        image: 'menu-dashboard.svg',
        withParam: 'slug'
    },
    // Messages
    {
        heading: t('user.menu.messages'),
        routeName: 'user-messages',
        fontIcon: 'fa-solid fa-envelope me-3',
        image: 'menu-messenger.svg',
        noIkonstar: true
    },
    // Workouts
    {
        heading: t('user.menu.workouts'),
        routeName: 'user-workouts',
        fontIcon: 'fa-solid fa-dumbbell me-3',
        image: 'menu-workouts.svg',
        module: 'workouts'
    },
    // Masterclass
    {
        heading: t('user.menu.masterclasses'),
        routeName: 'user-masterclass',
        fontIcon: 'fa-solid fa-star me-3',
        image: 'menu-masterclass.svg',
        imageLight: 'menu-masterclass-white.svg',
        module: 'masterclass'
    },
    // PODCASTS
    {
        heading: t('creator.menu.podcasts'),
        routeName: 'user-podcasts',
        fontIcon: 'fa-solid fa-podcast me-3 fs-5',
        module: 'podcasts'
    },
    // // Nutrition guides
    // {
    //     heading: t('user.menu.nutritionGuides'),
    //     routeName: 'user-account',
    //     fontIcon: 'fa-solid fa-book me-3',
    // },
    // // My plan
    // {
    //     heading: t('user.menu.myPlan'),
    //     routeName: 'user-account',
    //     fontIcon: 'fa-solid fa-calendar-alt me-3',
    // },
    // // My progress
    // {
    //     heading: t('user.menu.myProgress'),
    //     routeName: 'user-account',
    //     fontIcon: 'fa-solid fa-chart-line me-3',
    // },
    // // Photo gallery
    // {
    //     heading: t('user.menu.photoGallery'),
    //     routeName: 'user-account',
    //     fontIcon: 'fa-solid fa-images me-3',
    // },
    {
        heading: t('user.menu.myCalendar'),
        routeName: 'user-my-calendar',
        fontIcon: 'fa-solid fa-calendar-days me-3',
        image: 'menu-calendar.svg',
        noIkonstar: true
    },
    {
        heading: t('user.menu.myProgress'),
        routeName: 'user-progress',
        fontIcon: 'fa-solid fa-bars-progress me-3',
        image: 'menu-progress.svg',
        module: 'workouts'
    },
    {
        heading: t('user.menu.myFavorites'),
        routeName: 'user-favorites',
        fontIcon: 'fa-solid fa-heart me-3',
        image: 'menu-favourite.svg',
    },
    {
        heading: t('user.menu.myGallery'),
        routeName: 'user-gallery',
        fontIcon: 'fa-solid fa-image me-3',
        image: 'menu-gallery.svg',
        module: 'gallery'
    },
    {
        heading: t('user.menu.recipes'),
        routeName: 'user-recipes',
        fontIcon: 'fa-solid fa-table-list me-3',
        image: 'menu-recipes.svg',
        module: 'recipes'
    },
]

export default UserMenuConfig