<template>
    <div v-if="!useLoadingStore().loading && !loading">
        <section v-if="!isObjectEmpty(plans)">
            <div class="row">
                <div class="col-12">
                    <StarTitle tag="h2">
                        {{ $t('creator.plan.form.list.title') }}
                    </StarTitle>
                </div>
            </div>
            <Spacer size="0.938" />
            <div class="row">
                <div class="col-12">
                    <div class="list-group">
                        <div v-for="(planItem, key) in plans" :key="key" class="list-group-item list-group-item-action">
                            <div class="d-flex w-100 justify-content-between">
                                <div class="d-flex flex-column">
                                    <h5 class="mb-1 text-capitalize">
                                        {{ $t(`creator.plan.plans.${planItem.interval}.title`) }}
                                    </h5>
                                    <p class="mb-1">
                                        <span v-if="planItem.is_enabled">
                                            {{ formattedPrice(planItem) }}
                                        </span>
                                        <span v-else class="badge badge-pill badge-danger">
                                            {{ $t('creator.plan.form.list.status.values.disabled') }}
                                        </span>
                                    </p>
                                    <small>
                                        {{ $t(`creator.plan.plans.${key}.interval`) }}
                                    </small>
                                </div>
                                <div class="d-flex justify-content-between align-items-end flex-column">
                                    <div>
                                        <span v-if="planItem.is_enabled" class="badge badge-pill badge-success">
                                            {{ $t('creator.plan.form.list.status.values.enabled') }}
                                        </span>
                                    </div>
                                    <div>
                                        <button class="btn btn-tertiary rounded btn-sm" @click="showEditPlanModal(planItem)">
                                            {{ $t('creator.plan.form.list.button.edit.label') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else>

            <Form
                ref="onboardingForm"
                :validation-schema="step4ValidationSchema"
                :initial-values="initialValues"
                @submit="handleSubmit"
            >
                <div class="row mt-3 pb-5">

                    <div class="col-12">
                        <div class="col-12 col-md-6 mt-3">
                            <base-select
                                id="currency_id"
                                name="currency_id"
                                required
                                :options="currencies"
                                :placeholder="$t('creator.onboarding.form.step4.currency')"
                                :label="$t('creator.onboarding.form.step4.currency')"
                                is-object
                                option-text="name"
                                option-value="id"
                                @change="toggleCurrency"
                            />
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <span
                            class="d-block border rounded-3 p-4 my-2 priceCard"
                            :class="(plan === 1) ? 'bg-secondary' : 'bg-body-secondary'"
                            @click.prevent="selectPlan(1)"
                        >
                            <h5 class="mt-3">
                                {{ currencySymbol }}{{ prices[0] }}
                            </h5>
                            {{ t('creator.onboarding.form.step4.monthly') }}
                        </span>
                    </div>
                    <div class="col-6 text-center">
                        <span
                            class="d-block border rounded-3 p-4 my-2 priceCard"
                            :class="(plan === 2) ? 'bg-secondary' : 'bg-body-secondary'"
                            @click.prevent="selectPlan(2)"
                        >
                            <h5 class="mt-3">
                                {{ currencySymbol }}{{ prices[1] }}
                            </h5>
                            {{ t('creator.onboarding.form.step4.monthly') }}
                        </span>
                    </div>
                    <div class="col-6 text-center">
                        <span
                            class="d-block border rounded-3 p-4 my-2 priceCard"
                            :class="(plan === 3) ? 'bg-secondary' : 'bg-body-secondary'"
                            @click.prevent="selectPlan(3)"
                        >
                            <h5 class="mt-3">
                                {{ currencySymbol }}{{ prices[2] }}
                            </h5>
                            {{ t('creator.onboarding.form.step4.monthly') }}
                        </span>
                    </div>
                    <div class="col-6 text-center">
                        <span
                            class="d-block border rounded-3 p-4 my-2 priceCard"
                            :class="(plan === 4) ? 'bg-secondary' : 'bg-body-secondary'"
                            @click.prevent="selectPlan(4)"
                        >
                            <h5>{{ t('creator.onboarding.form.step4.custom') }}</h5>
                            <base-input
                                id="price"
                                name="price"
                                type="price"
                                step="0.01"
                                :model-value="`${prices[3]}`"
                                @click.prevent="selectPlan(4)"
                            />
                        </span>
                    </div>

                    <div
                        class="mt-1 col-12 d-flex"
                        style="column-gap: 1rem"
                    >
                        <button
                            type="submit"
                            class="btn btn-lg btn-tertiary rounded-3"
                        >
                            {{ $t('globals.global.form.button.submit.label') }}
                        </button>
                    </div>
                </div>
            </Form>
        </section>
        <section v-if="!isObjectEmpty(plans) && !creatorId" class="mt-5">
            <MyPlanPerks />
        </section>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user'
import {computed, onBeforeMount, ref} from 'vue'
import ApiService from '@/services/ApiService'
import Spacer from '@/components/Spacer.vue'
import StarTitle from '@/components/Text/Title.vue'
import MyPlanPerks from '@/views/KolAdmin/MySettings/MyPlanPerks.vue'
import { useFormModalStore } from '@/stores/formModal'
import { boolean, number, object, string } from 'yup'
import NotificationService from '@/services/NotificationService'
import i18n from '@/translation'
import { useLoadingStore } from '@/stores/frontend/loading'
import {useCreatorStore} from "@/stores/frontend/creator";
import { Form } from 'vee-validate'
import BaseInput from '@/components/Form/BaseInput.vue'


const { t } = i18n.global

const props = defineProps({
    creatorId: {
        type: String,
        default: null,
    },
})

const userStore = useUserStore()
const formModalStore = useFormModalStore()

const loading = ref(true)
const plans = ref({})
const selectedPlan = ref(null)

const computedCreatorId = props.creatorId !== null ? props.creatorId : useCreatorStore().creator.id

function showEditPlanModal(plan) {
    selectedPlan.value = plan

    formModalStore.showFormModal({
        title: t('creator.plan.form.edit.title'),
        fields: [
            {
                type: 'price',
                name: 'price',
                label: t('creator.plan.form.edit.price.label') + `(${plan.currency.code})`,
                defaultValue: plan.price,
                className: 'col-lg-6',
                rules: number()
                .typeError(t('creator.plan.form.edit.price.formatError'))
                .required()
                .min(0)
                .label(t('creator.plan.form.edit.price.label')),
                required: true,
            },
            {
                type: 'checkbox',
                name: 'is_enabled',
                label: t('creator.plan.form.edit.isEnabled.label'),
                defaultValue: plan.is_enabled,
                className: 'col-lg-6',
                rules: boolean().label(t('creator.plan.form.edit.isEnabled.label')),
            },
        ],
        submit: t('creator.plan.form.edit.button.submit.label'),
        onSubmit: onSubmit,
    })
}

function onSubmit(params) {
    ApiService.patch(`creator/${computedCreatorId}/plans/${selectedPlan.value.id}`, {
        price: params.price,
        is_enabled: params.is_enabled,
    }).then(() => {
        formModalStore.hideFormModal()
        selectedPlan.value = null

        refreshPlans()

        NotificationService.notifyUserSuccess(t('creator.plan.form.edit.message.success'))
    })
}
const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0 && objectName.constructor === Object;
}
function refreshPlans() {
    useLoadingStore().setLoading(true)

    plans.value = {}

    ApiService.get(`creator/${computedCreatorId}/plans`).then(({ data: _plans }) => {
        _plans.map((plan) => {
            plans.value[plan.interval] = plan
        })

        loading.value = false
        useLoadingStore().setLoading(false)
    })
}

onBeforeMount(() => {
    refreshPlans()
    ApiService.get('sites/' + import.meta.env.VITE_SITE_UUID + '/uuid')
    .then(({data}) => {
        currencies.value = {...data.availableCurrencies}
    })
})

const onboardingForm = ref()

const plan = ref(1)

const prices = ref([9.99,19.99,49.99,4.99])

const currencies = ref({})

const currencySymbol = ref('')

const initialValues = ref({})

const step4ValidationSchema = computed(() => {
    return object({
        currency_id: string()
        .typeError(t('creator.onboarding.invalidCurrency'))
        .required(t('creator.onboarding.requiredCurrency'))
        .not(['not-selected']),

        price: number()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .min(4.99, t('creator.onboarding.invalidPrice')),
    })
})
const handleSubmit = (values) => {
    let price = plan.value !== 4
        ? prices.value[(plan.value-1)].toString()
        : (values.price < 4.99 || values.price === '') ? 4.99 : values.price

    useLoadingStore().setLoading(true, true)
    userStore.submitOnboardingData({ ...values, price,  step: 5, "status": "complete" })
    .then(() => {
        userStore.fetchUser(false)
        .then(() => {
            refreshPlans()
        })
    })
}
// Toggle plan
function selectPlan(_plan) {
    plan.value = _plan
}


// Change currency into the plan card
function toggleCurrency() {
    let formValues = onboardingForm.value.getValues()
    Object.values(currencies.value)
        .map((currency) => {
            if(currency.id === formValues.currency_id) {
                currencySymbol.value = currency.symbol
            }
        })
}


const currentLocale = computed(() => i18n.global.locale.value)
const moneyFormat = {
    en: 'en-GB',
    fr: 'fr-FR',
    es: 'es-ES'
}

const formattedPrice = (_plan) => {
    return new Intl.NumberFormat(moneyFormat[currentLocale.value], { style: 'currency', currency: _plan.currency.code }).format((_plan.price))
}

</script>