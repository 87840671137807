<template>
    <BaseField
        :id="id"
        type="select"
        :name="name"
        :label="label"
        :success-message="successMessage"
        :helper="helper"
        :big="big"
        :light="light"
        :is-grouped="isGrouped"
        :required="required"
    >
<!--        <div class="select-wrapper">-->
            <select
                :id="id"
                class="form-select rounded-3"
                :class="[
                    { 'is-invalid': errorMessage },
                    { 'rounded-end-0': isGrouped },
                    big ? 'bg-white px-4 text-dark' : 'bg-white text-dark',
                    light ? 'bg-light' : '',
                ]"
                :style="`${big ? '' : 'padding: 0.75rem 0.938rem;'}`"
                :name="name"
                :value="value"
                :disabled="disabled"
                :data-info="dataInfo"
                :aria-describedby="ariaDescribedBy"
                @input="handleChange"
                @blur="handleBlur"
            >
                <option v-if="placeholder" value="">
                    {{ placeholder }}
                    <span v-if="required" class="text-danger">*</span>
                </option>
                <template v-if="isObject">
                    <option
                        v-for="(option, keyName) in options"
                        :key="keyName"
                        :value="option[optionValue]"
                    >
                        {{ option[optionText] }}
                    </option>
                </template>
                <template v-else-if="isArray">
                    <option
                        v-for="(optionName, keyName) in options"
                        :key="keyName"
                        :value="optionName"
                    >
                        {{ optionName }}
                    </option>
                </template>
                <template v-else>
                    <option
                        v-for="(optionName, keyName) in options"
                        :key="keyName"
                        :value="keyName"
                    >
                        {{ optionName }}
                    </option>
                </template>

            </select>
<!--        </div>-->
    </BaseField>
</template>

<script setup>
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'
import BaseField from '@/components/Form/BaseField.vue'

const props = defineProps({
    value: {
        type: [String, Number],
        default: '',
    },
    modelValue: {
        type: [String, Number],
        default: '',
    },
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    options: {
        type: Object,
        required: true,
    },
    helper: {
        type: [Boolean, String],
        default: false,
    },
    big: {
        type: Boolean,
        default: false,
    },
    light: {
        type: Boolean,
        default: false,
    },
    isGrouped: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isArray: {
        type: Boolean,
        default: false,
    },
    isObject: {
        type: Boolean,
        default: false,
    },
    optionText: {
        type: String,
        default: '',
    },
    optionValue: {
        type: String,
        default: '',
    },
    dataInfo: {
        type: String,
        default: '',
    },
})

const successMessage = ''

const ariaDescribedBy = computed(() => {
    return `${props.id}Help`
})

const name = toRef(props, 'name')
const { value, errorMessage, handleBlur, handleChange } = useField(
    name,
    undefined,
    {
        initialValue: props.modelValue,
        syncVModel: true,
    }
)
</script>

<style scoped>
.bg-light {
    background-color: #ebebeb !important;
}
</style>