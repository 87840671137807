<template>
    <div>
        <BaseField
            :id="id"
            :name="name"
            :label="label"
            :required="required"
        >
            <div class="quill-editor-container">
<!--                    :content="modelValue"-->
                <QuillEditor
                    ref="editor"
                    :options="options"
                    style="min-height: 600px"
                    :name="name"
                    content-type="html"
                    :content="modelValue"
                    :placeholder="placeholder"
                    @update:content="$emit(`update:${name}`, $refs.editor.getHTML());$emit('update:content', $refs.editor.getHTML()); handleChange"
                    @blur="handleBlur"
                    @ready="ready"
                />
            </div>
        </BaseField>
    </div>
</template>

<script setup>
import BaseField from '@/components/Form/BaseField.vue'
import i18n from '@/translation'
import {onMounted, onUnmounted, ref, watch} from 'vue'
import {Quill, QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {useField} from "vee-validate";

const editor = ref(null)

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    errorMessage: {
        type: String,
        default: '',
    },
    value: {
        type: String,
        default: '',
    },
    modelValue: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    language: {
        type: String,
        default: i18n.global.locale.value,
    },
    toolbar: {
        type: String,
        default: 'essential',
    },
    required: {
        type: Boolean,
        default: false,
    },
})
let content = ref(props.modelValue)
let icons = Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

const unwatch = watch(
    () => [props.modelValue, props.name],
    ([value, name]) => {
        if (value === '') {
            editor.value.setHTML(null)
            content.value = ''
        }
        content.value = value
    }
);


const {value, errorMessage, handleBlur, handleChange} = useField(name, undefined, {
    initialValue: props.modelValue,
    syncVModel: true,
})


onMounted(() => {
    // editor.value.setHTML(props.modelValue)
    editor.value.setContents(props.modelValue, 'api')
})

onUnmounted(() => {
    unwatch();
})

// onUpdated(() => {
//     editor.value.setHTML(props.modelValue)
// })

function ready(){
    editor.value.setHTML( props.modelValue)
}
function undoChange() {
    editor.value.getQuill().history.undo()
}
function redoChange() {
    editor.value.getQuill().history.redo()
}

const toolbarOptions = {
    essential: [
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline'],
        [{list: 'ordered'}, {list: 'bullet'}, {align: []}],
        ['blockquote', 'code-block', 'link'],
        [{color: []}, 'clean'],
        ['undo', 'redo']
    ],
    minimal: [
        [{header: 1}, {header: 2}],
        ['bold', 'italic', 'underline'],
        [{list: 'ordered'}, {list: 'bullet'}, {align: []}],
    ],
    full: [
        [{font: []}, {align: []}],
        [{size: ['small', false, 'large', 'huge']}, {header: [1, 2, 3, 4, 5, 6, false]}], // custom dropdown
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{list: 'ordered'}, {list: 'bullet'}],
        [{script: 'sub'}, {script: 'super'}], // superscript/subscript
        [{indent: '-1'}, {indent: '+1'}], // outdent/indent

        ['clean'], // remove formatting button
    ],
}


const options = {
    modules: {
        toolbar: {
            container: toolbarOptions[props.toolbar],
            handlers: {
                'undo': undoChange,
                'redo': redoChange,
            }
        }
    }
}
</script>

<style lang="scss">
.ql-container {
    display: flex;

    .ql-editor {
        flex: 1;
    }
}
</style>