import {useUserStore} from "@/stores/user";
import i18n from "@/translation";

export function useNavigatorLanguage() {

    function setLanguage() {

        let lang = (navigator.language.split('-')[0])

        if (!i18n.global.availableLocales.includes(lang)){
            lang = 'en'
        }

        if (
            !useUserStore().isAuthenticated
            && !localStorage.getItem('user-selected-lang')
            && i18n.global.availableLocales.includes(lang)
        ) {
            useUserStore().setLanguage(lang)
            localStorage.setItem('user-selected-lang', lang)
            i18n.global.locale.value = lang
        }
        else if (
            useUserStore().isAuthenticated
            && useUserStore().isGuest
            && !useUserStore().currentLang
        ) {
            useUserStore().setLanguage(lang)
            localStorage.setItem('user-selected-lang', lang)
            i18n.global.locale.value = lang
        }
        else if (
            useUserStore().isAuthenticated
            && !useUserStore().isGuest
        ) {
            i18n.global.locale.value = useUserStore().currentLang ?? 'en'
        }
    }

    return { setLanguage }
}