import { useUserStore } from '@/stores/user'
import JwtService from "@/services/JwtService";

export const authMiddleware = (to, from, next) => {
    
    if (to.name === 'user-dashboard-with-slug') {
        return next()
    }

    const { isVerified, isGuest } = useUserStore()

    let isAuthenticated = !!JwtService.getToken()

    if (!isAuthenticated) {
        return next({ name: 'home' })
    }

    if (!isAuthenticated) {
        return next({ name: 'home' })
    }

    // if (!isVerified && !isGuest) {
    //     return next({ name: 'account-verification' })
    // }

    if(to.name === 'payment' && isGuest) {
        return next({ name: 'home' })
    }

    return next()
}