import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import ApiService from '@/services/ApiService'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import { useCreatorStore } from '@/stores/frontend/creator'
import {useUserStore} from "@/stores/user";

const DEFAULT_RECIPE_TEST = {
    id: null,
    title: { en: 'title-en', fr: 'title-fr', es: 'title-es' },
    description: { en: 'description-en', fr: 'description-fr', es: 'description-es' },
}
const DEFAULT_RECIPE = {
    id: null,
    title: null,
    description: null,
}
const DEFAULT_RECIPE_CREATING = {
    id: null,
    hasStarted: false,
    step: 0,
    language: '',
    title: null,
    description: null,
    type: '',
    cooking_type: '',
    servings_nb: 0,
    servings_unit: '',

    preparation_time_hours: 0,
    preparation_time_minutes: 0,
    cooking_time_hours: 0,
    cooking_time_minutes: 0,
    resting_time_hours: 0,
    resting_time_minutes: 0,

    files: [],
}

export const usePrivateRecipesStore = defineStore('kolPrivateRecipes', () => {
    const recipes = ref(null)
    const creatorStore = useCreatorStore()

    const creatingRecipe = ref(DEFAULT_RECIPE_CREATING)

    const creatingRecipeInStorage = localStorage.getItem('kol-private-recipe-creating')
    if (creatingRecipeInStorage) {
        const storageCreatingRecipe = JSON.parse(creatingRecipeInStorage)
        creatingRecipe.value = {
            ...creatingRecipe.value,
            ...storageCreatingRecipe,
        }
    }
    watch(
        () => creatingRecipe,
        (state) => {
            localStorage.setItem('kol-private-recipe-creating', JSON.stringify(state.value))
        },
        { deep: true }
    )

    const isCreating = computed(() => creatingRecipe.value.hasStarted)

    /**
     * Start a recipe
     *
     * @param {Object} data Recipe data
     * @param {number} [step=1] Which form step are you on?
     * @returns {Promise<Object>}
     */
    function startRecipeForm(data, step = 1) {
        return new Promise((resolve) => {
            creatingRecipe.value = {
                ...creatingRecipe.value,
                ...data,
                step,
                hasStarted: true,
            }
            resolve(creatingRecipe.value)
        })
    }

    /**
     * Update a recipe form
     *
     * @param {Object} data Recipe data
     * @param {number} [step=1] Which form step are you on?
     * @returns {Promise<Object>}
     */
    function updateRecipeForm(data, step = 1) {
        return startRecipeForm(data, step)
    }

    /**
     * Reset recipe form
     *
     * @returns {Promise<Object>}
     */
    function resetRecipeForm() {
        return new Promise((resolve) => {
            creatingRecipe.value = DEFAULT_RECIPE_CREATING
            resolve(creatingRecipe.value)
        })
    }

    // API

    /**
     * Get all private recipes
     *
     * @param {string} [cursor=""] InfiniteScroller cursor value
     * @param {Object} [query={}] QueryParam for url
     * @param {Object} [query.filter={}] Filters QueryParam for url
     * @param {string} [query.search=''] Search query
     * @param {number} [query.page=1] Request specific page
     * @returns {Promise<AxiosResponse>}
     */
    function getAll(cursor = '', query = {}) {
        let queryFilter = ''

        if (cursor) {
            query = { ...query, cursor }
        }

        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }
        return ApiService.getAll(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes` + queryFilter)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(id) {
        return ApiService.get(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes/${id}`)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create a recipe
     *
     * @param {Object} data Recipe data
     * @returns {Promise<Object>} Created recipe
     */
    function create(data) {
        return ApiService.post(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes`, {
            ...data,
            creator_id: creatorStore.creator.id,
        })
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data
            })
    }

    /**
     * Update a recipe
     *
     * @param {Object} data Recipe data
     * @param {Integer} recipeId Recipe ID
     * @returns {Promise<Object>} Created recipe
     */
    function update(data, recipeId) {
        return ApiService.patch(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes/${recipeId}`, {
            ...data,
            creator_id: creatorStore.creator.id,
        })
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a video
     *
     * @param {Integer} recipeId Recipe ID
     * @param {String} videoId Video Id
     * @returns {Promise<Object>} Created recipe
     */
    function deleteVideo(recipeId, videoId) {
        return ApiService.delete(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes/${recipeId}/video/${videoId}`)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a image
     *
     * @param {Integer} recipeId Recipe ID
     * @param {String} imageId Image Id
     * @returns {Promise<Object>} Created recipe
     */
    function deleteImage(recipeId, imageId) {
        return ApiService.delete(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes/${recipeId}/image/${imageId}`)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a private recipe
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function deleteRecipe(recipeId) {
        return ApiService.delete(`creator/${useUserStore().selectedCreator.id}/creator-private-recipes/${recipeId}`)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        recipes,
        creatingRecipe,

        isCreating,

        startRecipeForm,
        updateRecipeForm,
        resetRecipeForm,

        getAll,
        getById,
        create,
        update,

        deleteVideo,
        deleteImage,
        deleteRecipe,
    }
})