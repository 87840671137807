import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import { QueryParamBuilder } from "@/helpers/QueryParamBuilder";

export const useDirectLiveStore = defineStore('directLive', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get one direct live
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function get(creatorId) {
        return ApiService.get(`/my/${creatorId}/direct-live`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get/Create direct live creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function start() {
        return ApiService.post(`/direct-live`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                console.log(data)
                // throw data.message
            })
    }

    /**
     * Update direct live status
     * @param liveStreamingId
     * @param data
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(liveStreamingId, data) {
        return ApiService.patch(`/direct-live/${liveStreamingId}`, data)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get direct live informations
     * @param liveStreamingId
     * @param data
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function settings(liveStreamingId, data) {
        // return ApiService.patch(`/live-streaming/${liveStreamingId}`, data)
        //     .then((result) => {
        //         return result
        //     })
        //     .catch(({ data }) => {
        //         throw data.message
        //     })
    }

    /**
     * Ban an user (chat)
     * @param directLiveUuid
     * @param userId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function chatBanUser(directLiveUuid, userId) {
        return ApiService.post(`/direct-live/${directLiveUuid}/ban-user`, {user_id: userId})
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Unban an user (chat)
     * @param directLiveUuid
     * @param userId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function chatUnbanUser(directLiveUuid, userId) {
        return ApiService.post(`/direct-live/${directLiveUuid}/unban-user`, {user_id: userId})
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        get,
        start,
        update,
        settings,
        chatBanUser,
        chatUnbanUser,
        setErrors,
    }
})
