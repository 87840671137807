import { v4 as uuidv4 } from 'uuid'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import { useUserStore } from '@/stores/user'
import ApiService from '@/services/ApiService'
import { useCreatorStore } from '@/stores/frontend/creator'
import NotificationService from '@/services/NotificationService'
import { useLoadingStore } from '@/stores/frontend/loading'
import router from '@/router'

class FCMNotificationService {
    static firebaseConfig
    static vapidKey
    static firebase
    static messaging
    static userFCMToken
    static deviceId
    static serviceWorkerRegistration

    static async initialize(serviceWorkerRegistration) {
        return new Promise((resolve, reject) => {
            this.serviceWorkerRegistration = serviceWorkerRegistration
            this.firebaseConfig = {
                apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
                authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
                projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
                storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
                appId: import.meta.env.VITE_FIREBASE_APP_ID,
                measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
            }
            this.vapidKey = import.meta.env.VITE_VAPID_PUBLIC
            this.firebase = null
            this.messaging = null
            this.userFCMToken = useUserStore().user.fcm_token
            this.deviceId = this.retrieveOrGenerateDeviceId()

            try {

                isSupported()
                .then((isSupported) => {
                    if (isSupported) {
                        this.firebase = initializeApp(this.firebaseConfig)
                        this.messaging = getMessaging(this.firebase)

                        // Handle incoming messages while on the page
                        onMessage(this.messaging, (payload) => {
                            // console.log('Message received: ', payload);

                            NotificationService.notifyUserInfo(payload.data, {
                                onConfirm: async () => {
                                    useLoadingStore().setLoading(true, true)
                                    await router.push(payload.data.url)
                                    useLoadingStore().setLoading(false)
                                },
                            })
                            // Handle the incoming message, e.g., display a notification
                        })

                        let token = this.userFCMToken
                        if (token) {
                            this.getToken(true).then(() => resolve(token))
                        } else {
                            resolve(true)
                        }
                    }
                    else {
                        resolve(false)
                    }
                })
            } catch (error) {
                // console.error('Error initializing FCM: ', error);
                reject(error)
            }
        })
    }

    static async requestPermission() {
        // Check if there's already a token
        let token = this.userFCMToken
        if (!token) {
            try {
                this.deviceId = this.retrieveOrGenerateDeviceId()
                await Notification.requestPermission()
                // console.log('Notification permission granted.');

                return await this.getToken()
            } catch (error) {
                // console.error('Unable to get permission to notify: ', error);
            }
        } else {
            return await this.getToken(true)
        }
    }

    static async sendTokenToServer(token, forceRefresh = false) {
        useUserStore().user.fcm_token = token
        useUserStore().user.device_id = this.deviceId
        return ApiService.post('/notifications/register', {
            token,
            device_id: this.deviceId,
            creator_ulid: useCreatorStore().creator.ulid,
            refreshed: forceRefresh
        })
        .then(({ data }) => {
            useUserStore().user.fcm_token = data.token
            useUserStore().user.device_id = data.device_id
            useUserStore().user.devices.push({
                fcm_token: data.token,
                device_id: data.device_id,
            })
            return data
        })
        .catch((error) => {
            // console.error(error)
        })
    }

    static async getToken(forceRefresh = false) {
        return getToken(this.messaging, {
            forceRefresh,
            vapidKey: this.vapidKey,
            serviceWorkerRegistration: this.serviceWorkerRegistration,
        })
            .then((token) => {
                if (token) {
                    return this.sendTokenToServer(token, forceRefresh)
                } else {
                    // Show permission request UI
                    // console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            })
            .catch((err) => {
                // console.log('An error occurred while retrieving token. ', err);
            })
    }

    static retrieveOrGenerateDeviceId() {
        let deviceId = localStorage.getItem('device_id')
        if (!deviceId) {
            // Generate a new device ID
            deviceId = uuidv4()
            // Store the device ID persistently
            localStorage.setItem('device_id', deviceId)
        }
        this.deviceId = deviceId

        return deviceId
    }
}

export default FCMNotificationService