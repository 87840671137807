<template>

    <div class="card col border-0 text-center mb-3 {{ props.customClass }}">
        <div class="card-body">
<!--            <select class="form-select mb-3 rounded-3 bg-white" aria-label="monthChoice">-->
<!--                <option selected>Ce mois-ci</option>-->
<!--                <option value="1">Mois m-1</option>-->
<!--                <option value="2">Mois m-2</option>-->
<!--                <option value="3">Mois m-3</option>-->
<!--            </select>-->
            <div class="card-title">
                {{ props.title }}
            </div>
            <div class="card-number">
                {{ props.number }}
            </div>
<!--            <a href="#" class="text-dark">VOIR TOUT</a>-->
        </div>
    </div>

</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    number: {
        type: String,
        required: true,
    },
    customClass: {
        type: String,
        default: '',
    },
})
</script>
<style scoped>
.card-primary {
    /* background: #ffffff url('@/assets/images/card-stat-primary.svg') no-repeat;
    background-position: top right; */
}
.card-secondary {
    background: #8ed8e6 url('@/assets/images/card-stat-secondary.svg')
        no-repeat -3.625rem 0rem;
}

.card-title {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 90%;
    text-align: center;
    /* color: #000; */
}
.card-number {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 200%;
    text-align: center;
    /* color: #fff;
    text-shadow: 2px 2px 0px #c79f56; */
}
.card-secondary .card-number {
    /* text-shadow: 2px 2px 0px #57acbc; */
}
</style>