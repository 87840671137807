<template>
    <div class="workout-container">
        <span class="workout-card" style="cursor: pointer" @click.prevent="redirect(props.to)">
            <img
                v-if="!props.imageUrl"
                class="workout-card-image"
                src="@/assets/images/masterclass_placeholder.jpg"
                role="presentation"
            />
            <img
                v-else
                :src="props.imageUrl"
                class="workout-card-image"
                role="presentation"
            />
            <div class="workout-card-content">
                <h2 class="workout-title">{{ props.title }}</h2>
                <p v-if="episodeCount" class="workout-card-text">
                    {{ props.episodeCount }}
                    {{ $t('workout.form.list.countSuffix') }}
                </p>
                <slot name="description"/>
                <p class="workout-description">{{ props.description }}</p>
            </div>
        </span>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import { useRegistrationCtaModalStore } from "@/stores/registrationCtaModal";
import router from '@/router'

/** Stores **/
const userStore = useUserStore()
const registerCtaStore = useRegistrationCtaModalStore()

/** Props **/
const props = defineProps({
    to: {
        type: Object,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    description: {
        type: String,
        required: true,
    },
    episodeCount: {
        type: Number,
        default: 0,
    },
    imageUrl: {
        type: String,
        required: true,
    },
})

// Router push to redirect
function redirect(_path) {
    // if (userStore.cannot('goto')){
    //     console.log('CTA')
    //     registerCtaStore.showModal()
    //     // TODO: CTA
    //     return false;
    // }
    router.push(_path)
}
</script>

<style scoped>
.workout-container {
    margin-top: 1.25rem;
}
.workout-card {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
.workout-card-image {
    width: 100%;
    object-fit: cover;
    border-radius: 0.625rem;
    height: 12rem;
    filter: brightness(50%);
}
.workout-card-content {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0.625rem 1rem;
    width: 100%;
}
.workout-title {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 1.625rem;
    letter-spacing: -0.04em;
    line-height: 2.25rem;
    color: #fff;
    max-height: 6.875rem;
    overflow: hidden;
}
.workout-description {
    overflow: hidden;
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: normal;
    color: white;
    margin-bottom: 0;
    max-height: 2.2rem;
}
.workout-card-text {
    margin: 0;
    padding: 0;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.313rem;
    text-align: left;
    color: var(--primary-button-background-color);
}
</style>