import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getRouteNameForStaticPage } from '@/config/StaticPageConfig'

const useStaticPageStore = defineStore('staticPage', () => {
    const router = useRouter()

    const lang = computed(() => router.currentRoute.value.meta.lang)
    const page = computed(() => router.currentRoute.value.meta.page)
    const title = computed(() => router.currentRoute.value.meta.title)

    const currentLanguage = ref(lang.value || 'en')

    watch(currentLanguage, () => {
        router
            .push({
                name: getRouteNameForStaticPage(
                    page.value,
                    currentLanguage.value
                ),
            })
            .then()
    })

    const getRouteNameForPage = (page) => {
        return getRouteNameForStaticPage(page, currentLanguage.value)
    }

    return {
        currentLanguage,
        title,
        getRouteNameForPage,
    }
})

export default useStaticPageStore
