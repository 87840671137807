import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import {computed, ref, watch} from 'vue'

export const useGlobalSettingsStore = defineStore('globalSettings', () => {

    const settings = ref({
        languages: [],
        hlsSupported: true
    })

    const availableLanguages = computed(() => settings.value.languages)

    /**
     * Cache storage
     */
    const settingsInStorage = localStorage.getItem('globalSettings')
    if (settingsInStorage) {
        const storageSettings = JSON.parse(settingsInStorage)
        settings.value = {
            ...settings.value,
            ...storageSettings,
        }
    }
    watch(
        () => settings,
        (state) => {
            localStorage.setItem('globalSettings', JSON.stringify(state.value))
        },
        { deep: true }
    )

    function setHlsSupport(_supported) {
        settings.value.hlsSupported = _supported
    }

    async function init() {

        if (!settings.value.languages.length || settings.value.languages.length < 3) {
            await getAvailableLanguages()
        }
    }

    /**
     * Get available languages
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getAvailableLanguages() {
        return ApiService.get('languages')
            .then(({ data }) => {
                settings.value.languages = data
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        settings,
        availableLanguages,

        init,
        setHlsSupport,
    }
})