<template>
    <div>
        <template v-if="fromMenu">
            <i class="fs-3 mt-1 fa-solid fa-share text-white" @click.prevent="createLink"></i>
        </template>
        <template v-else>
        <div v-if="item.creator" class="mb-2 me-2">
            <span class="btn btn-outline-dark rounded-3 fw-light" @click.prevent="createLink">
<!--                <i class="fa-solid fa-share"></i>-->
                <i class="fa-regular fa-share-nodes"></i>
                <span v-if="$breakpoints.lgAndUp.value" class="ms-2">{{ $t('globals.share.title') }}</span>

            </span>
        </div>
        <div v-else class="m-0 p-0">
            <span
                v-tooltip="{title: $t('globals.tooltips.share')}"
                class="d-flex align-items-center text-decoration-none text-black-dark-mode cursor-pointer"
                @click.prevent="createLink"
                style="font-size: 14px; color: #737373"
            >
<!--                <i class="fa-solid fa-share"></i>-->
                <i class="fa-regular fa-share-nodes me-2 fs-5"></i>
                {{ $t('globals.share.title') }}
            </span>
        </div>
        </template>
        
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useCreatorStore } from '@/stores/frontend/creator';
import { useShareStore } from '@/stores/share';
import ApiService from '@/services/ApiService'
import router from "@/router";

/** Stores **/
const creatorStore = useCreatorStore();
const shareStore = useShareStore();

/** Init parameters **/
let link = ref(null)

/** Props **/
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    fromMenu: {
        type: Boolean,
        default: false,
    },
    creatorUlid: {
        type: String,
        default: '',
    },
    creatorSlug: {
        type: String,
        default: '',
    },

})

/** Methods **/
// Get link (copy)
function createLink() {

    let ulid = props.creatorUlid !== '' ? props.creatorUlid : creatorStore.creator.ulid
    let slug = props.creatorSlug !== '' ? props.creatorSlug : creatorStore.creator.slug

    ApiService.post('share-link', {
        id: props.item.data.id,
        type: props.item.type,
        creator_ulid: ulid,
    })
    .then(({data}) => {
        link.value = data.link
        shareStore.show({
            data: props.item.data,
            creator: props.item.creator,
            link: props.item.creator ? import.meta.env.VITE_APP_URL + '/' + slug : link.value,
            network_link: link.value
        });
    })
}

</script>