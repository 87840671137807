import { defineStore } from 'pinia'
import {computed, ref} from 'vue'

const EMPTY_MODAL = {
    title: '',
    fields: [],
    submit: '',
    cancel: false,
    onSubmit: () => {},
    onCancel: () => {},
}

export const useFormModalStore = defineStore('form-modal', () => {
    const hasActiveModal = ref(false)
    const isSubmissionActive = ref(false)
    const modal = ref(EMPTY_MODAL)
    const defaultValues = ref({})
    const errors = ref({})
    let onDisplayCallbacks = []
    let onHideCallbacks = []

    const isSubmitted = computed(() => isSubmissionActive)

    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    function showFormModal(displayedModal) {
        hasActiveModal.value = true
        modal.value = displayedModal

        let newValidationSchema = {}
        displayedModal.fields.forEach((field) => {
            if (field.rules) {
                newValidationSchema[field.name] = field.rules
            }
        })

        let newDefaultValue = {}
        displayedModal.fields.forEach((field) => {
            if (field.defaultValue !== undefined) {
                newDefaultValue[field.name] = field.defaultValue
            }
        })
        defaultValues.value = newDefaultValue

        onDisplayCallbacks.forEach((callback) =>
            callback(defaultValues.value, newValidationSchema)
        )
    }

    function hideFormModal() {
        hasActiveModal.value = false
        modal.value = EMPTY_MODAL
        defaultValues.value = {}
        errors.value = {}

        onHideCallbacks.forEach((callback) => callback())
    }

    function setErrors(values) {
        errors.value = values
    }

    function setSubmitted(_submitted = true) {
        isSubmissionActive.value = _submitted
    }

    return {
        hasActiveModal,
        modal,
        defaultValues,
        errors,
        isSubmitted,

        setErrors,
        onDisplay,
        onHide,
        showFormModal,
        hideFormModal,
        setSubmitted,
    }
})
