import { defineStore } from 'pinia'
import { ref } from 'vue'
import ApiService from '@/services/ApiService'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'

const EMPTY_COMMENTS = {
    galleryId: null,
    elem: null,
    comments: [],
}

export const useGalleryStore = defineStore('gallery', () => {

    const hasActive = ref(false)
    const commentsList = ref(EMPTY_COMMENTS)
    let onDisplayCallbacks = []
    let onHideCallbacks = []

    /**
     * Get gallery listing
     * @param filter
     * @param cursor
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getListing(query = {}, cursor = '') {
        if (!query.filter) {
            query.filter = {}
        }
        let queryFilter = ''
        if (cursor != null) {
            query.cursor = cursor
        }
        queryFilter = QueryParamBuilder.encode(query)
        let apiUrl = '/galleries'
        return ApiService.getAll(apiUrl + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get one media
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getOne(galleryId) {
        return ApiService.get('galleries/' + galleryId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(galleryId) {
        return ApiService.get('series', galleryId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create a gallery
     * @param data
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function createItem(data) {
        return ApiService.postFormData('/galleries', data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateItem(params) {
        params._method = 'PUT'
        return ApiService.postFormData('galleries/' + params.id, params)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a gallery
     * @param galleryId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function deleteItem(galleryId) {
        return ApiService.delete('galleries/' + galleryId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like/Unlike a media
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function like(galleryId) {
        return ApiService.post('galleries/' + galleryId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    function onDisplay(callback) {
        onDisplayCallbacks.push(callback)
    }

    function onHide(callback) {
        onHideCallbacks.push(callback)
    }

    /**
     * Show comments of media
     */
    function showComments(displayed) {
        hasActive.value = true
        commentsList.value = {
            galleryId: displayed.galleryId,
            elem: displayed.elem,
            comments: displayed.comments
        }
        onDisplayCallbacks.forEach((callback) => callback())
    }

    /**
     * Hide comments of media
     */
    function hideComments() {
        hasActive.value = false
        commentsList.value = EMPTY_COMMENTS
        onHideCallbacks.forEach((callback) => callback())
    }

    return {
        hasActive,
        commentsList,
        getListing,
        getOne,
        getById,
        createItem,
        updateItem,
        deleteItem,
        like,
        onDisplay,
        onHide,
        showComments,
        hideComments,
    }
})