<script setup>
import { computed } from 'vue'
import dayjs from 'dayjs/esm/index.js'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const year = computed(() => dayjs().format('YYYY'))
</script>

<template>
    <footer class="mt-5 py-4">
        <div class="text-center small">
            {{ year }} © IKONSTAR. {{ t('globals.allRightsReserved') }}
        </div>
    </footer>
</template>
