import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import {useUserStore} from "@/stores/user";

export const useKolsStore = defineStore('kols', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all sites
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function sites() {
        return ApiService.get('sites')
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all Kols / specific one
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(page = '') {
        let queryFilter = ''

        if (page && page > 0) {
            queryFilter = '?page=' + page
        }

        return ApiService.get('creators' + queryFilter)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function listWithQuery(id = '', query = {}) {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        // Get data
        return ApiService.get('creators' + queryFilter, id)
            .then((result) => {
                if (result.data.meta?.ulid) {
                    useUserStore().user.ulid = result.data.meta.ulid
                }
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getById(id) {
        return ApiService.get('creators', id)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getSettingById(creatorId) {
        return ApiService.get('creator/' + creatorId + '/setting')
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getMessengerSettingById(creatorId) {
        return ApiService.get('creator/' + creatorId + '/messenger-setting')
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }
    
    /**
     * Get enum statuses
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getStatuses() {
        return ApiService.get('enum/creator-status')
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a kol
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function add(values) {
        return ApiService.post('creators', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a kol
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(values, id) {
        return ApiService.update('creators', id, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a kol status
     * @returns {Promise<axios.AxiosResponse>}
     * @param creatorId
     * @param status
     */
    async function updateStatus(_creatorId, _status) {
        return ApiService.patch('creator/' + _creatorId + '/update/status', {status: _status})
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a creator setting
     * @param values
     * @param creatorId
     * @param settingId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateSetting(values, creatorId, settingId = null) {
        return ApiService.update('creator/' + creatorId + '/setting', settingId, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createSetting(values, creatorId) {
        return ApiService.post('creator/' + creatorId + '/setting', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function createMessengerSetting(values, creatorId) {
        return ApiService.post('creator/' + creatorId + '/messenger-setting', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updateMessengerSetting(values, creatorId, messengerSettingId) {
        return ApiService.update('creator/' + creatorId + '/messenger-setting', messengerSettingId, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getSubscribers(creatorId, params = {}) {
        return ApiService.getAll('creator/' + creatorId + '/subscribers', {
            params,
        })
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get private video calls
     * @param creatorId
     * @param query
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getPrivateVideoCall(creatorId, query = '') {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.get('creator/' + creatorId + '/private-video-calls' + queryFilter)
            .then((response) => {
                return response.data
            })
            .catch(({ response }) => {
                throw response
            })
    }

    /**
     * Get private video calls
     * @param creatorId
     * @param query
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getPrivateVideoCallWithTimeslots(creatorId, query = '') {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.get('creator/' + creatorId + '/private-video-calls-form-content' + queryFilter)
            .then(({data}) => data.data)
            .catch(({ response }) => {
                throw response.message
            })
    }

    /**
     * Add a private video call
     * @param values
     * @param creatorId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function addPrivateVideoCall(values, creatorId) {
        return ApiService.post('creator/' + creatorId + '/private-video-calls', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a private video call
     * @param values
     * @param creatorId
     * @param privateVideoCallId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updatePrivateVideoCall(values, creatorId, privateVideoCallId) {
        return ApiService.patch('creator/' + creatorId + '/private-video-calls/' + privateVideoCallId, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a private video call
     * @param values
     * @param privateVideoCallId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updatePrivateVideoCallCalendarEvent(values, privateVideoCallId) {
        return ApiService.patch('live/private-video-calls/' + privateVideoCallId + '/update-event', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Confirm a private video call
     * @param privateVideoCallId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function confirmPrivateVideoCall(privateVideoCallId) {
        return ApiService.post('live/private-video-calls/' + privateVideoCallId + '/confirm-event')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a private video call
     * @param creatorId
     * @param privateVideoCallId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function deletePrivateVideoCall(creatorId, privateVideoCallId) {
        return ApiService.delete('creator/' + creatorId + '/private-video-calls/' + privateVideoCallId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a creator setting
     * @param values
     * @param creatorId
     * @param settingId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function resendPassword(values) {
        return ApiService.post('creator/resend-password-email', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get available modules
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function availableModules() {
        return ApiService.get('/enum/creator-modules')
            .then((response) => {
                return response
            })
            .catch(({ response }) => {
                throw response.message
            })
    }

    

    /**
     * Get modules status
     * @param creatorId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getModules(creatorId) {
        return ApiService.get('creator/' + creatorId + '/module')
            .then((response) => {
                return response.data
            })
            .catch(({ response }) => {
                throw response.message
            })
    }

    /**
     * Update modules status
     * @param values
     * @param creatorId
     * @param moduleId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateModules(values, creatorId, moduleId) {
        return ApiService.patch('creator/' + creatorId + '/module/' + moduleId, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        sites,
        list,
        listWithQuery,
        getById,
        getSettingById,
        getMessengerSettingById,
        getStatuses,
        add,
        update,
        updateStatus,
        updateSetting,
        createSetting,
        createMessengerSetting,
        updateMessengerSetting,
        setErrors,
        getSubscribers,

        getPrivateVideoCall,
        getPrivateVideoCallWithTimeslots,
        addPrivateVideoCall,
        updatePrivateVideoCall,

        updatePrivateVideoCallCalendarEvent,
        confirmPrivateVideoCall,
        deletePrivateVideoCall,
        resendPassword,

        availableModules,
        getModules,
        updateModules,
    }
})