import RolesHelper from '@/helpers/Roles'
import { useCreatorStore } from '@/stores/frontend/creator'

export default class PermissionsHelper {
    constructor(user = {}) {
        this.user = user
    }

    can(_ability, model = null) {
        switch (_ability) {
            // GLOBAL
            case 'goto':
                return this.commonAccess(model)
            case 'view-list':
                return this.commonAccess()
            case 'view':
                return this.commonAccess()
            case 'like':
                return !this.isGuest()
            case 'comment':
                return this.commonAccess()
            case 'search':
                return this.commonAccess()
            case 'filter':
                return this.commonAccess()
            case 'add-to-calendar':
                return this.commonAccess()
            case 'play-video':
                return this.commonAccess()
            case 'book-call':
                return this.commonAccess()

            // POST specific
            case 'zoom-post-image':
                return this.commonAccess()
            case 'listen-post-audio':
                return this.commonAccess()
            case 'answer-post-poll':
                return this.commonAccess() || (this.isRegistered() && model.checked_visibility === 'normal')

            // PROGRAM specific
            case 'join-program':
                return this.commonAccess()

            // MESSENGER specific
            case 'add-message-media':
                return this.commonAccess()
            case 'send-message-to-kol':
                return this.commonAccess()

            // GALLERY specific
            case 'add-gallery-photo':
                return this.commonAccess()
            case 'edit-gallery-photo':
                return this.commonAccess()
            case 'delete-gallery-photo':
                return this.commonAccess()

            // CALENDAR specific
            case 'get-events':
                return this.commonAccess()

            // NOTIFICATIONS specific
            case 'enable-notifications':
                return this.commonAccess()
        }
    }

    cannot(_ability, model = null) {
        return !this.can(_ability, model)
    }

    /**
     * METHODS
     */
    commonAccess(_model = null) {

        if (_model !== null) {
            return this.isAdmin()
                || (!this.inPreviewMode() && this.isSubscriber())
                || (this.isCreator() && this.modelIsMine(_model))
                || (this.isAccountManager() && this.modelIsMine(_model))
        }
        return this.isAdmin() || (!this.inPreviewMode() && this.isSubscriber()) || this.isCreator() || this.isAccountManager()
    }

    modelIsMine(_model) {
        return this.user.creator?.id === _model.creator_id
    }

    isSubscriber() {
        return (
            this.user.role === RolesHelper.Roles.User &&
            this.user.has_subscription &&
            this.user.creators_subscribed_to.length >= 1 &&
            this.user.creators_subscribed_to.some((creator) => creator.id === useCreatorStore().creator.id)
        )
    }

    isRegistered() {
        return !this.isGuest()
    }

    isGuest() {
        return this.user.role === RolesHelper.Roles.Guest
    }

    isCreator() {
        return (
            this.user.role === RolesHelper.Roles.Creator &&
            this.user.creator !== undefined &&
            this.user.creator !== null
        ) || this.user.is_creator
    }

    isAccountManager() {
        return (
            this.user.role === RolesHelper.Roles.AccountManager ||
            this.user.is_account_manager
        )
    }

    isAdmin() {
        return this.user.role === RolesHelper.Roles.SuperAdmin
    }
    hasCta(model = null) {
        return model && model.hasCta
    }

    inPreviewMode() {
        return this.user.preview !== undefined && this.user.preview !== false
    }
}