<template>

    <Form
        :validation-schema="validationSchema"
        @submit="submit"
        @invalid-submit="onInvalidSubmit"
    >
        <div class="col-12">
            <span class="is-invalid"></span>
            <ErrorMessage name="userType" class="invalid-feedback"/>
        </div>
        <div class="mb-3">
            <BaseInput
                id="email"
                big
                :white-bg="false"
                name="email"
                type="email"
                :label="`${$t('auth.login.form.email.label')}`"
                :placeholder="`${$t('auth.login.form.email.label')}`"
            />
        </div>
        <div class="mb-3">
            <BaseInput
                id="password"
                big
                :white-bg="false"
                name="password"
                type="password"
                :label="`${$t('auth.login.form.password.label')}`"
                :placeholder="`${$t('auth.login.form.password.label')}`"
            />
        </div>
        <div>
            <button class="col-12 btn btn-tertiary rounded-3 py-2" :disabled="loadingStore.loading">
                <template v-if="loadingStore.loading">
                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                    <span class="visually-hidden" role="status">Loading...</span>
                </template>
                {{ $t('auth.login.form.loginButton.label') }}
            </button>
        </div>
        <div v-if="!noForgot" class="col mt-4 text-center">
            <router-link :to="{ name: 'forgot' }" class="">
                <i class="small text-primary">
                    {{$t('auth.login.guest.lostPassword')}}
                </i>
            </router-link>
        </div>
    </Form>
</template>
<script setup>

import {object, string} from 'yup'
import {ErrorMessage, Form} from 'vee-validate'
import {useI18n} from 'vue-i18n'
import {useUserStore} from '@/stores/user'
import router from '@/router'
import {useLoadingStore} from "@/stores/frontend/loading";
import {useHead} from '@unhead/vue'
import BaseInput from "@/components/Form/BaseInput.vue";
import {useCreatorStore} from "@/stores/frontend/creator";
import {nextTick, onMounted, onUnmounted, ref} from "vue";
import {Modal} from "bootstrap";
import {onBeforeRouteLeave} from "vue-router";

const {t} = useI18n()
const userStore = useUserStore()

useHead({
    title: t('auth.login.guest.title'),
})

const {fromCta, fromModal, modalId} = defineProps({
    isLanding: {
        type: Boolean,
        default: false
    },
    noForgot: {
        type: Boolean,
        default: false
    },
    fromCta: {
        type: Boolean,
        default: false
    },
    fromModal: {
        type: Boolean,
        default: false
    },
    modalId: {
        type: String,
        default: ''
    }
})

/** Validation */
const validationSchema = object({
    email: string().required().email().label(t('auth.login.form.email.label')),
    password: string().required().label(t('auth.login.form.password.label')),
})

/** Methods */
function onInvalidSubmit({values, errors, results}) {
    // console.log(values); // current form values
    // console.log(errors); // a map of field names and their first error message
    // console.log(results); // a detailed map of field names and their validation results
}

const loadingStore = useLoadingStore()

function submit(values) {

    loadingStore.setLoading(true)
    userStore
        .login(values)
        .then(() => {
            if (fromModal) {
                modalLogin.value.hide()
            }
            if (fromCta) {
                return router.go(0)
            }
            else {

                const route = useUserStore().getRedirectRoute()

                if (route !== undefined && route.includes('with-slug')){
                    router.push({
                        name: route,
                        params: {
                            creatorSlug: useCreatorStore().creator.slug
                        }
                    })
                }
                else{
                    router.push(route !== undefined ? {name: route} : {name: 'home'})
                }
            }

            loadingStore.setLoading(false)
        })
        .catch((error) => {
        })
}
const modalLogin = ref(null)

onMounted(() => {
    if (fromModal){
        modalLogin.value = new Modal(`#${modalId}`)
    }
})

onUnmounted(() => {
    // if (fromModal) {
    //     modalLogin.value.hide()
    //     modalLogin.value.dispose()
    // }
})

onBeforeRouteLeave(async (to, from, next) => {

    if (fromModal && modalLogin.value) {
        modalLogin.value.hide()
        await nextTick()
        // modalLogin.value.dispose()
        return next()
    }
    return next()
})
</script>

<style>
.loginPadding {
    margin-top: 3rem !important;
}

.logoLogin {
    max-width: 280px;
}

@media (max-width: 640px) {
    .loginMobile {
        padding-top: 40px;
    }

    .loginPadding {
        margin-top: 0 !important;
        text-align: center;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
</style>
