<template>
    <div
        v-if="isVisible"
        class="d-none d-lg-block environment-container fs-8"
        :class="classes"
    >
        Site: {{ frontEnvironment }}<br />
        API: {{ apiEnvironment }}
    </div>
</template>

<script setup>
import {computed, onBeforeMount, ref} from 'vue'
import ApiService from '@/services/ApiService'

const props = defineProps({
    displayOnlyLocal: {
        type: Boolean,
        default: true,
    },
    asBanner: {
        type: Boolean,
        default: false,
    },
})

const frontEnvironment = ref(import.meta.env.VITE_ENVIRONMENT)
const apiEnvironment = ref(null)

const isVisible = computed(() => {
    return frontEnvironment.value &&
            apiEnvironment.value &&
            (!props.displayOnlyLocal ||
                (frontEnvironment.value !== 'production' &&
                    apiEnvironment.value !== 'production'))
})

const classes = computed(() => {
    return {
        'environment-good':
            frontEnvironment.value === 'production' &&
            apiEnvironment.value === 'production',
        'environment-danger':
            frontEnvironment.value !== 'production' ||
            apiEnvironment.value !== 'production',
        'banner text-center': props.asBanner,
    }
})

onBeforeMount(() => {
    ApiService.init()

    ApiService.get('environment').then(({ data }) => {
        apiEnvironment.value = data.environment
    })
})
</script>

<style scoped>
.environment-container {
    margin-left: 1rem;
    color: var(--white-color);
}
.banner {
    margin-left: 0;
    margin-top: 1rem;
}
.environment-good {
    color: var(--success-color);
}
.environment-danger {
    color: var(--danger-color);
    font-weight: bold;
    /*animation: 1.5s linear infinite condemned_blink_effect;*/
}
@keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    33% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}
</style>