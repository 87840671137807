<template>
    <div class="d-flex flex-column" :class="`align-items-${align}`">
        <Checkbox ref="checkbox" v-model="recaptchaValue" :size="size"></Checkbox>
        <div v-if="recaptchaError" class="invalid-feedback mt-2">{{ recaptchaError }}</div>
    </div>
</template>

<script setup>
import {Checkbox} from 'vue-recaptcha'

import {ref, toRef} from 'vue'
import {useField} from 'vee-validate'

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    rules: {
        type: Object,
        default: () => ({})
    },
    align: {
        type: String,
        default: 'start'
    },
    size: {
        type: String,
        default: 'normal'
    }

})

const name = toRef(props, 'name')
const checkbox = ref(null)

const { value: recaptchaValue, errorMessage: recaptchaError } = useField(name, props.rules, {
    initialValue: props.modelValue,
    valueProp: props.modelValue,
    syncVModel: true,
})

defineExpose({
    checkbox
})

</script>