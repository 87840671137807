import i18n from '@/translation'
import RolesHelper from '@/helpers/Roles'

const { t } = i18n.global

const AdminMenuConfig = [
    {
        heading: t('admin.menu.dashboard'),
        routeName: 'admin-dashboard',
        fontIcon: 'fa-solid fa-house me-3',
        roles: [RolesHelper.Roles.SuperAdmin, RolesHelper.Roles.AccountManager],
    },
    {
        heading: t('admin.menu.kols'),
        routeName: 'admin-kols',
        fontIcon: 'fa-solid fa-person-rays me-3',
        roles: [RolesHelper.Roles.SuperAdmin, RolesHelper.Roles.AccountManager],
    },
    {
        heading: t('admin.menu.recipes'),
        routeName: 'admin-recipes',
        fontIcon: 'fa-solid fa-table-list me-3',
        roles: [RolesHelper.Roles.SuperAdmin],
    },
    {
        heading: t('admin.menu.members'),
        routeName: 'admin-members',
        fontIcon: 'fa-solid fa-users me-3',
        roles: [RolesHelper.Roles.SuperAdmin],
    },
    // {
    //   heading: t('admin.menu.settings'),
    //   routeName: "admin-settings",
    //   fontIcon: "fa-solid fa-gears me-3",
    // },
    {
        heading: t('admin.menu.earlyAccesses'),
        routeName: 'admin-user-early-accesses',
        fontIcon: 'fa-solid fa-house me-3',
        roles: [RolesHelper.Roles.SuperAdmin],
    },
]

export default AdminMenuConfig