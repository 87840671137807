<template>
    <div v-if="item" class="card rounded-4 h-100">
        <router-link
            :to="routerName"
            class="masterclass-card text-decoration-none position-relative"
        >
            <img
                v-if="!item.thumb"
                class="card-img-top masterclass-card-image rounded-bottom-0"
                src="@/assets/images/masterclass_placeholder.jpg"
                role="presentation"
            />
            <img
                v-else
                :src="item.thumb"
                class="card-img-top masterclass-card-image rounded-bottom-0"
                role="presentation"
            />
            <div class="d-flex flex-wrap justify-content-between align-items-center gap-3 fs-8 position-absolute text-white w-100 px-3 py-2" style="top:150px">
                <div class="d-flex align-items-center gap-2 bg-dark rounded-pill py-1 px-2">
                    {{ $filters.formattedPrice(item.price, currentLocale, item.currency?.code ?? 'EUR') }}
                </div>

                <div class="d-flex align-items-center gap-2 bg-dark rounded-pill py-1 px-2">
                    <i class="fa-regular fa-clock"></i>
                    <span>
                        {{ $filters.formatDuration(item.duration_in_minutes *60) }}
                    </span>
                </div>

                <div class="gap-2 bg-dark rounded-pill py-1 px-2">
                    <div class="d-flex justify-content-center align-items-center">
                        <i class="fa-regular fa-calendar me-1"></i>
                        <time v-if="!isUser">
                            {{ $filters.formatDate(item.starts_at, 'DD/MM/YY HH:mm') }}
                        </time>
                        <time v-else>
                            {{ $filters.formatDate(item.user_starts_at, 'DD/MM/YY HH:mm') }}
                        </time>
                    </div>
                    <div v-if="!isUser" class="text-center">{{ item.timezone }}</div>
                </div>                        
            </div>

            <!-- MENU-->
            <div v-if="!isUser" class="position-absolute end-0 top-0">
                <DropdownMenuButton button-color="text-white" has-border>
                    <li>
                        <a href="javascript:void(0);"
                        class="dropdown-item py-2 text-info text-decoration-none"
                        @click="goPreviewMode('preview-user-live-event-view', {uuid: item.uuid})"
                        >
                            <i class="fa-regular fa-eye pe-2"></i>
                            {{ $t('globals.menu.sidebar.preview') }}
<!--                            {{ $t('creator.post.delete') }}-->
                        </a>
                    </li>
                    <li>
                        <router-link
                            class="dropdown-item py-2 text-decoration-none"
                            :to="{
                                name: 'kol-admin-edit-live-event',
                                params: {
                                    id: item.uuid,
                                },
                            }"
                        >
                            <i class="fa-light fa-pen-to-square pe-2"></i>
                            {{ $t('creator.post.edit') }}
                        </router-link>
                    </li>
                    <li v-if="item.nb_paid === 0">
                        <a href="javascript:void(0);" class="dropdown-item py-2 text-danger text-decoration-none" @click="deleteLiveEvent">
                            <i class="fa-regular fa-trash pe-2"></i>
                            {{ $t('creator.post.delete') }}
                        </a>
                    </li>
                </DropdownMenuButton>
            </div>

            <CreatorAvatarCardHeader
                v-if="isGlobal"
                :slug="item.creator.slug"
                :username="item.creator.username"
                :avatar="item.creator.avatar"
            />

            <div class="card-body d-flex flex-column align-items-start">
                <h6 class="m-0 p-0 card-title text-black-dark-mode text-concat-max-2">
                    {{ TranslationsHelper.getLocalisedContent(item.title_trans, currentLocale) }}
                </h6>

                <div v-if="!isUser" class="text-gray">
                    {{item.nb_paid}}
                    {{$t('liveEvents.sold', item.nb_paid)}}
                </div>

            </div>
        </router-link>

        <div class="card-footer bg-white rounded-bottom-5 h-100 d-flex align-items-end">
            <div class="d-flex flex-row justify-content-between align-items-end w-100">

                <div v-if="!isUser">
                    {{$t(`liveEvents.status.${item.status}`)}}
                </div>

                <router-link
                    v-if="isUser"
                    class="d-flex align-items-center text-decoration-none text-black-dark-mode cursor-pointer"
                    :to="routerName"
                    style="font-size: 14px; color: #737373"
                >
                    <i class="fa-light fa-eye me-2"></i>
                    {{ $t('liveEvents.user.actions.view') }}
                </router-link>
<!--                <LikeItem-->
<!--                    :key="`liker-${item.id}`"-->
<!--                    :liked="item.like"-->
<!--                    :show-counter="item.like_count >= 100 || !isUser"-->
<!--                    :counter="item.like_count"-->
<!--                    :loading="false"-->
<!--                    @clicked="$emit('liked')"-->
<!--                />-->
                <!--                <span-->
                <!--                    class="d-block text-decoration-none text-black-dark-mode me-2"-->
                <!--                    style="cursor: pointer"-->
                <!--                    @click="$emit('liked')"-->
                <!--                >-->
                <!--                    <span class="text-capitalize" :class="{ 'text-info': item.like }">-->
                <!--                        <i class="fa-solid fa-heart"></i> {{ item.like_count }} {{ $t('masterclass.global.likes') }}-->
                <!--                    </span>-->
                <!--                </span>-->

                <share-social :key="item.id" :item="{type: 'live-event', data: item}" />

<!--                <span v-if="!isUser" class="text-danger text-decoration-none ms-2 cursor-pointer fs-7" >-->
<!--                    <i class="fa-regular fa-trash"></i> {{ $t('masterclass.global.delete') }}-->
<!--                </span>-->
            </div>
        </div>
    </div>
    <!--            -->
    <!--            <div class="masterclass-card-content">-->
    <!--                <h2 class="masterclass-title">{{ props.title }}</h2>-->
    <!--                <p class="masterclass-card-text">-->
    <!--                    {{ props.episodeCount }}-->
    <!--                    {{ $t('myWorkouts.card.countSuffix') }}-->
    <!--                </p>-->
    <!--                <p class="masterclass-description">{{ props.description }}</p>-->
    <!--            </div>-->
</template>

<script setup>
import ShareSocial from '@/components/ShareSocial.vue'
import LikeItem from "@/components/LikeItem.vue";
import DropdownMenuButton from "@/components/Buttons/DropdownMenuButton.vue";
import {useLiveEvents} from "@/stores/KolAdmin/LiveEvents/liveEventsStore";
import NotificationService from "@/services/NotificationService";
import {useI18n} from "vue-i18n";
import {useLoadingStore} from "@/stores/frontend/loading";
import {usePreviewMode} from "@/plugins/Composables/usePreviewMode";
import TranslationsHelper from "@/helpers/TranslationsHelper";
import i18n from '@/translation'
import {ref, computed} from "vue";
import CreatorAvatarCardHeader from "@/components/Cards/CreatorAvatarCardHeader.vue";

const currentLocale = ref(i18n.global.locale.value)

const emit = defineEmits(['liked', 'deleted'])

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    isUser: {
        type: Boolean,
        default: false,
    },
    isGlobal: {
        type: Boolean,
        default: false,
    },
})

const {t} = useI18n()

const {isPreviewMode, goPreviewMode} = usePreviewMode()

const routerName = computed(() => {
    if (!props.isUser) {
        return {
            name: 'kol-admin-edit-live-event',
            params: {
                id: props.item.uuid
            }
        }
    }
    else {
        let params = {
            uuid: props.item.uuid,
        }

        if (props.isGlobal && !isPreviewMode()) {
            params.creatorSlug = props.item.creator.slug
        }
        return {
            name: `${isPreviewMode() ? 'preview-' : ''}user-live-event-view`,
            params
        }
    }
})

const deleteLiveEvent = () => {

    NotificationService.notifyUserConfirmation(
        t('liveEvents.actions.delete'),
        t('globals.yes'),
        t('globals.no'),
    )
    .then(() => {
        useLoadingStore().setLoading(true, true)
        return useLiveEvents().destroy(props.item.id)
    })
    .then(() => {
        emit('deleted')
    })
    .catch(() => {

    })
}
</script>

<style scoped>
.masterclass-card-image {
    width: 100%;
    object-fit: cover;
    border-radius: 0.625rem;
    height: 12rem;
}
</style>