import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'

const useEnumStore = defineStore('enums', () => {
    async function getEnum(category) {
        return ApiService.get(`enum/${category}`)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                return error.message
            })
    }

    async function getSeriesWorkoutLevelEnums() {
        return getEnum('series-workout-level')
            .then((data) => {
                return data
            })
            .catch((errMessage) => {
                return errMessage
            })
    }

    async function getSeriesWorkoutBodyPartEnums() {
        return getEnum('series-workout-body-part')
            .then((data) => {
                return data
            })
            .catch((errMessage) => {
                return errMessage
            })
    }

    async function getSeriesWorkoutGoalEnums() {
        return getEnum('series-workout-goal')
            .then((data) => {
                return data
            })
            .catch((errMessage) => {
                return errMessage
            })
    }

    // recipe-category
    async function getRecipeCategoryEnums() {
        return getEnum('recipe-category')
            .then((data) => {
                return data
            })
            .catch((errMessage) => {
                return errMessage
            })
    }

    // series-workout-category
    async function getSeriesWorkoutCategoryEnums() {
        return getEnum('series-workout-category')
            .then((data) => {
                return data
            })
            .catch((errMessage) => {
                return errMessage
            })
    }

    return {
        getEnum,

        getSeriesWorkoutLevelEnums,
        getSeriesWorkoutBodyPartEnums,
        getSeriesWorkoutGoalEnums,
        getRecipeCategoryEnums,
        getSeriesWorkoutCategoryEnums,
    }
})

export default useEnumStore
