<template>
    <div v-pause-on-hide="{active: pauseOnHide, handler: handlePause}" >
        <div v-show="!isLoading" class="align-items-center" :class="{'flex-column w-100': stack === 'column', 'd-flex': !isLoading}">
            <a
                href="#"
                class="btn-play-pause d-block px-2"
                :class="{'mb-3': stack === 'column'}"
                @click.prevent="handlePlayPause"
            >
                <i v-if="isPlaying" class="fa-regular fa-pause-circle fa-2xl"></i>
                <i v-else class="fa-regular fa-play-circle fa-2xl"></i>
            </a>
            <div
                ref="containerRef"
                class="audio-player flex-grow-1"
                :class="{'w-100': stack === 'column', 'd-none': hiddenTrack}"
                @pointermove="handlePointerMove"
            >
                <div class="time">{{ time }}</div>
                <div class="duration">{{ duration }}</div>
                <div ref="hoverRef" class="hover"></div>
            </div>
        </div>
        <div v-show="isLoading" class="w-100 text-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

    </div>
</template>

<script>
// Create the canvas for defining the gradients
// https://wavesurfer-js.org/examples/#soundcloud.js

const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')

// Define the waveform gradient
const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35)
gradient.addColorStop(0, '#656666') // Top color
gradient.addColorStop((canvas.height * 0.7) / canvas.height, '#656666') // Top color
gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff') // White line
gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff') // White line
gradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, '#B1B1B1') // Bottom color
gradient.addColorStop(1, '#B1B1B1') // Bottom color

// Define the progress gradient
const progressGradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.35)
progressGradient.addColorStop(0, '#e8c586') // Top color
progressGradient.addColorStop((canvas.height * 0.7) / canvas.height, '#e8c586') // Top color
progressGradient.addColorStop(
    (canvas.height * 0.7 + 1) / canvas.height,
    '#ffffff'
) // White line
progressGradient.addColorStop(
    (canvas.height * 0.7 + 2) / canvas.height,
    '#ffffff'
) // White line
progressGradient.addColorStop(
    (canvas.height * 0.7 + 3) / canvas.height,
    '#F6B094'
) // Bottom color
progressGradient.addColorStop(1, '#F6B094') // Bottom color
</script>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { useRegistrationCtaModalStore } from "@/stores/registrationCtaModal";
import { useUserStore } from '@/stores/user'
import {useGlobalAudioPlayerStore} from "@/stores/globalAudioPlayer";

/** Stores **/
const registerCtaStore = useRegistrationCtaModalStore()
const userStore = useUserStore()

const playerStore = useGlobalAudioPlayerStore()

/** Props **/
const props = defineProps({
    src: {
        type: String,
        required: true,
    },
    stack: {
        type: String,
        default: 'row',
    },
    options: {
        type: Object,
        default: () => ({}),
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
    autoplay: {
        type: Boolean,
        default: false,
    },
    hiddenTrack: {
        type: Boolean,
        default: false,
    }
})

/** Init paremeters **/
const containerRef = ref()
const hoverRef = ref()
const wavesurfer = ref()
const time = ref('0:00')
const duration = ref('0:00')
const subscriptions = ref([])
const isPlaying = ref(false)

/** Methods **/
const handlePointerMove = (e) => {
    hoverRef.value.style.width = `${e.offsetX}px`
}

const handlePlayPause = () => {
    // if (userStore.cannot('listen-post-audio')){
    //     console.log('CTA')
    //     registerCtaStore.showModal()
    //     // TODO: CTA
    //     return false;
    // }
    wavesurfer.value?.playPause()
}
const handlePause = () => {
    wavesurfer.value?.pause()
}

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const secondsRemainder = Math.round(seconds) % 60
    const paddedSeconds = `0${secondsRemainder}`.slice(-2)
    return `${minutes}:${paddedSeconds}`
}

const isLoading = ref(true)
const initPlayer = () => {
    wavesurfer.value = WaveSurfer.create({
        ...props.options,
        waveColor: gradient,
        progressColor: progressGradient,
        barWidth: 2,
        url: props.src,
        container: containerRef.value,
        autoplay: false
    })


    // Global array to hold all wavesurfer instances
    window.audioPlayers = window.audioPlayers || [];
    window.videoPlayers = window.videoPlayers || [];
    window.audioPlayers.push(wavesurfer.value);

    // register events
    subscriptions.value.push(
        wavesurfer.value.on('interaction', () => {
            wavesurfer.value?.play()
        }),
        wavesurfer.value.on('play', () => {
            window.videoPlayers.forEach(p => {
                p.pause();
            });
            window.audioPlayers.forEach(p => {
                if (p !== wavesurfer.value && p.isPlaying()) p.pause();
            });
            if (playerStore.media.status === 'playing') {
                playerStore.pause()
            }
            isPlaying.value = true
        }),
        wavesurfer.value.on('pause', () => {
            isPlaying.value = false
        }),
        wavesurfer.value.on('decode',(_duration) => {
            duration.value = formatTime(_duration)
        }),
        wavesurfer.value.on('init',() => {
            isLoading.value = true
        }),
        wavesurfer.value.on('loading',() => {
            isLoading.value = true
        }),
        wavesurfer.value.on('redraw',() => {
            isLoading.value = true
        }),
        wavesurfer.value.on('redrawcomplete',() => {
            isLoading.value = false
            if (props.autoplay) {
                wavesurfer.value?.play()
            }
        }),
        wavesurfer.value.on(
            'timeupdate',
            (currentTime) => (time.value = formatTime(currentTime))
        )
    )
}

const destroyPlayer = () => {
    for (const unsubscribe of subscriptions.value) {
        unsubscribe()
    }

    wavesurfer.value?.destroy()
}

watch(
    () => props.src,
    (_new, _old) => {

        if (_new === '' && _old !== ''){
            destroyPlayer()
        }
        else{
            isLoading.value = true
            wavesurfer.value?.load(props.src)
        }
    }
)

onMounted(() => {
    initPlayer()
})

onBeforeUnmount(() => {
    destroyPlayer()
})
</script>

<style scoped lang="scss">
.btn-play-pause {
    color: inherit;
}

.audio-player {
    cursor: pointer;
    position: relative;
    z-index: 0;

    .hover {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        pointer-events: none;
        height: 100%;
        width: 0;
        mix-blend-mode: overlay;
        background: rgba(255, 255, 255, 0.5);
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    .time,
    .duration {
        position: absolute;
        z-index: 11;
        top: 50%;
        margin-top: -1px;
        transform: translateY(-50%);
        font-size: 11px;
        background: rgba(0, 0, 0, 0.75);
        padding: 2px;
        color: #ddd;
    }

    .time {
        left: 0;
    }

    .duration {
        right: 0;
    }
}

.audio-player:hover .hover {
    opacity: 1;
}
</style>