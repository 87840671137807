<template>
    <div class="card position-relative">
        <span v-if="!readonly" v-tooltip="{title: (item.id) ? $t('globals.tooltips.deletePreviewLink') : $t('globals.tooltips.disablePreviewLink')}" class="position-absolute top-0 end-0 mt-n2 me-n2 cursor-pointer" @click="$emit('clicked', item.id)">
            <i class="fa-solid fa-circle-xmark fs-5 text-danger"></i>
        </span>
        
        <a :href="props.item.url" target="_blank" class="text-decoration-none">
            <img
                v-if="!item.image"
                src="@/assets/images/masterclass_placeholder.jpg"
                class="card-img-top cursor-pointer"
                @click.prevent="redirect"
            />
            <img
                v-else
                :src="item.image"
                class="card-img-top cursor-pointer"
                @click.prevent="redirect"
            />
        </a>
        <div class="card-body ms-3 p-0 mx-2 cursor-pointer" @click.prevent="redirect">
            <h5 class="card-title" :class="$breakpoints.mdAndDown.value ? 'fs-7' : ''">
                {{
                    (item.title.length > 30 && $breakpoints.mdAndDown.value)
                        ? $filters.truncateText(item.title, 30)
                        : (item.title.length > 60 && !$breakpoints.mdAndDown.value)
                            ? $filters.truncateText(item.title, 60)
                            : item.title
                }}
            </h5>
            <p v-if="!$breakpoints.mdAndDown.value" class="card-text mb-1 lh-1">
                {{ item.description.length > 80 ? $filters.truncateText(item.description, 80) : item.description }}
            </p>
            <small class="text-gray">
                <i class="fa-solid fa-link"></i> {{ item.site_name}}
            </small>
        </div>
    </div>
</template>

<script setup>
/** Props **/
const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    readonly: {
        type: Boolean,
        required: true,
    },
})

/** Emit **/
defineEmits(['clicked'])

/** Methods **/
function redirect() {
    if (props.item.url) {
        window.open(props.item.url, '_blank');
    }
}
</script>

<style scoped>
.card {
  flex-direction: row;
  align-items: center;
}
.card-title {
  font-weight: bold;
}
.card-title-mobile {
  font-weight: bold;
}
.card img {
  width: auto;
  max-height: 80px;
  border-top-right-radius: 0;
  border-bottom-left-radius: calc(0.25rem - 1px);
}
</style>