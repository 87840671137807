import {ref} from 'vue'
import {defineStore} from 'pinia'
import ApiService from '@/services/ApiService'

export const useRegistrationStore = defineStore('registration', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Register a creator
     * @returns {Promise<axios.AxiosResponse>}
     * @param _email
     */
    // async function registerCreator(
    //     {email, language_id, recaptcha} = {email: '', language_id: '', recaptcha: ''}
    // ) {
    async function registerCreator(_values) {
        return ApiService.post('register/creator', _values)
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    /**
     * Get a code to check creator email
     * @param uuid
     * @param code
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function codeCreator(_uuid, _code) {
        return ApiService.post('register/creator/' + _uuid + '/code', {code: _code})
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }

    /**
     * Set a creator password
     * @param uuid
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function passwordCreator(_uuid, _values) {
        return ApiService.post('register/creator/' + _uuid + '/password', _values)
        .then(({data}) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }


    return {
        errors,
        setErrors,
        registerCreator,
        codeCreator,
        passwordCreator,
    }
})