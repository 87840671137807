import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import {QueryParamBuilder} from "@/helpers/QueryParamBuilder";

export const useCalendarStore = defineStore('calendar', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get one event
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(creatorId = '', eventId) {
        let query = (creatorId > 0) ? 'creator/' + creatorId + '/calendar-events/' + eventId : 'calendar-events/' + eventId
        return ApiService.get(query)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get all events
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(creatorId = '', query = '') {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }
        // Prepare query
        let route = (creatorId > 0) ? 'creator/' + creatorId + '/calendar-events' : 'calendar-events'
        return ApiService.get(route + queryFilter)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add an event
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function add(creatorId = '', values) {
        // Prepare query
        let query = (creatorId > 0) ? 'creator/' + creatorId + '/calendar-events' : 'calendar-events'
        return ApiService.post(query, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an event
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function change(creatorId = '', eventId, values) {
        // Prepare query
        let query = (creatorId > 0) ? 'creator/' + creatorId + '/calendar-events/' + eventId : 'calendar-events/' + eventId
        // console.log(query)
        return ApiService.patch(query, values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete an event
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(creatorId = '', eventId) {
        // Prepare query
        let query = (creatorId > 0) ? 'creator/' + creatorId + '/calendar-events/' + eventId : 'calendar-events/' + eventId
        return ApiService.delete(query)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get creator availability
     * @param creatorId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getAvailability(creatorId) {
        return ApiService.get("creator/" + creatorId + "/calendar-availabilities")
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update creator availability
     * @param creatorId
     * @param availabilityId
     * @param availability
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateAvailability(creatorId, availabilityId, availability) {
        return ApiService.patch("creator/" + creatorId + "/calendar-availabilities/" + availabilityId, availability)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update creator availability
     * @param creatorId
     * @param availabilityId
     * @param availability
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateLiveStreaming(liveStreamingId, options) {
        return ApiService.patch("live/streaming/" + liveStreamingId, options)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update creator availability
     * @param creatorId
     * @param availabilityId
     * @param availability
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateLiveStreamingIvs(creatorId, liveStreamingId, options) {
        return ApiService.patch(`/creator/${creatorId}/live-streaming/${liveStreamingId}`, options)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        getById,
        list,
        add,
        change,
        softDelete,
        getAvailability,
        updateAvailability,
        updateLiveStreaming,
        updateLiveStreamingIvs,
        setErrors,
    }
})
