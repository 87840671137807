import { openDB } from 'idb';

class IdbService {

    static notifications
    static countNotRead

    /**
     * @description Init indexedDb
     * @returns {Promise}
     */
    static async init() {
        return openDB(
            'ikonstar',
            1,
            {
                upgrade(db) {
                    // Create a store of objects
                    const store = db.createObjectStore('notification-center', {
                        // The 'id' property of the object will be the key.
                        keyPath: 'id',
                        // If it isn't explicitly set, create a value by auto incrementing.
                        autoIncrement: true,
                    });
                    // Create an index on the 'date' property of the objects.
                    store.createIndex('date', 'date');
                },
            }
        );
    }

    /**
     * @description Insert a notification
     * @param data | Object | data of the notification
     * @returns {Promise}
     */
    static async add(data) {
        return this.init()
        .then(async (dbPromise) => {
            return (await dbPromise).add('notification-center', data)
            .then((response) => {
                // console.log(`Notification (id, ${response}) was inserted successfully!`)
            })
            .catch((error) => {
                // console.log("Notification was not inserted!")
                // console.log(error)
            })
        })
    }

    /**
     * @description Get all notifications
     * @returns {Promise}
     * @param _creator
     */
    static async getAll(_creator) {
        return this.init()
        .then(async (dbPromise) => {
            return (await dbPromise)
            .getAllFromIndex('notification-center', 'date')
            .then((response) => {
                // Creator filtering
                let filtered = []
                Object.entries(response).map((item) => {
                    if (item[1].slug === _creator.slug) {
                        filtered.push(item[1])
                    }
                })
                this.notifications = {...filtered.reverse()}
                this.countNotRead = 0
                // Unread messages counter
                Object.entries(this.notifications).map((item) => {
                    if (!item[1].read) {
                        this.countNotRead++
                    }
                })
                return {data: this.notifications, counter: this.countNotRead}
            })
            .catch((error) => {
                // console.log("Notification was not inserted!")
                // console.log(error)
            })
        })
        .catch((error) => {
            return {data: {}, counter: 0}
            // console.log("Notification was not inserted!")
            // console.log(error)
        })
    }

    /**
     * @description Update a notification
     * @param data | Object | data of the notification
     * @returns {Promise}
     */
    static async update(data) {
        return this.init()
        .then(async (dbPromise) => {
            return (await dbPromise).put('notification-center', data)
            .then(() => {
                // console.log(`Notification was marked as read successfully!`)
            })
            .catch((error) => {
                // console.log("Notification was not updated!")
                // console.log(error)
            })
        })
    }

    /**
     * @description Delete a notification
     * @param elemId | Int | id of the notification
     * @returns {Promise}
     */
    static async del(elemId) {
        return this.init()
        .then(async (dbPromise) => {
            return (await dbPromise).delete('notification-center', elemId)
            .then(() => {
                // console.log(`Notification (id, ${elemId}) was deleted successfully!`)
            })
            .catch((error) => {
                // console.log("Notification was not deleted!")
                // console.log(error)
            })
        })
    }

    /**
     * @description Mark a notification as read
     * @param elemId | Int | id of the notification
     * @returns {Promise}
     */
    static async markAsRead(elemId) {
        // Get notification
        return this.init()
        .then(async (dbPromise) => {
            return (await dbPromise).get('notification-center', elemId)
            .then(async (_notification) => {
                // Change status
                let data = {
                    ..._notification,
                    read: true,
                }
                // Update status
                await this.update(data)
            })
        })
    }
}

export default IdbService