import i18n from "@/translation";

export default class TranslationsHelper {

    /**
     *
     * @param {object} content
     * @param {string} requestedLocale
     * @param {boolean} debug
     * @returns {string}
     */
    static getLocalisedContent(content, requestedLocale, debug = false) {

        if (debug) {
            console.log("requested locale:", requestedLocale)
            console.log("content: ", content[requestedLocale])
            console.log("fallback locale: ", i18n.global.fallbackLocale.value)
        }
        // Check if requested locale has content
        if (content[requestedLocale] === undefined) {
            // Check if fallback locale has content
            // When fallback !== requested
            if (
                requestedLocale !== i18n.global.fallbackLocale.value
                && content[i18n.global.fallbackLocale.value] !== undefined
            ) {
                if (debug) {
                    console.log("content fallback: ", content[i18n.global.fallbackLocale.value])
                }
                return content[i18n.global.fallbackLocale.value]
            }

            // Nothing worked, return first available value
            if (debug) {
                console.log("first content available: ", Object.values(content)[0])
            }
            return Object.values(content)[0]
        }

        // Requested locale exists
        return content[requestedLocale]
    }
}