import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import {QueryParamBuilder} from "@/helpers/QueryParamBuilder";

export const useWorkoutEpisodeStore = defineStore('workoutEpisode', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all series episodes by workout type
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(seriesId, query = {}) {
        // Query param builder
        let queryFilter = ''
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll('series/' + seriesId + '/episode' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function post(params) {
        return ApiService.post(
            'series/' + params.series_id + '/episode',
            params
        )
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Create an item with form upload
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function create(params) {
        return ApiService.postFormData(
            'series/' + params.series_id + '/episode',
            params
        )
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(params) {
        params._method = 'PUT'
        return ApiService.postFormData(
            'series/' + params.series_id + '/episode/' + params.id,
            params
        )
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get an item
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(seriesId, episodeId) {
        return ApiService.get('series/' + seriesId + '/episode/' + episodeId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete an episode
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(workoutId, episodeId) {
        return ApiService.delete('series/' + workoutId + '/episode/' + episodeId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Reorder episodes
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function reorderEpisodes(seriesId, ids) {
        return ApiService.post(
            'series/' + seriesId + '/reorder-episodes',
            {ids}
        )
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }


    return {
        errors,
        list,
        create,
        post,
        update,
        getById,
        softDelete,
        setErrors,
        reorderEpisodes,
    }
})