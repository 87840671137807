<template>
    <div
        v-pause-on-hide="{active: pauseOnHide, handler: handlePause}"
        class="player-post" style="width: 100%; height: 100%"
    >
        <video
            ref="playerRef"
            class="video-js vjs-defaultskin"
            :class="fullStyle ? 'vjs-fill' : ''"
            style="position: relative; max-height: 100%"
        ></video>
    </div>
</template>

<script setup>
import Plyr from 'plyr'
import Hls from 'hls.js'

import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps({
    options: {
        type: Object,
        default: () => ({}),
    },
    fullStyle: {
        type: Boolean,
        default: true,
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
})

const player = ref(null)
const playerRef = ref(null)
const emit = defineEmits(['videoEnded', 'videoPlaying', 'videoCanPlay'])

const plyrOptions = computed(() => {
    return {
        ...{
            controls: [
                'play-large',
                'play',
                'progress',
                'current-time',
                'mute',
                'volume',
                'captions',
                'settings',
                'pip',
                'airplay',
                'fullscreen',
            ],
            type: 'video',
        },
        ...props.options,
    }
})

const updateQuality = (newQuality) => {
    if (newQuality === 0) {
        window.hls.currentLevel = -1 //Enable AUTO quality if option.value = 0
    } else {
        window.hls.levels.forEach((level, levelIndex) => {
            if (level.height === newQuality) {
                window.hls.currentLevel = levelIndex
            }
        })
    }
}

const initVideoPlayer = () => {
    if (props.options.sources[0].type === 'application/x-mpegURL' && Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(props.options.sources[0].src)
        // From the m3u8 playlist, hls parses the manifest and returns
        // all available video qualities. This is important, in this approach,
        // we will have one source on the Plyr player.
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            // Transform available levels into an array of integers (height values).
            const availableQualities = hls.levels.map((l) => l.height)
            availableQualities.unshift(0) //prepend 0 to quality array

            // Add new qualities to option
            plyrOptions.value.quality = {
                default: 0, //Default - AUTO
                options: availableQualities,
                forced: true,
                onChange: (e) => updateQuality(e),
            }
            // Add Auto Label
            plyrOptions.value.i18n = {
                qualityLabel: {
                    0: 'Auto',
                },
            }

            hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
                var span = document.querySelector(".plyr__menu__container [data-plyr='quality'][value='0'] span")
                if (hls.autoLevelEnabled) {
                    span.innerHTML = `AUTO (${hls.levels[data.level].height}p)`
                } else {
                    span.innerHTML = `AUTO`
                }
            })

            // Initialize new Plyr player with quality options
            player.value = new Plyr(playerRef.value, plyrOptions.value)

            registerVideoEvents()
        })
        hls.attachMedia(playerRef.value)
        window.hls = hls
    } else {
        player.value = new Plyr(playerRef.value, plyrOptions)
        player.value.source = {
            type: plyrOptions.value.type,
            sources: props.options.sources,
        }
        registerVideoEvents()
    }
}

const registerVideoEvents = () => {
    // Global array to hold all Plyr instances
    window.videoPlayers = window.videoPlayers || [];
    window.audioPlayers = window.audioPlayers || [];
    window.videoPlayers.push(player.value);
    player.value.on('play', function() {
        window.videoPlayers.forEach(p => {
            if (p !== player.value ) p.pause();
        });
        window.audioPlayers.forEach(p => {
            if (p.isPlaying()) p.pause();
        });
    });

    player.value.on('playing', () => {
        emit('videoPlaying')
    })
    player.value.on('ended', () => {
        emit('videoEnded')
    })
    player.value.on('canplay', () => {
        emit('videoCanPlay')
    })
}

const replay = () => {
    player.value.currentTime = 0
    player.value.play()
}
const start = () => {
    player.value.play()
}

const handlePause = () => {
    player.value?.pause()
}


onMounted(() => {
    initVideoPlayer()
})

onBeforeUnmount(() => {
    if (player.value) {
        player.value.destroy()
    }
})

defineExpose({
    replay, start
})
</script>

<style scoped>
.plyr--video .plyr__controls {
    padding: 1.2rem 1rem 1rem !important;
}
</style>

<style src="plyr/dist/plyr.css"></style>