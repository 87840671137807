<template>
    <div class="btn-group dropstart">
        <button
            type="button"
            class="icon-menu-dropdown fs-3"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
        >
            <i
                :class="[menuIconCss]"
                @mouseover="hoverMenu = true"
                @mouseleave="hoverMenu = false"
            ></i>
        </button>
        <ul class="dropdown-menu fs-7">
            <slot></slot>
        </ul>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const hoverMenu = ref(false)

const props = defineProps({
    buttonColor: {
        type: String,
        default: ''
    },
    hasBorder: {
        type: Boolean,
        default: false
    },
})

const menuIconCss = computed(() => {
    return hoverMenu.value
        ? "fa-solid fa-circle-ellipsis text-secondary"
        : `${props.buttonColor !== '' ? 'fa-solid': 'fa-thin'} fa-circle-ellipsis ${props.buttonColor} ${props.hasBorder ? 'bg-black border border-black rounded-pill' : ''}`
})

</script>

<style scoped>

</style>