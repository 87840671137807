<template>
    <div>
        <BaseField
            :id="id"
            :name="name"
            :label="label"
        >
            <BaseInput
                :id="props.id"
                v-model="jsonData"
                :value="jsonData"
                type="hidden"
                :name="props.name"
                :label="props.label"
            />
            <draggable
                tag="ul"
                :list="items"
                class="list-group"
                handle=".handle"
                item-key="id"
            >
                <template #item="{ element, index }">
                    <li class="list-group-item d-inline-flex align-items-center">
                        <button class="handle btn btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-move" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10zM.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708l-2-2zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8z"/>
                            </svg>
                        </button>

                        <div class="form-control mx-4 p-0 border-0 bg-transparent">
                            <input v-model="element.content[props.language]" type="text" class="form-control" :name="`${props.name}.draggable${index}${props.language}`"/>
                            <div v-if="inputHelper !== ''" :id="`${props.name}.draggable${index}${props.language}HelpBlock`" class="form-text mb-3">
                                {{ inputHelper }}{{index+1}} ({{props.language.toUpperCase()}})
                            </div>
                        </div>

                        <button class="btn btn-tertiary" @click.prevent="removeItem(index)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                            </svg>
                        </button>
                    </li>
                </template>
                <template #footer>
                    <li class="d-inline-flex align-items-center">

                        <PrimaryButton :on-click="addItem" :is-big="false" style="font-size: small">
                            + {{ props.buttonText !== '' ? props.buttonText : $t('globals.addMore') }}
                        </PrimaryButton>
                    </li>
                </template>
            </draggable>
        </BaseField>
    </div>
</template>

<script setup>
import BaseField from '@/components/Form/BaseField.vue'
import BaseInput from '@/components/Form/BaseInput.vue'
import draggable from 'vuedraggable'

import i18n from '@/translation'
import { computed, ref, watch } from 'vue'
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    buttonText: {
      type: String,
      default: ''
    },
    inputHelper: {
      type: String,
      default: ''
    },
    errorMessage: {
        type: String,
        default: '',
    },
    list: {
        type: Array,
        required: true,
    },
    language: {
        type: String,
        default: i18n.global.locale.value,
    },
})
const values = ref(props.list)

watch(() => props.list, (items) => {
    values.value = items
});

const items = computed(() => {
    return values.value
})
const jsonData = computed(() => {
    return JSON.stringify(values.value)
})

function addItem() {
    values.value.push({
        id: null,
        content: {
            [props.language]: null
        },
        order: values.value.length + 1
    })
}

function removeItem(index) {
    values.value.splice(index, 1)
}
</script>

<style scoped>
.list-group-item {
    border: none;
    background-color: transparent;
}
</style>
