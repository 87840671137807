import { useUserStore } from "@/stores/user"

const typeToRoute = {
    post: 'user-dashboard-with-slug',
    live: 'user-live',
    messenger: {
        User: 'user-messages',
        Creator: 'kol-admin-messages',
    },
    workout: 'user-workouts',
    masterclass: 'user-masterclass',
    video: {
        User: {
            route: 'user-video',
        },
        Creator: {
            route: 'kol-admin-video',
            param: ['userId','eventId'],
         },
    },
    'stripe-requirement': 'kol-admin-my-feed',
    'account-verification-result': 'kol-admin-my-feed',
    'video-encoding-result': {
        media: {
            route: 'kol-admin-medias-edit',
            param: ['id'],
        },
        workout: {
            route: 'kol-admin-edit-workout-episode',
            param: ['workoutId', 'episodeId'],
        },
        masterclass: {
            route: 'kol-admin-edit-masterclass-episode',
            param: ['masterclassId', 'episodeId'],
        }
    },
}

/**
 * Split url to get path and params
 * @param _url /:slug/notification/:type/:params1/:paramsX... 
 */ 
function getRoute(_url) {
    // Init parameters
    let explode = _url.split('/')
    let _slug = explode[1]
    let _type = explode[3]
    let _params = explode.slice(4)
    let _route = ''

    // Return route
    if (!Object.keys(typeToRoute).includes(_type)) {
        _route = {
            name: (useUserStore().user.role === 'Creator') ? 'kol-admin-my-feed' : 'user-dashboard-with-slug',
            params: { creatorSlug: _slug },
        }
    } else {

        switch (_type) {
            case 'post':
                _route = { 
                    name: typeToRoute[_type], 
                    params: { creatorSlug: _slug } 
                }
                break;
            case 'messenger':
                _route = { 
                    name: typeToRoute[_type][useUserStore().user.role], 
                }
                break;
            case 'video':
                _route = { 
                    name: typeToRoute[_type][useUserStore().user.role].route, 
                    params: {}
                }
                if (typeToRoute[_type][useUserStore().user.role].param) {
                    typeToRoute[_type][useUserStore().user.role].param.forEach((element, index) => {
                        _route.params[element] = _params[index]
                    });
                }

                break;
            case 'video-encoding-result':
                _route = { 
                    name: typeToRoute[_type][_params[0]].route,
                    params: {}
                }
                if (typeToRoute[_type][_params[0]].param) {
                    typeToRoute[_type][_params[0]].param.forEach((element, index) => {
                        _route.params[element] = _params[index+1]
                    });
                }
                break;
        
            default:
                _route = { name: typeToRoute[_type] }
                break;
        }
    }

    return _route
}

export default getRoute