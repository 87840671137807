import { defineStore } from 'pinia'
import {ref} from "vue";

export const useReverbClientStore = defineStore('reverbClient', () => {

    const channel = ref(null)
    const getChannel = () => {
        return channel.value
    }
    const setChannel = (_channel) => {
        // console.log('SET CHANNEL', _channel)
        channel.value = _channel
        // console.log(channel.value)
    }

    const reactions = ref({
        hearts: {
            emoji: '\u2764\ufe0f',
            reactions: []
        },
        fire: {
            emoji: '\ud83d\udd25',
            reactions: []
        },
        rockets: {
            emoji: '\ud83d\ude80',
            reactions: []
        },
        blownMinds: {
            emoji: '\ud83e\udd2f',
            reactions: []
        },
    })

    const randomMargin = (min = -50, max = 50) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    const addReaction = (id, type, margin = null) => {
        margin =  margin ?? randomMargin()
        reactions.value[type].reactions.push({
            id, margin
        });

        setTimeout(() => {
            reactions.value[type].reactions.splice(reactions.value[type].reactions.indexOf(id), 1)
        }, 900);
    }

    const sendClientMessage = (_message, whisperOn = 'message', _channel = null) => {
        // console.log('SEND MESSAGE REVERB', _message, whisperOn, getChannel(), _channel)
        let useChannel = _channel ?? getChannel()
        useChannel !== null && useChannel.whisper(whisperOn, _message)
    }

    return {
        reactions,
        addReaction,
        setChannel,
        getChannel,
        sendClientMessage
    }
})