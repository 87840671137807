import { authMiddleware } from '@/router/middleware/authMiddleware'
import RolesHelper from '@/helpers/Roles'
import { creatorFinderMiddleware } from '@/router/middleware/creatorFinderMiddleware'
import {shouldLogout} from "@/router/middleware/shouldLogout";

const creatorRoutes = [
    {
        path: '/:creatorSlug/',
        // component: () => import('@/components/Layout/UserLayout.vue'),
        beforeEnter: [
            creatorFinderMiddleware,
            // shouldLogout
        ], //roleMiddleware
        meta: {
            authorize: [], //RolesHelper.Roles.User
        },
        children: [
            {
                path: '',
                name: 'creator.landing',
                // beforeRouteEnter: [shouldLogout],
                component: () => import('@/views/Creator/Landing.vue'),
                props: true,
            },
            {
                path: 'payment',
                name: 'payment',
                beforeEnter: [authMiddleware],
                // beforeRouteEnter: [shouldLogout],
                component: () => import('@/views/Creator/PaymentV2.vue'),
                props: true,
            },
            {
                path: 'payment-success',
                name: 'payment-success',
                component: () => import('@/views/Creator/PaymentSuccess.vue'),
                props: true,
            },
        ],
    },
]

export default creatorRoutes