import { useMembersStore } from '@/stores/members'

const checkIfAccountManager = async (to, from, next) => {
    const id = to.params.id

    const member = await useMembersStore().list(id)

    if (member.role !== 'Account Manager') {
        return next({ name: 'admin-members' })
    }

    return next()
}

export default checkIfAccountManager
