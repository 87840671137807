import { authMiddleware } from '@/router/middleware/authMiddleware'
import { roleMiddleware } from '@/router/middleware/roleMiddleware'
import RolesHelper from '@/helpers/Roles'
import AdminMenuConfig from '@/config/AdminMenuConfig'
import { RouterView } from 'vue-router'
import { h } from 'vue'
import checkIfAccountManager from '@/router/middleware/checkIfAccountManager'

const admin = [
    {
        path: '/admin',
        component: () => import('@/components/Layout/AdminLayout.vue'),
        props: {
            menu: AdminMenuConfig,
        },
        beforeEnter: [authMiddleware, roleMiddleware],
        meta: {
            authorize: [
                RolesHelper.Roles.SuperAdmin,
                RolesHelper.Roles.AccountManager,
            ],
        },
        children: [
            {
                path: '',
                redirect: '/admin/dashboard',
            },
            {
                path: 'dashboard',
                name: 'admin-dashboard',
                component: () => import('@/views/Admin/Dashboard/Dashboard.vue'),
            },
            {
                path: 'kols',
                name: 'admin-kols',
                component: () => import('@/views/Admin/Kols/List.vue'),
            },
            {
                path: 'kols',
                // component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: 'admin-kols',
                        component: () => import('@/views/Admin/Kols/List.vue'),
                    },
                    {
                        path: 'add',
                        name: 'admin-kol-add',
                        component: () => import('@/views/Admin/Kols/Add.vue'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-kol-edit',
                        component: () => import('@/views/Admin/Kols/Edit.vue'),
                    },
                ],
            },
            {
                path: 'creators',
                // component: { render: () => h(RouterView) },
                children: [
                    {
                        path: ':id/profile',
                        component: () => import('@/views/Admin/Kols/Profile/CreatorProfile.vue'),
                        children: [
                            {
                                path: 'personal',
                                component: () => import('@/views/Admin/Kols/Edit.vue'),
                                name: 'admin-creator-profile-personal',
                            },
                            {
                                path: 'global-settings',
                                component: () => import('@/views/Admin/Kols/Profile/CreatorGlobalSettings.vue'),
                                name: 'admin-creator-profile-global-settings',
                            },
                            {
                                path: 'messenger-settings',
                                component: () => import('@/views/Admin/Kols/Profile/CreatorMessengerSettings.vue'),
                                name: 'admin-creator-profile-messenger-settings',
                            },
                            {
                                path: 'subscriptions/plans',
                                component: () => import('@/views/Admin/Kols/Profile/CreatorPlans.vue'),
                                name: 'admin-creator-profile-subscriptions-plans',
                            },
                            {
                                path: 'subscriptions/subscribers',
                                component: () => import('@/views/Admin/Kols/Profile/CreatorSubscribers.vue'),
                                name: 'admin-creator-profile-subscriptions-subscribers',
                                beforeEnter: [roleMiddleware],
                                meta: {
                                    authorize: [RolesHelper.Roles.SuperAdmin],
                                },
                            },
                            {
                                path: 'privacy-security/password',
                                component: () => import('@/views/Admin/Kols/Profile/Password.vue'),
                                name: 'admin-creator-profile-privacy-security-password',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'recipes',
                // component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: 'admin-recipes',
                        component: () => import('@/views/Admin/Recipes/List.vue'),
                    },
                    {
                        path: 'add',
                        name: 'admin-recipe-add',
                        component: () => import('@/views/Admin/Recipes/Add.vue'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-recipe-edit',
                        component: () => import('@/views/Admin/Recipes/Edit.vue'),
                    },
                ]
            },
            {
                path: 'members',
                // component: { render: () => h(RouterView) },
                beforeEnter: [roleMiddleware],
                meta: {
                    authorize: [RolesHelper.Roles.SuperAdmin],
                },
                children: [
                    {
                        path: '',
                        name: 'admin-members',
                        component: () => import('@/views/Admin/Members/List.vue'),
                    },
                    {
                        path: 'add',
                        name: 'admin-member-add',
                        component: () => import('@/views/Admin/Members/Add.vue'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-member-edit',
                        component: () => import('@/views/Admin/Members/Edit.vue'),
                    },
                    {
                        path: 'account-manager/:id',
                        component: () => import('@/views/Admin/Members/AccountManagerProfile/AccountManagerProfile.vue'),
                        beforeEnter: [checkIfAccountManager],
                        children: [
                            {
                                path: 'personal',
                                name: 'admin-member-account-manager-profile-personal',
                                component: () => import('@/views/Admin/Members/Edit.vue'),
                            },
                            {
                                path: 'members',
                                name: 'admin-member-account-manager-profile-creators',
                                component: () =>
                                    import('@/views/Admin/Members/AccountManagerProfile/AccountManagerCreators.vue'),
                            },
                            {
                                path: 'security',
                                name: 'admin-member-account-manager-profile-security',
                                component: () =>
                                    import('@/views/Admin/Members/AccountManagerProfile/AccountManagerSecurity.vue'),
                            },
                            {
                                path: '',
                                redirect: { name: 'admin-member-account-manager-profile-personal' },
                                name: 'admin-member-account-manager-profile',
                            },
                        ],
                    },
                ],
            },
            {
                path: 'user-early-accesses',
                // component: { render: () => h(RouterView) },
                children: [
                    {
                        path: '',
                        name: 'admin-user-early-accesses',
                        component: () => import('@/views/Admin/UserEarlyAccesses/List.vue'),
                    },
                ],
            },
            {
                path: 'settings',
                name: 'admin-settings',
                component: () => import('@/views/Admin/Settings/Settings.vue'),
            },
        ],
    },
]

export default admin