<template>
    <div v-if="props.totalPages > 1" class="row mt-4">
        <div class="col-lg-12">
            <nav aria-label="..." style="float: right">
                <ul class="pagination">
                    <!-- First page -->
                    <li v-if="props.currentPage > 2" class="page-item m-1">
                        <a
                            class="page-link"
                            href="#"
                            @click.prevent="$emit('to-first-page', 1)"
                        >
                            &lt;&lt;
                        </a>
                    </li>

                    <!-- Previous page -->
                    <li v-if="props.currentPage > 1" class="page-item m-1">
                        <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                                $emit('page-changed', props.currentPage - 1)
                            "
                        >
                            &lt;
                        </a>
                    </li>

                    <!-- Pages -->
                    <li v-for="(page, key) in pages" class="page-item m-1">
                        <a
                            :key="key"
                            class="page-link"
                            href="javascript:void(0)"
                            :disabled="page.isDisabled"
                            :class="{ active: isPageActive(page.name) }"
                            @click.prevent="$emit('page-changed', page.name)"
                        >
                            {{ page.name }}
                        </a>
                    </li>

                    <!-- Next page -->
                    <li
                        v-if="
                            props.totalPages > props.maxVisibleButtons &&
                            props.totalPages !== props.currentPage
                        "
                        class="page-item m-1"
                    >
                        <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                                $emit('page-changed', props.currentPage + 1)
                            "
                        >
                            >
                        </a>
                    </li>

                    <!-- Last page -->
                    <li
                        v-if="props.currentPage < props.totalPages - 1"
                        class="page-item m-1"
                    >
                        <a
                            class="page-link"
                            href="#"
                            @click.prevent="
                                $emit('to-last-page', props.totalPages)
                            "
                        >
                            >>
                        </a>
                    </li>
                </ul>
            </nav>
            <div v-if="itemsOf" class="mt-2 me-4" style="float: right">
                {{ props.from }}-{{ props.to }} of {{ props.total }} items
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    maxVisibleButtons: {
        type: Number,
        required: false,
        default: 3,
    },
    from: {
        type: Number,
        // required: true
    },
    to: {
        type: Number,
        // required: true
    },
    total: {
        type: Number,
        required: true,
    },
    totalPages: {
        type: Number,
        required: true,
    },
    perPage: {
        type: Number,
        required: true,
    },
    currentPage: {
        type: Number,
        required: true,
    },
    itemsOf: {
        type: Boolean,
        default: true,
    }
})

const startPage = computed(() => {
    // When on the first page
    if (props.currentPage === 1) {
        return 1
    }

    // When on the last page
    if (props.currentPage === props.totalPages) {
        const start = props.totalPages - (props.maxVisibleButtons - 1)
        if (start === 0) {
            return 1
        } else {
            return start
        }
    }

    // When in between
    return props.currentPage - 1
})

const pages = computed(() => {
    const range = []

    for (
        let i = startPage.value;
        i <=
        Math.min(
            startPage.value + props.maxVisibleButtons - 1,
            props.totalPages
        );
        i++
    ) {
        range.push({
            name: i,
            isDisabled: i === props.currentPage,
        })
    }

    return range
})

function isPageActive(page) {
    return props.currentPage === page
}
</script>