import { defineStore } from 'pinia'
import {ref, watch} from "vue";
import ApiService from "@/services/ApiService";
import {useCreatorStore} from "@/stores/frontend/creator";
import {QueryParamBuilder} from "@/helpers/QueryParamBuilder";


export const useLiveEvents = defineStore('live-events', () => {
    const liveEvents = ref([])
    const nextCursor = ref(null)

    const liveEventsInStorage = localStorage.getItem('creator-live-events')
    // if (liveEventsInStorage) {
    //     const storageLiveEvents = JSON.parse(liveEventsInStorage)
    //     liveEvents.value = {
    //         ...liveEvents.value,
    //         ...storageLiveEvents,
    //     }
    // }

    const stopWatching = watch(
        () => liveEvents,
        (state) => {
            localStorage.setItem('creator-live-events', JSON.stringify(state.value))
        },
        { deep: true }
    )

    /**
     * Find element in store
     */
    const getAll = (query = {}, cursor = null) => {

        let queryFilter = ''

        if (cursor != null) {
            query.cursor = cursor
        }
        else{
            liveEvents.value = []
        }

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        return ApiService.getAll(
            `creator/${useCreatorStore().creator.id}/live-events${queryFilter}`
        )
        .then(({data}) => {
            liveEvents.value.push(...data.data)

            nextCursor.value = data.meta.next_cursor
            return liveEvents.value
        })
    }

    /**
     * Find element in store
     */
    const getLiveEvent = (_eventId) => {
        return ApiService.get(
            `creator/${useCreatorStore().creator.id}/live-events/${_eventId}`
        )
        .then(({data}) => data)
    }

    /**
     * Store new LiveEvent
     * @param data
     */
    const store = (data) => {
        return ApiService.post(
            `creator/${useCreatorStore().creator.id}/live-events`,
            {
                ...data
            }
        )
        .then(({data}) => data)
    }

    /**
     * Update  LiveEvent
     * @param _id
     * @param data
     */
    const update = (_id, data) => {
        return ApiService.put(
            `creator/${useCreatorStore().creator.id}/live-events/${_id}`,
            {
                ...data
            }
        )
        .then((result) => result.data)
    }

    /**
     * Update  LiveEvent
     * @param _id
     */
    const destroy = (_id) => {
        return ApiService.delete(`creator/${useCreatorStore().creator.id}/live-events/${_id}`)
        .then(({data}) => data)
    }

    /**
     * Get/Create direct live creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    const start = (_eventUuid) => {
        return ApiService.post(`/event-live/${_eventUuid}`)
        .then((result) => {
            return result
        })
        .catch((data) => {
            throw 404
        })
    }

    /**
     * Update direct live status
     * @param liveEventId
     * @param data
     * @returns {Promise<axios.AxiosResponse>}
     */
    const updateStream = (liveEventId, data) => {
        return ApiService.patch(`/event-live/${liveEventId}`, data)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get one event live stream
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getStream(creatorId, eventId) {
        return ApiService.get(`/my/${creatorId}/event-live/${eventId}`)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Ban an user (chat)
     * @param directLiveUuid
     * @param userId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function chatBanUser(directLiveUuid, userId) {
        return ApiService.post(`/direct-live/${directLiveUuid}/ban-user`, {user_id: userId})
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Unban an user (chat)
     * @param directLiveUuid
     * @param userId
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function chatUnbanUser(directLiveUuid, userId) {
        return ApiService.post(`/direct-live/${directLiveUuid}/unban-user`, {user_id: userId})
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }


    /**
     * Call to stop watcher
     */
    const unmounted = () => {
        stopWatching()
    }

    return {
        liveEvents,
        nextCursor,

        getAll,
        getLiveEvent,

        getStream,
        start,
        updateStream,

        chatBanUser,
        chatUnbanUser,

        store,
        update,
        destroy,
        unmounted,
    }
})