<template>
    <div v-if="!loading">
        <div class="position-relative d-flex flex-column align-content-center justify-content-end">
            <template v-for="(reaction, index) in reactions" :key="index">
                <button
                    v-if="!isCreator"
                    class="cursor-pointer fs-2"
                    @:click="react(index)"
                >
                    <span class="flex">{{reaction.emoji}}</span>
                </button>


                <Teleport to="#video-streams">
                    <div class="reactions position-absolute">
                        <template v-for="value in reaction.reactions" :key="value.id">
                            <span class="fs-3" :style="`margin-left: ${value.margin}px`">{{reaction.emoji}}</span>
                        </template>
                    </div>
                </Teleport>
            </template>

        </div>
    </div>
</template>

<script setup>
import {useReverbClientStore} from "@/stores/reverbClient";
import {onMounted, ref} from "vue";
import { v4 as uuidv4 } from 'uuid'
import {useLocalLoading} from "@/plugins/Composables/useLocalLoading";

// DEFINES
defineProps({
    isCreator: {
        type: Boolean,
        default: false
    }
})
const {
    sendClientMessage,
    reactions,
    addReaction
} = useReverbClientStore()

// REFS
const timeout = ref(false)

// METHODS
const react = (type) => {
    if (!timeout.value) {
        timeout.value = true
        sendClientMessage(type, 'reaction')
        addReaction(uuidv4(), type, randomMargin())
        setTimeout(() => {
            timeout.value = false
        }, 1000)
    }
}

const randomMargin = (min = -100, max = 100) => {
     return Math.floor(Math.random() * (max - min + 1) + min);
}

const {loading, setLoading} = useLocalLoading()
setLoading(true)
onMounted(() => {
    setLoading(false)
})

</script>

<style lang="scss" scoped>
.reactions {
    position: absolute;
    bottom: 100px;
    left: 50%;
    z-index: 999;
    transform: translate(-50%);
    white-space: nowrap
}

.reactions span {
    display: inline-block;
    position: absolute;
    animation: floatUp 1.1s ease-in-out infinite;
    opacity: 0
}

@keyframes floatUp {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    to {
        transform: translateY(-150px);
        opacity: 0
    }
}
</style>