import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import ApiService from '@/services/ApiService'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'

export const useSubscriptionStore = defineStore(
    'subscription',
    () => {
        const subscription = ref({})

        const guest = ref({
            firstname: null,
            lastname: null,
            email: null,
        })

        // LOCAL STORAGE
        const subscriptionInStorage = localStorage.getItem('subscription')
        if (subscriptionInStorage) {
            const storageSubscription = JSON.parse(subscriptionInStorage)
            subscription.value = {
                ...subscription.value,
                ...storageSubscription,
            }
        }
        watch(
            () => subscription,
            (state) => {
                localStorage.setItem('subscription', JSON.stringify(state.value))
            },
            { deep: true }
        )

        const guestInStorage = localStorage.getItem('guest')
        if (guestInStorage) {
            const storageGuest = JSON.parse(guestInStorage)
            guest.value = {
                ...guest.value,
                ...storageGuest,
            }
        }
        watch(
            () => guest,
            (state) => {
                localStorage.setItem('guest', JSON.stringify(state.value))
            },
            { deep: true }
        )
        // __END_LOCAL STORAGE

        function storePlan(_plan) {
            subscription.value = {
                ...subscription.value,
                ..._plan,
            }
        }
        function storeIntent(subscriptionIntent) {
            subscription.value = {
                ...subscription.value,
                creator_account_id: subscriptionIntent.creator_account_id,
                subscriptionId: subscriptionIntent.id,
                clientSecret: subscriptionIntent.latest_invoice.payment_intent.id,
            }
        }
        function storeMethod(_method) {
            subscription.value = {
                ...subscription.value,
                paymentMethod: _method,
            }
        }

        function setUserAsGuest(user) {
            guest.value = {
                ...user,
            }
        }

        async function registerGuest(_guest) {
            ApiService.init()

            return ApiService.post('/landing-register', _guest)
                .then(({ data }) => {
                    guest.value = {
                        ...guest.value,
                        ...data,
                    }
                    return data
                })
                .catch(({ data }) => {
                    throw data.message
                })
        }

        async function createSetupIntent(userInfo) {
            return ApiService.init()
                .withBearer(guest.value.token)
                .post('/subscription/setup-intent', {
                    ...userInfo,
                    creator_id: subscription.value.creatorId,
                    plan_id: subscription.value.id,
                    subscription_id: subscription.value.subscriptionId
                })
                .then(({ data: intent }) => {
                    storeIntent(intent)
                    return intent
                })
        }

        async function confirmSetupIntent() {
            return ApiService.init()
                .withBearer(guest.value.token)
                .post('/subscription/confirm-intent', {
                    creator_id: subscription.value.creatorId,
                    plan_id: subscription.value.id,
                    subscription_id: subscription.value.subscriptionId
                })
                .then(({ data }) => {
                    return data
                })
                .catch(({ data }) => {
                    throw data.message
                })
        }

        function clear() {
            subscription.value = {}
            guest.value = {}
        }

        function checkRegistrationPasswordSignature(_data) {
            const url = '/landing-registration-password' + QueryParamBuilder.encode(_data)
            return ApiService.get(url)
        }

        async function createFirstPassword(_guest, _query) {
            ApiService.init()

            return ApiService.post('/landing-registration-password' + QueryParamBuilder.encode(_query), _guest)
                .then(({ data }) => {
                    guest.value = {
                        ...guest.value,
                        ...data,
                    }
                    return data
                })
                .catch(({ data }) => {
                    throw data.message
                })
        }

        return {
            subscription,
            guest,

            storePlan,
            storeIntent,
            storeMethod,

            registerGuest,

            createSetupIntent,
            confirmSetupIntent,

            setUserAsGuest,

            clear,
            checkRegistrationPasswordSignature,
            createFirstPassword,
        }
    },
    {}
)