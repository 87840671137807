import { useUserStore } from '@/stores/user'
import { useCreatorStore } from '@/stores/frontend/creator'

export const checkOnboardingMiddleware = async (to, from, next) => {

    if (to.name === 'user-dashboard-with-slug') {
        return next()
    }

    const {getOnboardingData, selectedCreator, isGuest, user} = useUserStore()
    const {needsOnboarding, creator} = useCreatorStore()

    // Init params
    let isCreator = (user.creator !== null && user.creator !== undefined)
    let onboardingRoute = (isCreator) ? 'kol-admin-onboarding' : 'user-onboarding'

    // Get onboarding information
    const onboardingData = await getOnboardingData(true)

    // If creator and onboarding is not done
    if (isCreator && onboardingData.status === 'pending' && needsOnboarding()) {
        return next({
            name: onboardingRoute,
            query: {
                step: onboardingData.step,
            },
        })
    }

    // If creator and onboarding is done
    if (isCreator && onboardingData.status === 'complete') {
        return next()
    }

    // If guest or not subscriber and not creator
    if ((isGuest || !user.has_subscription) && !isCreator) {
        return next()
    }

    // If Creator has workouts and not creator and User subscribed
    if (
        useCreatorStore().hasModule('workouts') &&
        !isCreator &&
        user.creators_subscribed_to.find((_creator) => _creator.id === creator.id)
    ) {
        onboardingRoute = 'user-onboarding'
    } else {
        return next()
    }

    // User onboarding is done
    // if (
    //     onboarded &&
    //     onboardingData &&
    //     onboardingData.status === 'complete'
    // ) {
    if (
        from.name === 'user-onboarding'
        && onboardingData
        && onboardingData.status === 'complete'
    ) {
        return next({
            name: 'user-dashboard-with-slug',
            params: {
                creatorSlug: creator.slug
            }
        })
    }

    // User onboarding is not done
    // if (
    //     !onboarded &&
    //     (!onboardingData || onboardingData.status === 'pending')
    // ) {
    if (!onboardingData || onboardingData.status === 'pending') {
        // TODO Something is not right here, infinite redirect
        // return next({
        //     name: onboardingRoute,
        //     query: {
        //         step: onboardingData.step,
        //     },
        // })
    }

    return next()

    // return async (to, from, next) => {
    //     console.log(3)
    //     console.log(onboarded, to, from, next)
    //
    //     if (to.name === 'user-dashboard-with-slug') {
    //         return next()
    //     }
    //
    //     const { getOnboardingData, selectedCreator, isGuest, user } = useUserStore()
    //     const { needsOnboarding, creator } = useCreatorStore()
    //
    //     // Init params
    //     let isCreator = (user.creator !== null && user.creator !== undefined)
    //     let onboardingRoute = (isCreator) ? 'kol-admin-onboarding' : 'user-onboarding'
    //
    //     // Get onboarding information
    //     const onboardingData = await getOnboardingData(true)
    //
    //     // If creator and onboarding is not done
    //     if (isCreator && onboardingData.status === 'pending' && needsOnboarding()) {
    //         return next({
    //             name: onboardingRoute,
    //             query: {
    //                 step: onboardingData.step,
    //             },
    //         })
    //     }
    //
    //     // If creator and onboarding is done
    //     if (isCreator && onboardingData.status === 'complete') {
    //         return next()
    //     }
    //
    //     // If guest or not subscriber and not creator
    //     if ((isGuest || !user.has_subscription) && !isCreator) {
    //         return next()
    //     }
    //
    //     // If Creator has workouuts and not creator and User subscribed
    //     if (
    //         useCreatorStore().hasModule('workouts') &&
    //         !isCreator &&
    //         user.creators_subscribed_to.find((_creator) => _creator.id === creator.id)
    //     ) {
    //         onboardingRoute = 'user-onboarding'
    //     } else {
    //         return next()
    //     }
    //
    //     // User onboarding is done
    //     if (
    //         onboarded &&
    //         onboardingData &&
    //         onboardingData.status === 'complete'
    //     ) {
    //         return next({
    //             name: 'user-dashboard-with-slug',
    //             params: {
    //                 creatorSlug: creator.slug
    //             }
    //         })
    //     }
    //
    //     // User onboarding is not done
    //     if (
    //         !onboarded &&
    //         (!onboardingData || onboardingData.status === 'pending')
    //     ) {
    //         return next({
    //             name: onboardingRoute,
    //             query: {
    //                 step: onboardingData.step,
    //             },
    //         })
    //     }
    //
    //     return next()
    // }
}