import { useUserStore } from '@/stores/user'

const AvatarHelper = {
    getAvatar: (avatarUrl, name = '') =>
        avatarUrl !== '' && !useUserStore().user.is_guest
            ? avatarUrl
            : 'https://ui-avatars.com/api/?name=' +
              name +
              '.png&background=random',
    getCreatorAvatar: (avatarUrl, name = '') =>
    avatarUrl !== ''
        ? avatarUrl
        : 'https://ui-avatars.com/api/?name=' +
            name +
            '.png&background=random',
}

export default AvatarHelper
