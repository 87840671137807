import { authMiddleware } from '@/router/middleware/authMiddleware'
import RolesHelper from '@/helpers/Roles'
import UserMenuConfig from '@/config/UserMenuConfig'
import { roleMiddleware } from '@/router/middleware/roleMiddleware'
import { hasSubscriptionMiddleware } from '@/router/middleware/hasSubscriptionMiddleware'
import { checkOnboardingMiddleware } from '@/router/middleware/checkOnboardingMiddleware'

import i18n from '@/translation'
import { creatorFinderMiddleware } from '@/router/middleware/creatorFinderMiddleware'

const { t } = i18n.global

const user = [
    {
        path: '/shared/:creatorSlug/:type/:id',
        name: 'user-share-redirector',
        component: () => import('@/views/User/ShareRedirector.vue'),
        beforeEnter: [creatorFinderMiddleware],
        props: true
    },
    {
        path: '/',
        props: true,
        beforeEnter: [authMiddleware, roleMiddleware, checkOnboardingMiddleware],
        meta: {
            authorize: [RolesHelper.Roles.User, RolesHelper.Roles.Guest, RolesHelper.Roles.AccountManager, RolesHelper.Roles.SuperAdmin, RolesHelper.Roles.Creator],
            shouldCreatorSlugParam: true
        },
        children: [
            {
                path: '',
                props: true,
                component: () => import('@/components/Layout/UserLayout.vue'),
                children: [
                    {
                        path: 'home',
                        name: 'user-home',
                        redirect: {name: 'user-home-feed'},
                        component: () => import('@/views/User/Home/HomeLayout.vue'),
                        props: true,
                        children: [
                            {
                                path: 'feed',
                                name: 'user-home-feed',
                                component: () => import('@/views/User/Home/Feed/HomeFeedIndex.vue'),
                                props: true
                            },
                            {
                                path: 'following',
                                name: 'user-home-following',
                                component: () => import('@/views/User/Home/Following/HomeFollowingIndex.vue'),
                                props: true
                            }
                        ]
                    },
                    {
                        path: 'search/creator',
                        name: 'home-search-creator',
                        component: () => import('@/views/User/Home/SearchCreator/HomeSearchCreator.vue'),
                        props: true,
                        meta: {
                            hasTopBar: true,
                            hasBottomBar: true,
                        },
                    },
                    {
                        path: 'account',
                        name: 'user-account',
                        component: () => import('@/views/User/Account.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.myAccount'),
                            backButton: 'user-home',
                        },
                    },
                    {
                        path: 'favorites',
                        name: 'user-favorites',
                        component: () => import('@/views/User/Favorites.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.myFavorites'),
                            backButton: 'user-home',
                        },
                    },
                ]
            },
            // VIEWING A CREATOR
            {
                path: ':creatorSlug',
                name: '',
                beforeEnter: [creatorFinderMiddleware],
                props: true,
                component: () => import('@/components/Layout/UserLayout.vue'),
                children: [
                    {
                        path: 'feed',
                        name: 'user-dashboard-with-slug',
                        component: () => import('@/views/User/Dashboard.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        watch: {
                            $route(to, from) {
                                this.show = false
                            },
                        },
                        meta: {
                            hasRightPanel: true,
                        }
                    },                    
                    {
                        path: 'notification/:type',
                        name: 'user-notification-redirector',
                        component: () => import('@/views/User/NotificationRedirector.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        props: true
                    },
                    {
                        path: 'live-events',
                        children: [
                            {
                                path: '',
                                name: 'user-live-events',
                                component: () => import('@/views/User/LiveEvents/LiveEventsIndex.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasTitleBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('creator.menu.liveEvents'),
                                }
                            },
                            {
                                path: ':uuid',
                                name: 'user-live-event-view',
                                component: () => import('@/views/User/LiveEvents/LiveEventsView.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasTitleBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('creator.menu.liveEvents'),
                                }
                            }
                        ]
                    },
                    {
                        path: 'dashboard',
                        name: 'user-dashboard',
                        component: () => import('@/views/User/Dashboard.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        watch: {
                            $route(to, from) {
                                this.show = false
                            },
                        },
                    },
                    {
                        path: 'posts/:id',
                        name: 'user-post-view',
                        component: () => import('@/views/User/Posts/PostView.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.post'),
                        },
                    },
                    {
                        path: 'video',
                        name: 'user-video',
                        component: () => import('@/views/KolAdmin/MyVideo.vue'),
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('messenger.user.privateCall.title'),
                        },
                    },
                    {
                        path: 'workouts',
                        beforeEnter: [hasSubscriptionMiddleware],
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'user-workouts',
                                component: () => import('@/views/User/Masterclass/Workout/WorkoutList.vue'),
                            },
                            {
                                path: ':workoutId',
                                name: 'user-view-workouts',
                                component: () => import('@/views/User/Masterclass/Workout/WorkoutView.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.workouts'),
                                    backButton: 'user-workouts',
                                    // authorize: [RolesHelper.Roles.User, RolesHelper.Roles.Guest, RolesHelper.Roles.Creator, RolesHelper.Roles.SuperAdmin],
                                },
                            },
                            {
                                path: 'program',
                                children: [],
                                name: 'user-workout-program',
                                component: () => import('@/views/User/WorkoutProgram/WorkoutProgramList.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('user.workouts.subMenu.workoutProgram'),
                                },
                            },
                            {
                                path: 'program/:workoutProgramId',
                                name: 'user-view-workout-program',
                                component: () => import('@/views/User/WorkoutProgram/WorkoutProgramView.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.workouts'),
                                    backButton: 'user-workout-program',
                                },
                            },
                        ],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.workouts'),
                        },
                    },
                    {
                        path: 'masterclass',
                        beforeEnter: [hasSubscriptionMiddleware],
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'user-masterclass',
                                component: () => import('@/views/User/Masterclass/MasterclassList.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('user.menu.masterclasses'),
                                },
                            },
                            {
                                path: ':masterclassId',
                                component: () => import('@/views/User/Masterclass/MasterclassViewV2.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.masterclasses'),
                                    backButton: 'user-masterclass',
                                },
                                children: [
                                    {
                                        path: '',
                                        name: 'user-view-masterclass',
                                        component: () => import('@/views/User/Masterclass/_partials/MasterclassViewStart.vue'),
                                        meta: {
                                            // authorize: [RolesHelper.Roles.User, RolesHelper.Roles.Guest, RolesHelper.Roles.Creator, RolesHelper.Roles.SuperAdmin],
                                            hasTopBar: false,
                                            hasBottomBar: false,
                                            breadcrumbBarTitle: t('user.menu.masterclasses'),
                                            backButton: 'user-masterclass',
                                            offcanvasMenu: 'masterclass-episodes-offcanvas',
                                            hideMainMenuText: true
                                        },
                                        props: {
                                            masterclass: {
                                                type: Object,
                                                default: () => {}
                                            },
                                            loading: {
                                                type: Boolean,
                                                default: true
                                            },
                                        }
                                    },
                                    {
                                        path: 'episode/:episodeId',
                                        name: 'user-view-masterclass-episode',
                                        component: () => import('@/views/User/Masterclass/_partials/MasterclassViewEpisode.vue'),
                                        meta: {
                                            hasTopBar: false,
                                            hasBottomBar: false,
                                            breadcrumbBarTitle: t('user.menu.masterclasses'),
                                            backButton: 'user-masterclass',
                                            offcanvasMenu: 'masterclass-episodes-offcanvas',
                                            hideMainMenuText: true
                                        },
                                        props: {
                                            masterclass: {
                                                type: Object,
                                                default: () => {}
                                            },
                                            episodeId: {
                                                type: Number,
                                                default: null
                                            },
                                            loading: {
                                                type: Boolean,
                                                default: true
                                            },
                                        }
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        path: 'podcasts',
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'user-podcasts',
                                component: () => import('@/views/User/Podcasts/PodcastIndex.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('creator.menu.podcasts'),
                                },
                            },
                            {
                                path: ':episodeId',
                                name: 'user-view-podcast',
                                component: () => import('@/views/User/Podcasts/PodcastEpisode.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('creator.menu.podcasts'),
                                    backButton: 'user-podcasts',
                                },
                            },
                        ],
                    },                    
                    {
                        path: 'calendar',
                        beforeEnter: [hasSubscriptionMiddleware],
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'user-my-calendar',
                                component: () => import('@/views/User/MyCalendar.vue'),
                                watch: {
                                    $route(to, from) {
                                        this.show = false
                                    },
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('user.menu.myCalendar'),
                                },
                            },
                            {
                                path: 'add/:type/:slot',
                                name: 'user-calendar-add-event',
                                component: () => import('@/views/User/Calendar/AddEvent.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.myCalendar'),
                                    backButton: 'user-my-calendar',
                                },
                            },
                            {
                                path: 'call',
                                name: 'user-private-video-call-event',
                                component: () => import('@/views/User/Calendar/PrivateVideoCallEvent.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.myCalendar'),
                                    backButton: 'user-messages',
                                },
                            },
                            {
                                path: 'book/:payment_intent?',
                                name: 'user-private-video-call-book',
                                component: () => import('@/views/User/Calendar/BookCall.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.myCalendar'),
                                    backButton: 'user-messages',
                                },
                            },
                            {
                                path: 'great/:eventId',
                                name: 'user-calendar-congrats',
                                component: () => import('@/views/User/Calendar/Great.vue'),
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.myCalendar'),
                                    backButton: 'user-my-calendar',
                                },
                            },
                        ],
                    },
                    {
                        path: 'messages',
                        name: 'user-messages',
                        component: () => import('@/views/User/Messages.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: t('user.menu.messages'),
                        },
                    },
                    {
                        path: 'progress',
                        name: 'user-progress',
                        component: () => import('@/views/User/MyProgress.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.myProgress'),
                        },
                    },
                    {
                        path: 'gallery',
                        name: 'user-gallery',
                        component: () => import('@/views/User/MyGallery.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasBottomBar: true,
                            breadcrumbBarTitle: t('user.menu.myGallery'),
                        },
                    },
                    {
                        path: 'recipes',
                        beforeEnter: [hasSubscriptionMiddleware],
                        children: [
                            {
                                path: '',
                                children: [],
                                name: 'user-recipes',
                                component: () => import('@/views/KolAdmin/Recipes/MyRecipes.vue'),
                                props: {
                                    isCreator: false,
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: true,
                                    breadcrumbBarTitle: t('user.menu.recipes'),
                                },
                            },
                            {
                                path: ':recipeId',
                                name: 'user-view-recipe',
                                component: () => import('@/views/KolAdmin/Recipes/ViewRecipe.vue'),
                                props: {
                                    isCreator: false,
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.recipes'),
                                    backButton: 'user-recipes',
                                },
                            },
                            {
                                path: 'show-private/:recipeId',
                                children: [],
                                name: 'user-show-private-recipe',
                                component: () => import('@/views/User/Recipes/PrivateRecipes/CreatorPrivateRecipeShow.vue'),
                                props: {
                                    isCreator: false,
                                },
                                meta: {
                                    hasTopBar: false,
                                    hasBottomBar: false,
                                    breadcrumbBarTitle: t('user.menu.recipes'),
                                    backButton: 'user-recipes',
                                },
                            },
                        ],
                    },
                    {
                        path: 'live',
                        name: 'user-live',
                        component: () => import('@/views/User/Live.vue'),
                        beforeEnter: [hasSubscriptionMiddleware],
                        meta: {
                            hasTopBar: false,
                            hasTitleBar: false,
                            hasBottomBar: false,
                            breadcrumbBarTitle: 'Live',
                        }
                    },
                    // {
                    //     path: 'live-ivs',
                    //     name: 'user-live-ivs',
                    //     component: () => import('@/views/User/Live2.vue'),
                    //     beforeEnter: [hasSubscriptionMiddleware],
                    //     meta: {
                    //         hasTopBar: false,
                    //         hasTitleBar: false,
                    //         hasBottomBar: false,
                    //         breadcrumbBarTitle: 'Live',
                    //     }
                    // },
                ]
            },
            // SPECIFIC LIVE MOBILE
            {
                path: ':creatorSlug/live',
                name: 'user-live-mobile',
                props: true,
                component: () => import('@/views/User/Live.vue'),
                beforeEnter: [hasSubscriptionMiddleware],
                meta: {
                    hasTopBar: false,
                    hasTitleBar: false,
                    hasBottomBar: false,
                    breadcrumbBarTitle: 'Live',
                }
            },
            // {
            //     path: 'me',
            //     name: 'profile',
            //     component: () => import('@/views/User/Profile.vue'),
            // },
            // {
            //     path: '/:creatorSlug/live-events/:uuid',
            //     name: 'user-live-event-view-direct',
            //     component: () => import('@/views/User/LiveEvents/LiveEventsView.vue'),
            //     beforeEnter: [creatorFinderMiddleware],
            //     props: true
            // },            
        ],
    },
    {
        path: '/onboarding',
        component: () => import('@/components/Layout/UserOnboardingLayout.vue'),
        beforeEnter: [authMiddleware, roleMiddleware, hasSubscriptionMiddleware, checkOnboardingMiddleware],
        meta: {
            authorize: [RolesHelper.Roles.User],
        },
        children: [
            {
                path: '',
                name: 'user-onboarding',
                component: () => import('@/views/User/Onboarding.vue'),
            },
        ],
    },
]

export default user