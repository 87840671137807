import qs from 'qs'

/**
 * USAGE
 * let query = QueryParamBuilder.encode({
 *       filters: {
 *           role: ['User', 'Super Admin'],
 *           other: []
 *       },
 *       sort: [
 *         '-username','date'
 *       ]
 *   })
 */
export class QueryParamBuilder {
    static encode(data) {
        return qs.stringify(data, {
            arrayFormat: 'comma',
            addQueryPrefix: true,
        })
    }
}
