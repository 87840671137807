<template>
    <div v-if="item" class="d-flex align-items-start justify-content-center flex-grow-1 my-2">
        <template v-if="isImage">
            <PostImageMedia
                :id="`${item.id}`"
                :key="`post-image-${item.id}`"
                :media="item.media"
                :poster="item.video_poster"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
        <template v-else-if="isAudio">
            <PostAudioMedia
                :id="`${item.id}`"
                :key="`post-audio-${item.id}`"
                :pause-on-hide="pauseOnHide"
                :media="item.media"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
        <template v-else-if="isVideo">
            <PostVideoMedia
                :id="`${item.id}`"
                :key="`post-video-${item.id}${isView?'-viewer':''}`"
                :pause-on-hide="pauseOnHide"
                :media="item.media"
                :is-view="isView"
                :video="item.video"
                :poster="item.video_poster"
                :teaser-poster="item.media?.poster"
                :post-is-locked="postIsLocked"
                :checked-visibility="item.checked_visibility"
            />
        </template>
    </div>
</template>

<script setup>
import {computed, onBeforeMount, onMounted, ref, toRaw} from "vue";
import { useI18n } from 'vue-i18n'
import PostImageMedia from "@/views/Common/Posts/Media/PostImageMedia.vue";
import PostAudioMedia from "@/views/Common/Posts/Media/PostAudioMedia.vue";
import PostVideoMedia from "@/views/Common/Posts/Media/PostVideoMedia.vue";

const { t } = useI18n()

const props = defineProps({
    item: {
        type: Object,
        default: () => ({
            id: 0,
            media: {
                original: false,
                thumb: false,
                media_type: ""
            },
            checked_visibility: 'blur',
        })
    },
    isView: {
        type: Boolean,
        default: false,
    },
    postIsLocked: {
        type: Boolean,
        default: false,
    },
    pauseOnHide: {
        type: Boolean,
        default: false,
    },
})

const isImage = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (props.item.media.media_type?.startsWith('image/') || props.item.media.media_type?.startsWith('locked_image/'))
})
const isAudio = computed(() => {
    return Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (props.item.media.media_type?.startsWith('audio/') || props.item.media.media_type?.startsWith('locked_audio/'))
})
const isVideo = computed(() => {
    return (
            Object.prototype.hasOwnProperty.call(props.item.media, "media_type")
        && props.item.media.media_type !== null
        && (
            props.item.media.media_type?.startsWith('video/')
            || props.item.media.media_type?.startsWith('locked_video/')
        )
    )
        || (props.item.video !== '' && props.item.video !== null && props.item.video !== undefined)
})


onMounted(() => {
})

</script>