export class FormHelper {
    static prepareVueMultiselectForBooleanGroup(data, initial) {

        return !data.length ? {} : Object.assign(
            ...initial.map((item) => {
                return {
                    [`${item.name}`]: data.some((d) => d.name === item.name)
                }
            })
        )
    }
    static prepareBooleanGroupForVueMultiselect(data, enumData) {
        let res = []
        Object.entries(data).map(([key, value]) => {
            if (value === true){
                res.push({
                    label: enumData[key],
                    name: key
                })
            }
        })
        return res
    }

    static sortArrayByDate(array, key = 'created_at') {
        return [...array].sort((a, b) => new Date(b[key]) - new Date(a[key]))

    }
}