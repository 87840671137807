<template>
    <a v-if="href" :href="href" :class="classNames" :type="type">
        <slot />
    </a>
    <button v-else :type="type" :class="classNames" :disabled="loading" @click="onClick">
        <template v-if="loading">
            <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>
            <span class="visually-hidden" role="status">Loading...</span>
        </template>
        <slot />
    </button>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    isBig: {
        type: Boolean,
        default: true,
    },
    onClick: {
        type: Function,
        default: null,
    },
    href: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'submit',
    },
    color: {
        type: String,
        default: 'btn-tertiary',
    },
    loading: {
        type: Boolean,
        default: false
    }
})

const isBig = computed(() => props.isBig ?? true)
const href = computed(() => props.href)
const type = computed(() => props.type)
const classNames = computed(() => {
    return [
        'btn', props.color, {'btn-lg': !!isBig.value}
    ]
})
</script>