<template>
    <div>
        <div
            v-if="
                notificationStore.notification.type === NOTIFICATION_TYPE.INFO &&
                notificationStore.notification.content !== ''
            "
            id="notification-info"
            class="card position-fixed rounded-4 shadow-sm mt-4"
            :class="$breakpoints.smAndDown.value ? 'top-5 start-10' : 'top-0 start-50'"
            :style="$breakpoints.smAndUp.value ? 'z-index: 9999; transform: translateX(-230px)' : 'z-index: 9999;'"
        >
            <div class="card-body d-flex justifify-content-center">
                <div class="avatar-cropper d-flex align-items-center me-3">
                    <img
                        v-if="
                            notificationStore.notification.content.image !== undefined &&
                            notificationStore.notification.content.image !== ''
                        "
                        :src="notificationStore.notification.content.image"
                        class="avatar-pic"
                        alt="avatar"
                    />
<!--                    :src="'@/assets/images/logo-form-modal.svg'"-->
                    <img v-else src="/images/logo-form-modal.svg" class="avatar-pic" alt="avatar" />
                </div>
                <div class="fw-medium me-2" :style="$breakpoints.smAndDown.value ? 'width: 250px' : 'max-width: 300px'">
                    <h6>{{ notificationStore.notification.content.title }}</h6>
                    <div class="fw-light overflow-hidden" style="max-height: 100px">
                        {{ notificationStore.notification.content.body }}
                    </div>
                    <div
                        class="text-center text-primary fw-light text-decoration-underline cursor-pointer"
                        @click.prevent="redirect(notificationStore.notification.content.url)"
                    >
                        {{ $t(`globals.seeMore`) }}
                    </div>
                </div>
                <div class="d-flex align-items-top text-gray">
                    <button type="button" class="btn-close fs-8" aria-label="Close" @click="closeInfo"></button>
                </div>
            </div>
        </div>
        <div id="notification-modal" class="modal modal-md fade" tabindex="-1" aria-hidden="true" aria-labelledby="">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <img
                            v-if="notificationStore.notification.type === NOTIFICATION_TYPE.INFO"
                            src="/images/logo-form-modal.svg"
                            role="presentation"
                            class="form-modal-image"
                        />
                        <i
                            class="fa notification-icon"
                            :class="{
                                'fa-solid fa-circle-xmark text-danger':
                                    notificationStore.notification.type === NOTIFICATION_TYPE.ERROR,
                                'fa-solid fa-triangle-exclamation text-warning':
                                    notificationStore.notification.type === NOTIFICATION_TYPE.WARNING,
                                'fa-solid fa-check text-success':
                                    notificationStore.notification.type === NOTIFICATION_TYPE.SUCCESS,
                            }"
                        ></i>
                        <button class="form-modal-header-close" @click="closeModal">
                            <img src="../assets/images/close-icon.svg" alt="Close" />
                        </button>
                        <div class="form-modal-header-title" v-html="notificationStore.notification.content"></div>
                        <div v-if="notificationStore.notification.body !== ''" class="fw-light overflow-hidden mt-2" style="max-height: 100px">
                            {{ notificationStore.notification.body }}
                        </div>
                        <Spacer size="1" />
                        <div class="modal-footer">
                            <PrimaryButton
                                v-if="notificationStore.notification.cancelText"
                                type="button"
                                color="btn-outline-primary"
                                @click="closeModal"
                            >
                                {{ notificationStore.notification.cancelText }}
                            </PrimaryButton>

                            <PrimaryButton v-if="notificationStore.notification.submitText" :on-click="confirmModal">
                                {{ notificationStore.notification.submitText }}
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="toast-container position-fixed top-0 start-50 translate-middle-x p-3">
            <div
                id="notification-toast"
                class="toast align-items-center border-0"
                :class="{
                    'text-bg-danger': notificationStore.notification.type === NOTIFICATION_TYPE.ERROR,
                    'text-bg-warning': notificationStore.notification.type === NOTIFICATION_TYPE.WARNING,
                    'text-bg-success': notificationStore.notification.type === NOTIFICATION_TYPE.SUCCESS,
                }"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
            >
                <div class="d-flex">
                    <div class="d-flex flex-column">
                        <div class="toast-body" v-html="notificationStore.notification.content"></div>

                        <button
                            class="m-2 btn btn-sm btn-outline-light"
                            v-if="notificationStore.notification.submitText"
                            @click="confirmToast"
                        >
                            {{ notificationStore.notification.submitText }}
                        </button>
                    </div>
                    <button
                        type="button"
                        class="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useRegistrationCtaModalStore } from "@/stores/registrationCtaModal";
import { useNotificationStore, NOTIFICATION_TYPE } from '@/stores/notification'
import { Modal, Toast } from 'bootstrap'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import Spacer from '@/components/Spacer.vue'
import getRoute from '@/helpers/NotificationHelper'
import IdbService from '@/services/IdbService'

const notificationStore = useNotificationStore()
const isConfirmed = ref(false)
let currentModalObject = null
const userStore = useUserStore()
const registerCtaStore = useRegistrationCtaModalStore()
const typeToRoute = {
    post: 'user-dashboard-with-slug',
    live: 'user-live',
    messenger: 'user-messages',
    workout: 'user-workouts',
    masterclass: 'user-masterclass',
}

async function redirect(_path) {
    // isSubscriber?
    if (userStore.cannot('goto')) {
        console.log(registerCtaStore)
        registerCtaStore.showModal()
        return false
    }

    // Mark as read
    IdbService.getAll(userStore.selectedCreator)
        .then((response) => {
            let elem = response.data[0]
            IdbService.markAsRead(elem.id)
                .then(async () => {
                    // Decrement notification counter
                    notificationStore.currentCounter[userStore.selectedCreator.slug]--
                    // Hide notifcation and redirect
                    notificationStore.hideNotification()
                    await router.push(getRoute(_path))
                })
        })
}

function notificationInfo() {
    // Display element
    setTimeout(() => {
        // Hide element after 5sec
        if (document.querySelector('#notification-info')) {
            document.querySelector('#notification-info').classList.add('hidden-info')
        }
    }, 5000)
    setTimeout(() => {
        // Empty notification
        notificationStore.hideNotification()
    }, 6000)

    // Increment notification counter
    notificationStore.currentCounter[userStore.selectedCreator.slug]++
}

onMounted(() => {
    currentModalObject = new Modal('#notification-modal')

    document.getElementById('notification-modal').addEventListener('hidden.bs.modal', () => {
        if (isConfirmed.value) {
            notificationStore.notification.onConfirm()
        } else {
            notificationStore.notification.onClose()
        }

        notificationStore.hideNotification()
    })

    document.getElementById('notification-toast').addEventListener('hidden.bs.toast', () => {
        if (isConfirmed.value) {
            notificationStore.notification.onConfirm()
        } else {
            notificationStore.notification.onClose()
        }

        notificationStore.hideNotification()
    })
})

notificationStore.onDisplay(() => {
    if (notificationStore.notification.asToast) {
        Toast.getOrCreateInstance('#notification-toast').show()
    } else if (notificationStore.notification.type === NOTIFICATION_TYPE.INFO) {
        notificationInfo()
    } else {
        currentModalObject.show()
    }
})

notificationStore.onHide(() => {
    if (notificationStore.notification.asToast) {
        Toast.getOrCreateInstance('#notification-toast').hide()
    } else {
        currentModalObject.hide()
    }
})

function confirmToast() {
    isConfirmed.value = true
    Toast.getOrCreateInstance('#notification-toast').hide()
}
function confirmModal() {
    isConfirmed.value = true
    currentModalObject.hide()
}
function closeInfo() {
    notificationStore.hideNotification()
}

function closeModal() {
    isConfirmed.value = false
    currentModalObject.hide()
}
</script>

<style scoped>
.notification-icon {
    font-size: 5rem;
}
#notification-modal {
    z-index: 9999;
}
#notification-info {
    opacity: 0.9;
}
.hidden-info {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.8s, opacity 0.8s linear;
}
.avatar-cropper {
    position: relative;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50%;
}
.avatar-pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>