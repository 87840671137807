import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import JwtService from '@/services/JwtService'
import { computed, ref, watch } from 'vue'
import router from '@/router'
import RolesHelper from '@/helpers/Roles'
import PermissionsHelper from '@/helpers/PermissionsHelper'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import { useCreatorStore } from '@/stores/frontend/creator'
import { useLoadingStore } from '@/stores/frontend/loading'
import i18n from '@/translation'
import {useSentry} from "@/plugins/Composables/useSentry";

const DEFAULT_IMPERSONATOR = {
    name: '',
    email: '',
    id: false,
    verified: false,
    role: '',
    token: '',
    code: '',
}

const DEFAULT_USER = {
    name: '',
    lastname: '',
    email: '',
    id: '',
    ulid: '',
    avatar: '',
    verified: false,
    role: '',
    token: '',
    fcm_token: '',
    device_id: '',
    code: '',
    postal_code: null,
    country_id: null,
    creator: null,
    preview: false,
    has_subscription: false,
    is_account_manager: false,
    is_creator: false,
    // !!! DIRECT LIVE CHAT BAN !!!
    is_logged_in: {
        // 0: false KEY = CREATOR_ULID
    },
    // !!! DIRECT LIVE CHAT BAN !!!
    refresh: false,
    creators_following: [],
    creators_subscribed_to: []
}

const DEFAULT_SELECTED_CREATOR = {
    id: null,
    ulid: null,
    full_name: '',
    avatar: '',
    slug: '',
}

export const useUserStore = defineStore('user', () => {
    const errors = ref([])

    const creatorStore = useCreatorStore()

    const user = ref(DEFAULT_USER)
    let Permissions = new PermissionsHelper(user.value)

    const selectedCreator = ref(DEFAULT_SELECTED_CREATOR)

    const impersonator = ref(DEFAULT_IMPERSONATOR)

    const onboardingData = ref()

    const isAuthenticated = computed(() => !!JwtService.getToken())
    const isVerified = computed(() => !!user.value.verified)
    const role = computed(() => (user.value.role ? user.value.role : null))

    const isGuest = computed(() => user.value.role === RolesHelper.Roles.Guest)
    const requestedSettingsMenu = ref("")


    function can(ability, model = null) {
        return Permissions.can(ability, model)
    }

    function cannot(ability, model = null) {
        return Permissions.cannot(ability, model)
    }

    const creator = computed(() => user.value.creator)
    const isImpersonating = computed(() => impersonator.value.id)
    const inPreviewMode = computed(() => !!user.value.preview)
    const isCreator = computed(
        () =>
            user.value.role === RolesHelper.Roles.Creator &&
            user.value.creator !== undefined &&
            user.value.creator !== null
    )

    // Local Storage, User purchase product
    const purchase = ref({})

    const preferredLanguage = computed(() => (currentLang.value !== null ? currentLang.value : 'en'))
    // const preferredLanguage = computed(() => 'en')

    const currentLang = ref(null)

    function setLanguage(_lang = 'en') {
        currentLang.value = _lang
        localStorage.setItem('user-preferred-lang', _lang)
    }

    const langInStorage = localStorage.getItem('user-preferred-lang')
    if (langInStorage) {
        currentLang.value = langInStorage
    }
    watch(
        () => currentLang,
        (state) => {
            localStorage.setItem('user-preferred-lang', state.value)
        }
    )

    const userInStorage = localStorage.getItem('user')
    if (userInStorage) {
        const storageUser = JSON.parse(userInStorage)
        user.value = {
            ...user.value,
            ...storageUser,
        }
        Permissions = new PermissionsHelper(user.value)
    }
    watch(
        () => user,
        (state) => {
            localStorage.setItem('user', JSON.stringify(state.value))
        },
        { deep: true }
    )

    const impersonatorInStorage = localStorage.getItem('impersonator')
    if (impersonatorInStorage) {
        const storageImpersonator = JSON.parse(impersonatorInStorage)
        impersonator.value = {
            ...impersonator.value,
            ...storageImpersonator,
        }
    }
    watch(
        () => impersonator,
        (state) => {
            localStorage.setItem('impersonator', JSON.stringify(state.value))
        },
        { deep: true }
    )

    const selectedCreatorInStorage = localStorage.getItem('selectedCreator')
    if (selectedCreatorInStorage) {
        const storageSelectedCreator = JSON.parse(selectedCreatorInStorage)
        selectedCreator.value = {
            ...selectedCreator.value,
            ...storageSelectedCreator,
        }
    }
    watch(
        () => selectedCreator,
        (state) => {
            localStorage.setItem('selectedCreator', JSON.stringify(state.value))
        },
        { deep: true }
    )

    const avatar = computed(() =>
        user.value.avatar !== ''
            ? user.value.avatar
            : 'https://ui-avatars.com/api/?name=' + user.value.name + '.png&background=random'
    )

    function setNewAvatar(avatar) {
        user.value.avatar = avatar
        user.value.creator.avatar = avatar
    }

    function setNewCover(cover) {
        user.value.cover = cover
        user.value.creator.cover = cover
    }

    function setNewHeaderImg(headerImg) {
        user.value.headerImg = headerImg
        user.value.creator.headerImg = headerImg
    }

    function setModulesStatus(status) {
        user.value.creator.modules = {...status}
        useCreatorStore().setModules(status)
    }

    /**
     *  Set preview
     * @param state
     */
    function setPreview(state) {
        user.value.preview = state
        if (!state && user.value.is_creator) {
            useUserStore().user.is_creator = false
            useUserStore().user.role = RolesHelper.Roles.Creator
        }
    }

    function refreshCreator() {
        if (user.value.creator !== undefined && user.value.creator !== null && user.value.creator.id !== undefined && user.value.creator.id !== null) {
            return ApiService.get(`creators/${user.value.creator.id}`)
            .then(({data}) => {
                user.value.creator = {...data}
                setSelectedCreator(data)
            })
            .catch(({data}) => {
                throw data.message
            })
        }
        else{
            return Promise.reject('No creator to refresh')
        }
    }

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Set authenticated user
     * @param authUser
     */
    function setAuth(authUser = {}) {
        user.value = { ...authUser }
        Permissions = new PermissionsHelper(user.value)

        if (!user.value.is_guest) {
            setLanguage(user.value.preferred_language)
        }

        errors.value = []
        JwtService.setToken(user.value.token)

        useSentry().setUser({
            id: user.value.id,
            email: user.value.email,
            username: user.value.username
        })

        useSentry().setUserRole(user.value.role)

        if (creatorStore.creator.slug !== '') {
            setSelectedCreator({ ...creatorStore.creator })
            return
        }

        // User has any subscription ?
        // if (user.value.has_subscription === 0 && user.value.creators_following.length > 0) {
        //
        //     let creatorToSelect = user.value.creators_following.length === 1
        //         ? user.value.creators_following[0]
        //         : user.value.creators_following.find((creator) => !creator.is_ikonstar)
        //
        //
        //     setSelectedCreator({ ...creatorToSelect })
        //
        //     return
        // }

        // Set itself as select creator
        if (authUser.role === RolesHelper.Roles.Creator) {
            setSelectedCreator({ ...authUser.creator })
            return
        }
        // Set account manager as itself
        if (
            authUser.role === RolesHelper.Roles.AccountManager &&
            user.value.creator !== undefined &&
            user.value.creator !== null
        ) {
            setSelectedCreator({ ...authUser.creator })
            return
        }

        // if (
        //     authUser.role === RolesHelper.Roles.User &&
        //     authUser.creators_subscribed_to &&
        //     authUser.creators_subscribed_to.length === 1
        // ) {
        //     setSelectedCreator({ ...authUser.creators_subscribed_to[0] })
        //     return
        // }

        // if (
        //     authUser.role === RolesHelper.Roles.User &&
        //     authUser.creators_following &&
        //     authUser.creators_following.length === 1
        // ) {
        //     setSelectedCreator({ ...authUser.creators_following[0] })
        //
        //     return
        // }
    }

    function setSelectedCreator(_creator = {}) {
        selectedCreator.value = { ..._creator }
        creatorStore.setCreator({ ..._creator })

        useSentry().setSelectedCreator({
            id: _creator.id,
            slug: _creator.slug
        })


        // if (user.value.role === RolesHelper.Roles.Creator) {
        //     user.value.role = RolesHelper.Roles.User
        // }

    }

    /**
     * Purge store
     */
    function purgeAuth() {
        return new Promise((resolve) => {
            user.value = DEFAULT_USER
            localStorage.removeItem('user')
            localStorage.removeItem('guest')
            useSentry().setUser(null)
            useSentry().setUserRole(null)

            impersonator.value = DEFAULT_IMPERSONATOR
            localStorage.removeItem('impersonator')

            selectedCreator.value = DEFAULT_SELECTED_CREATOR
            creatorStore.setCreator({ ...DEFAULT_SELECTED_CREATOR })
            localStorage.removeItem('selectedCreator')
            useSentry().setSelectedCreator(null)

            localStorage.removeItem('user-onboarding')
            localStorage.removeItem('user-preferred_lang')
            localStorage.removeItem('subscription')

            errors.value = []
            JwtService.destroyToken()

            resolve('ok')
        })
    }

    /**
     * Log user in
     * @param credentials
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function login(credentials = { email: '', password: '', device_name: '' }) {
        return ApiService.post('login', credentials)
            .then(({ data }) => {
                setAuth(data)

                if (role.value === 'User') {
                    getOnboardingData()
                }

                i18n.global.locale.value = currentLang.value

                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Login guest
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function loginGuest() {
        if (!JwtService.getToken()) {
            return ApiService.post('login-guest')
                .then(({ data }) => {
                    setAuth(data)
                    return data
                })
                .catch((error) => {
                    throw error
                })
        } else {
            return Promise.resolve('logged')
        }
    }

    async function changePassword(data) {
        return ApiService.post('/me/new-password', data)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Impersonate a User or Creator
     * @returns {Promise<axios.AxiosResponse>}
     * @param user_id
     */
    async function impersonate(user_id) {
        return ApiService.post('/creator/impersonate', {
            user_id,
        })
            .then(({ data }) => {
                setImpersonated(data)
                setSelectedCreator(data.creator)
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function impersonateUser(user_id) {
        return ApiService.post('/users/impersonate', { user_id })
            .then(({ data }) => {
                setImpersonated(data)
                setSelectedCreator({...DEFAULT_SELECTED_CREATOR})
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Set impersonated user
     * @param authUser
     */
    function setImpersonated(authUser = {}) {
        impersonator.value = {
            ...user.value,
            impersonator: authUser.id,
        }
        user.value = {
            ...authUser,
        }

        user.value.is_account_manager = false
        if (
            user.value.creator !== undefined
            && user.value.creator !== null
            && authUser.role === RolesHelper.Roles.AccountManager
        ) {
            user.value.is_account_manager = true
            user.value.role = RolesHelper.Roles.Creator
        }

        errors.value = []
        JwtService.setToken(user.value.token)
    }

    /**
     * Return to normal
     */
    async function stopImpersonating() {
        return new Promise((resolve) => {
            user.value = {
                ...impersonator.value,
            }
            impersonator.value = {}
            JwtService.setToken(user.value.token)

            if (user.value.is_account_manager) {
                refreshCreator()
                .then(() => {
                    user.value.role = RolesHelper.Roles.Creator
                    user.value.is_creator = false
                    // user.value.refresh = true

                    resolve('kol-admin-my-creators')
                })
            }
            else {
                resolve(redirectionRoute.value)
            }
        })
    }

    /**
     * View as subscriber specific Account Manager
     */
    function viewAsSubscriber(destroy = false) {
        if (destroy) {
            refreshCreator()
            .then(() => {
                user.value.role = RolesHelper.Roles.Creator
                user.value.is_creator = false
                user.value.refresh = true
                router.push({name: 'kol-admin-my-creators'})
            })
        }
    }

    /**
     * Forgot password
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function forgot(values = { email: '' }) {
        // Email typed in the form
        user.value = { ...values }
        // Call to the api
        return ApiService.post('password/email', values)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Code to validate forgotten password
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function code(values = { email: '', code: '' }) {
        // Code typed in the form
        user.value = { ...values }
        return ApiService.post('password/code/verify', values)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Request a code to change my email
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function requestChangeEmail(values) {
        // Code typed in the form
        return ApiService.post('me/new-email', values)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Reset the password
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function resetPassword(values = { email: '', code: '', password: '' }) {
        return ApiService.post('password/reset', values)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Code to validate the email
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function verify() {
        // Code typed in the form
        return ApiService.get('email/code/send')
            .then((response) => {
                return response
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Submit code to validate the new email
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function verifyCodeNewEmail(values) {
        return ApiService.post('me/new-email/code', values)
            .then((response) => {
                return response
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Check if the code is valid and set email to verified
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function verified(values = { code: '' }) {
        // Code typed in the form
        return ApiService.post('email/code/verified', values)
            .then((data) => {
                // update verified in the user
                setAuth({ ...user.value, verified: true })
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function register(values) {
        return ApiService.post('register', values)
            .then(({ data }) => {
                setAuth(data)
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function updatePersonalInfo(values) {
        return ApiService.patch('me/personal-info', values)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function fetchUser(forceSet = true) {

        return ApiService.get('me')
            .then(({ data }) => {

                setAuth({
                    ...user.value,
                    ...data.data,
                })

                // If role is account manager need to set to creator
                if (user.value.role === RolesHelper.Roles.AccountManager) {
                    user.value.role = RolesHelper.Roles.Creator
                }

                // Spe creator watch a creator
                if (forceSet && (user.value.role === RolesHelper.Roles.Creator || user.value.is_account_manager)) {
                    user.value.role = RolesHelper.Roles.User
                    user.value.is_creator = true
                }

                return data.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getSubscription(creatorUlid) {
        return ApiService.get('me/subscription?creator=' + creatorUlid)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }
    async function getAllSubscriptions() {
        return ApiService.get('me/subscription')
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Cancel my subscription
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function cancelSubscription(creatorUlid) {
        return ApiService.delete('me/subscription/cancel?creator=' + creatorUlid)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Resume my subscription
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function resumeSubscription(creatorUlid) {
        return ApiService.patch('me/subscription/resume?creator=' + creatorUlid)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Log user out
     */
    function logout() {
        useLoadingStore().setLoginOut(true)
        useLoadingStore().setLoading(true, true)

        purgeAuth()
            .then(async () => {
                let result = await router.push({ path: '/' })
                router.go(0)
            })
            .finally(() => {
                useLoadingStore().setLoginOut(false)
                useLoadingStore().setLoading(false)
            })
    }


    const onboardingInStorage = localStorage.getItem('user-onboarding')
    if (onboardingInStorage) {
        onboardingData.value = JSON.parse(onboardingInStorage)
    }
    watch(
        () => onboardingData,
        (state) => {
            localStorage.setItem('user-onboarding', JSON.stringify(state.value))
        }, {
            deep: true
        }
    )

    const fetchingOnboarding = ref(null)
    // const isFetchingInStorage = localStorage.getItem('user-onboarding-fetching')
    // if (isFetchingInStorage) {
    //     fetchingOnboarding.value = JSON.parse(isFetchingInStorage)
    // }
    // watch(
    //     () => fetchingOnboarding,
    //     (state) => {
    //         state.value === null
    //             ? localStorage.removeItem('user-onboarding-fetching')
    //             : localStorage.setItem('user-onboarding-fetching', JSON.stringify(state.value))
    //     }, {
    //         deep: true
    //     }
    // )

    async function getOnboardingData(refresh = false) {
        // if (fetchingOnboarding.value !== 'null' && fetchingOnboarding.value !== null && Object.prototype.hasOwnProperty.call(fetchingOnboarding.value, 'id')) {
        if (fetchingOnboarding.value !== 'null' && fetchingOnboarding.value !== null) {
            return fetchingOnboarding.value;
        }

        fetchingOnboarding.value = new Promise(async (resolve, reject) => {
            try {
                if (!refresh && onboardingData.value) {
                    resolve(onboardingData.value)
                }

                const { data } = await ApiService.get('me/onboarding')

                onboardingData.value = { ...data }

                fetchingOnboarding.value = null;
                resolve(onboardingData.value);
            }
            catch (err) {
                reject(err);
            }
            finally {
                fetchingOnboarding.value = null;
            }
        });
        return fetchingOnboarding.value;
    }

    async function submitOnboardingData(data) {
        return ApiService.patch('me/onboarding', data)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getPaymentInfo() {
        return ApiService.get('me/payment-info')
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    const redirectionRoute = computed(() => {
        return getRedirectRoute()
    })

    function getRedirectRoute() {
        switch (role.value) {
            case RolesHelper.Roles.User:
                return 'user-home-feed'
                if (user.value.has_subscription && user.value.creators_subscribed_to.length > 1) {
                    return 'creator-select'
                }

                // if (!user.value.has_subscription) {
                //     return 'user-account'
                // }

                return 'user-dashboard-with-slug'
            case RolesHelper.Roles.Guest:
                return 'user-dashboard-with-slug'
            case RolesHelper.Roles.SuperAdmin:
                return 'admin-dashboard'
            case RolesHelper.Roles.Creator:
                return 'kol-admin-my-feed'
            case RolesHelper.Roles.CreatorManager:
                return 'admin-dashboard'
            case RolesHelper.Roles.AccountManager:

                if (
                    user.value.creator !== undefined &&
                    user.value.creator !== null
                ) {
                    user.value.is_account_manager = true
                    user.value.role = RolesHelper.Roles.Creator
                }

                return 'kol-admin-my-feed'
        }
    }

    /**
     * Store selected product
     * @param data
     */
    function storeProduct(data) {
        // let stored = getStoredProduct()
        purchase.value = {
            ...purchase.value,
            ...data,
        }
        localStorage.setItem('purchase', JSON.stringify(purchase.value))
    }

    /**
     * Get stored product
     */
    function getStoredProduct() {
        const data = localStorage.getItem('purchase')
        return JSON.parse(data)
    }

    // Store intent
    function storeIntent(_intent) {
        let stored = getStoredProduct()
        purchase.value = {
            ...stored,
            setupIntent: _intent,
        }
        localStorage.setItem('purchase', JSON.stringify(purchase.value))
    }

    // Store method payment
    function storeMethod(_method) {
        let stored = getStoredProduct()
        purchase.value = {
            ...stored,
            paymentMethod: _method,
        }
        localStorage.setItem('purchase', JSON.stringify(purchase.value))
    }

    /**
     * Create a setup intent
     * @param type
     * @param data
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function createSetupIntent(type, data = {}) {
        // Get right route based on type of product
        let route = ''
        let stored = getStoredProduct()
        switch (type) {
            case 'private-call': {
                let productId = stored.product.id
                route = 'live/private-video-calls/' + productId + '/purchase-intent'
                break
            }
            case 'live-event': {
                let productId = stored.product.id
                route = 'live/events/' + productId + '/purchase-intent'
                break
            }
        }
        return ApiService.post(route, {...data}).then(({ data: intent }) => {
            storeIntent(intent)
            return intent
        })
    }

    /**
     * Clear stored product
     */
    function clear() {
        localStorage.removeItem('purchase')
    }

    /**
     * Purchased a private video call event
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function purchasedPrivateVideoCall(values) {
        return ApiService.post('live/private-video-calls/' + values.productId + '/create-event', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Purchased a private video call event
     * @param values
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function purchasedLiveEvent(values) {
        return ApiService.post('live/events/' + values.productId + '/confirm-payment', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * User stats
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function userStats(query = '') {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        // Call to the api
        return ApiService.get('my/stats' + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Preselect sub section into Account
     * @returns {Promise<axios.AxiosResponse>}
     */
    function setRequestedSettingsMenu(_menu = "") {
        requestedSettingsMenu.value = _menu
    }


    /**
     * Get global likes
     * @param type
     * @param cursor
     * @param filter
     * @return {Promise<axios.AxiosResponse>}
     */
    async function getLiked(type, cursor = '', filter = {}) {
        return ApiService.getAll('liked/' + type, {
            params: {
                cursor,
                filter,
            },
        })
        .then((data) => {
            return data
        })
        .catch(({data}) => {
            throw data.message
        })
    }



    return {
        user,
        impersonator,
        errors,
        avatar,
        selectedCreator,
        onboardingData,

        isGuest,
        isCreator,
        // Permissions
        can,
        cannot,

        isVerified,
        isAuthenticated,
        isImpersonating,
        role,
        redirectionRoute,
        creator,
        inPreviewMode,

        fetchUser,
        logout,
        login,
        loginGuest,
        register,
        changePassword,
        refreshCreator,
        impersonate,
        impersonateUser,
        stopImpersonating,
        viewAsSubscriber,
        forgot,
        code,
        resetPassword,
        verify,
        requestChangeEmail,
        verifyCodeNewEmail,
        verified,
        purgeAuth,
        updatePersonalInfo,
        setErrors,
        getRedirectRoute,
        setNewAvatar,
        setNewCover,
        setNewHeaderImg,
        setModulesStatus,
        setPreview,
        setSelectedCreator,
        getSubscription,
        getAllSubscriptions,
        cancelSubscription,
        resumeSubscription,
        getOnboardingData,
        submitOnboardingData,
        setAuth,

        userStats,

        getPaymentInfo,
        storeProduct,
        getStoredProduct,
        storeIntent,
        storeMethod,
        createSetupIntent,
        clear,
        purchasedPrivateVideoCall,
        purchasedLiveEvent,

        /********************
         * LANGUAGES
         *******************/
        preferredLanguage,
        currentLang,
        setLanguage,

        /********************
         * MY SETTINGS
         *******************/
        requestedSettingsMenu,
        setRequestedSettingsMenu,

        /********************
         * GLOBALS LIKES
         *******************/
        getLiked,
    }
})
