import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { ref } from 'vue'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'

export const useMembersStore = defineStore('members', () => {
    const errors = ref([])

    /**
     * Set errors
     * @param _errors
     */
    function setErrors(_errors = []) {
        errors.value = [..._errors]
    }

    /**
     * Get all members / specific one
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function list(id = '', query = {}) {
        // init params
        let queryFilter = ''

        // Query param builder
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }

        // Get data
        return ApiService.get('users/management' + queryFilter, id)
            .then((result) => {
                return result.data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Add a member
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function add(values, role = null, id = null) {
        return ApiService.post('users/management', values)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a member
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function update(values, id) {
        return ApiService.update('users/management', id, values)
            .then((data) => {
                return data
                // return updateRole(values, id)
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Update a role member
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function updateRole(role, id) {
        return ApiService.update('users/management', id + '/roles', role)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Ban a member
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function disable(values) {
        return ApiService.update(
            'users/management',
            values.id + '/disable',
            values
        )
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Unban a member
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function enable(id) {
        return ApiService.update('users/management', id + '/enable')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Convert a user to creator
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function convertToCreator(_userID) {
        let params = { user_id: _userID }
        return ApiService.post(`users/${_userID}/create-creator`, params)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Attach a creator to the account manger
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function attachCreator(creatorId, data) {
        return ApiService.post('creator/' + creatorId + '/manager', data)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Detach a creator to the account manger
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function detachCreator(creatorId, managerId) {
        return ApiService.delete('creator/' + creatorId + '/manager/' + managerId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        errors,
        list,
        add,
        update,
        disable,
        enable,
        convertToCreator,
        attachCreator,
        detachCreator,
        setErrors,
    }
})
