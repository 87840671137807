import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useLoadingStore = defineStore('loading', () => {
    const loading = ref(false)

    const loginOut = ref(false)

    const loaderVisible = ref(false)

    /**
     * Set loading state
     *
     * @param status Boolean
     * @param withLoader Boolean
     */
    function setLoading(status, withLoader = false) {
        loaderVisible.value = withLoader
        loading.value = status
    }

    function setLoginOut(status) {
        loginOut.value = status
    }

    return {
        loading,
        setLoading,
        loginOut,
        setLoginOut,

        loaderVisible
    }
})