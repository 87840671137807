<template>
    <div class="row">
        <div class="col-12">
            <Form
                class="form-auto-spacer"
                :validation-schema="validationSchema"
                @submit="submit"
                @invalid-submit="onInvalidSubmit"
            >

                <div id="accordion-parent" class="accordion">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <StarTitle tag="h5">
                                    1 • {{ $t('workout.exercise.form.create.subTitle.information') }}
                                </StarTitle>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordion-parent">
                            <div class="accordion-body">
                                <div class="row">
                                    <ul class="nav nav-tabs mb-4">
                                        <li
                                            v-for="(lang, keyLang) in settingsStore.availableLanguages"
                                            :key="keyLang"
                                            class="nav-item"
                                        >
                                            <a
                                                class="d-flex justify-content-center align-items-center"
                                                :class="navActiveByLang(lang.code)"
                                                href="#"
                                                @click.prevent="switchLang(lang.code)"
                                            >

                                                <div class="p-0 m-0">Version ({{lang.code.toUpperCase()}})</div>
                                                <FlagIcon :country="(lang.code === 'en') ? 'gb' : lang.code" class="m-0 me-1 p-0" />
                                                <!-- {{ $t('globals.edit') + ' ' + lang.name }}-->
                                            </a>
                                        </li>
                                    </ul>
                                    <RequiredInputGuide />
                                    <div class="col-12">
                                        <div class="col-12 col-lg-6 mb-3">
                                            <BaseSelect
                                                id="visibility"
                                                v-model="formData.visibility.visible"
                                                name="visibility"
                                                :model-value="formData.visibility.visible"
                                                :label="$t('workout.exercise.form.create.visibility.label')"
                                                :options="visibilitites"
                                                required
                                            />
                                        </div>
                                        <BaseInput
                                            id="title"
                                            v-model="formData.title[currentLocale]"
                                            type="text"
                                            name="title"
                                            :data-info="`collapseOne`"
                                            class="mb-4"
                                            :model-value="formData.title[currentLocale]"
                                            :label="
                                                $t('workout.exercise.form.create._title.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                        />
                                        <BaseInput
                                            id="description"
                                            v-model="formData.description[currentLocale]"
                                            type="textarea"
                                            name="description"
                                            :data-info="`collapseOne`"
                                            class="mb-4"
                                            :rows="7"
                                            :model-value="formData.description[currentLocale]"
                                            :label="
                                                $t('workout.exercise.form.create.description.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                            :max="255"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="cover"
                                            role="button"
                                            name="cover"
                                            type="file"
                                            :model-value="coverTmp"
                                            :label="$t('workout.exercise.form.create.cover.label')"
                                            :placeholder="$t('workout.exercise.form.create.cover.idleLabel')"
                                            required
                                            @update:cover="handleChangeCoverImage"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div v-if="coverImage" class="form-group">
                                            <label class="form-label">
                                                {{ $t('workout.exercise.form.create.cover.current') }}
                                            </label>
                                            <br />
                                            <img class="img-fluid cover-image" :src="coverImage" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-12">
                                        <BaseInput
                                            id="calories_spend_in_kcals"
                                            v-model="formData.calories_spend_in_kcals"
                                            name="calories_spend_in_kcals"
                                            type="number"
                                            class="mb-4"
                                            :model-value="formData.calories_spend_in_kcals"
                                            :label="$t('workout.exercise.form.create.caloriesSpendInKcals.label')"
                                        />
                                    </div>
                                    <!-- <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="fat_burning_in_grams"
                                            v-model="formData.fat_burning_in_grams"
                                            name="fat_burning_in_grams"
                                            type="number"
                                            class="mb-4"
                                            :model-value="formData.fat_burning_in_grams"
                                            :label="$t('form.workoutEpisode.fields.fatBurningInGrams.label')"
                                        />
                                    </div> -->
                                </div>
                                <div class="col-12 text-end">
                                    <span
                                        class="btn btn-lg btn-dark cursor-pointer"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="true"
                                        aria-controls="collapseTwo"
                                        @click="nextStep"
                                    >
                                        {{ $t('globals.next') }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <StarTitle tag="h5"> 2 • {{ $t('workout.exercise.form.create.subTitle.content') }} </StarTitle>
                            <hr class="my-2" />
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordion-parent">
                            <div class="accordion-body">
                                <RequiredInputGuide />
                                <div class="row">
                                    <div class="col-12 col-lg-12">
                                        <BaseInput
                                            id="repetition"
                                            v-model="formData.repetition"
                                            name="repetition"
                                            :data-info="`collapseTwo`"
                                            type="number"
                                            class="mb-4"
                                            required
                                            :model-value="formData.repetition"
                                            :label="$t('workout.exercise.form.create.repetition.label')"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="set"
                                            v-model="formData.set"
                                            name="set"
                                            :data-info="`collapseTwo`"
                                            type="number"
                                            class="mb-4"
                                            required
                                            :model-value="formData.set"
                                            :label="$t('workout.exercise.form.create.set.label')"
                                        />
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="resting_repeat_time"
                                            v-model="formData.resting_repeat_time"
                                            name="resting_repeat_time"
                                            type="number"
                                            class="mb-4"
                                            :model-value="formData.resting_repeat_time"
                                            :label="$t('workout.exercise.form.create.restingRepeatTime.label')"
                                        />
                                    </div>
                                    <!-- <div class="col-12 col-lg-6">
                                        <BaseInput
                                            id="resting_episode_time"
                                            v-model="formData.resting_episode_time"
                                            name="resting_episode_time"
                                            type="number"
                                            class="mb-4"
                                            :model-value="formData.resting_episode_time"
                                            :label="$t('form.workoutEpisode.fields.restingEpisodeTime.label')"
                                        />
                                    </div> -->

                                    <div class="col-12">
                                        <BaseTag
                                            id="required_equipments"
                                            v-model="formData.required_equipments"
                                            name="required_equipments"
                                            :label="$t('workout.exercise.form.create.requiredEquipments.label')"
                                            :form-title="$t('workout.exercise.form.create.requiredEquipments.add')"
                                            :button-text="$t('workout.exercise.form.create.requiredEquipments.add')"
                                            :list="formData.required_equipments"
                                            :resource-params="tagTypeFilters['required-equipments']"
                                            :language="currentLocale"
                                            @update:tags="handleChangeTags"
                                        />
                                    </div>

            <!--                        <div class="col-12 col-lg-6">-->
            <!--                            <BaseInput-->
            <!--                                id="episode_number"-->
            <!--                                v-model="formData.episode_number"-->
            <!--                                name="episode_number"-->
            <!--                                type="number"-->
            <!--                                class="mb-4"-->
            <!--                                :model-value="formData.episode_number"-->
            <!--                                :label="$t('workout.exercise.form.create.exerciseNumber.label')"-->
            <!--                                required-->
            <!--                            />-->
            <!--                        </div>-->
                                    <div class="col-12 col-lg-6">
                                        <BaseSelect
                                            id="type"
                                            v-model="formData.type"
                                            name="type"
                                            :data-info="`collapseTwo`"
                                            :model-value="formData.type"
                                            :label="$t('workout.exercise.form.create.type.label')"
                                            :options="workoutEpisodeTypes"
                                            required
                                        />
                                    </div>
                                    <!--                        <div class="col-12 col-lg-6">-->
                                    <!--                            <BaseInput-->
                                    <!--                                id="days_before_release"-->
                                    <!--                                v-model="formData.days_before_release"-->
                                    <!--                                name="days_before_release"-->
                                    <!--                                type="number"-->
                                    <!--                                :model-value="formData.days_before_release"-->
                                    <!--                                :label="$t('form.workoutEpisode.fields.daysBeforeRelease.label')"-->
                                    <!--                            />-->
                                    <!--                        </div>-->
                                </div>
                                <!-- <div class="row mt-4">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <BaseCheckbox
                                                id="is_paid"
                                                v-model="formData.is_paid"
                                                name="is_paid"
                                                :model-value="formData.is_paid"
                                                :label="$t('form.workoutEpisode.fields.isPaid.label')"
                                                :inline="true"
                                            />
                                            <BaseInput
                                                v-if="formData.is_paid"
                                                id="price"
                                                v-model="formData.price"
                                                type="number"
                                                name="price"
                                                :model-value="formData.price"
                                                :label="$t('form.workoutEpisode.fields.price.label')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <BaseCheckbox
                                                id="is_pinned"
                                                v-model="formData.is_pinned"
                                                name="is_pinned"
                                                :model-value="formData.is_pinned"
                                                :label="$t('form.workoutEpisode.fields.isPinned.label')"
                                            />
                                        </div>
                                    </div>
                            </div> -->
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="nav nav-tabs mt-3 mb-4">
                                            <li
                                                v-for="(lang, keyLang) in settingsStore.availableLanguages"
                                                :key="keyLang"
                                                class="nav-item"
                                            >
                                                <a
                                                    class="d-flex justify-content-center align-items-center"
                                                    :class="navActiveByLang(lang.code)"
                                                    href="#"
                                                    @click.prevent="switchLang(lang.code)"
                                                >

                                                    <div class="p-0 m-0">Version ({{lang.code.toUpperCase()}})</div>
                                                    <FlagIcon :country="(lang.code === 'en') ? 'gb' : lang.code" class="m-0 me-1 p-0" />
                                                    <!-- {{ $t('globals.edit') + ' ' + lang.name }} -->
                                                </a>
                                            </li>
                                        </ul>
                                        <BaseInput
                                            v-if="formData.type != `html-content`"
                                            id="content"
                                            v-model="formData.content[currentLocale]"
                                            type="textarea"
                                            name="content"
                                            :data-info="`collapseTwo`"
                                            class="mb-4"
                                            :rows="7"
                                            :model-value="formData.content[currentLocale]"
                                            :label="
                                                $t('workout.exercise.form.create.content.label') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                            :max="255"
                                        />
                                        <BaseTextEditor
                                            v-else
                                            id="content"
                                            v-model="formData.content[currentLocale]"
                                            name="content"
                                            class="mb-4"
                                            :model-value="formData.content[currentLocale]"
                                            :label="
                                                $t('workout.exercise.form.create.content.htmlLabel') +
                                                ` (${currentLocale.toUpperCase()})`
                                            "
                                            required
                                            @update:content="handleUpdateContent"
                                        />
                                    </div>
                                    <div class="col-12 row">
                                        <div v-if="formData.type === 'video'" class="col-12 col-lg-6">
                                            <Dashboard
                                                :uppy="uppyVideo"
                                                :props="{
                                                    doneButtonHandler: null,
                                                }"
                                            />
                                        </div>
                                        <div v-if="item && item.video_url !== null" class="col-12 col-lg-6">
                                            <label class="form-label form-label-boolean-check">
                                                {{ $t('workout.exercise.form.create.file.current') }}
                                            </label>
                                            <div class="form-group preview">
                                                <PostVideoMedia
                                                    v-if="
                                                        item.video_url !== '' &&
                                                        item.video_url !== 'processing' &&
                                                        item.video_url !== 'pending' &&
                                                        item.video_url !== 'failed'
                                                    "
                                                    :id="`workout-form-episode-${item.id?? 'create'}`"
                                                    :key="`workout-form-episode-${item.id?? 'create'}-viewer'}`"
                                                    is-view
                                                    :video="item.video_url"
                                                    checked-visibility="normal"
                                                />
                                                <div v-else-if="item.video_url !== ''">
                                                    {{ $t(`globals.global.videoStatus.${item.video_url}`) }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 text-end">
                                    <span
                                        class="btn btn-lg btn-gray cursor-pointer me-2"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                    >
                                        {{ $t('globals.previous') }}
                                    </span>
                                    <PrimaryButton v-if="!item" :loading="!submitButton.active">
                                        {{ props.submitButtonText }}
                                    </PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="item" class="col-12 text-end mt-3">
                    <PrimaryButton :loading="!submitButton.active">
                        {{ props.submitButtonText }}
                    </PrimaryButton>
                </div>

            </Form>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, onBeforeMount, ref, toRaw, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import router from '@/router'
import i18n from '@/translation'
import set from 'lodash.set'

import { Form } from 'vee-validate'
import { boolean, number, object, string } from 'yup'

import { useWorkoutEpisodeStore } from '@/stores/workoutEpisode'
import { useGlobalSettingsStore } from '@/stores/globalSettings'
import { useLoadingStore } from '@/stores/frontend/loading'
// import { useDraftStore } from '@/stores/DraftManager/draftStore'

import NotificationService from '@/services/NotificationService'
import ApiService from '@/services/ApiService'
import UppyService from '@/services/UppyService'

import { Dashboard } from '@uppy/vue'
import FlagIcon from 'vue-country-flag-next'
import PostVideoMedia from "@/views/Common/Posts/Media/PostVideoMedia.vue";
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'
import BaseSelect from '@/components/Form/BaseSelect.vue'
import BaseInput from '@/components/Form/BaseInput.vue'
import BaseCheckbox from '@/components/Form/BaseCheckbox.vue'
import BaseTextEditor from '@/components/Form/BaseTextEditor.vue'
import BaseTag from '@/components/Form/BaseTag.vue'
import StarTitle from '@/components/Text/Title.vue'
import VideoUploader from '@/components/VideoUploader.vue'
import VideojsPlayer from '@/components/VideojsPlayer.vue'
import RequiredInputGuide from '@/components/Text/RequiredInputGuide.vue'

/** Locale **/
const { t } = useI18n()
let currentLocale = ref(i18n.global.locale.value)

/** Stores **/
const route = useRoute()
const settingsStore = useGlobalSettingsStore()
// const draftStore = useDraftStore()
const loadingStore = useLoadingStore()

/** Props **/
const props = defineProps({
    submitButtonText: {
        type: String,
        required: true,
    },
    item: {
        type: Object,
        default: null,
    },
})

/** Init parameters */
const enums = ref({
    type: [],
})
// const draftOptions = ref({
//     type: 'exercices',
//     uuid: route.params.uuid ?? null,
// })
const uppy = UppyService.init('episodes', {}, false)
const uppyVideo = UppyService.init('video-upload', {
    maxNumberOfFiles: 1,
    allowedFileTypes: ['video/*'],
}, false)
const workoutEpisodeTypes = computed(() => {
    return {
        // audio:"Audio",
        // document: "PDF Document",
        video: 'Video',
        // 'html-content': "HTML Content",
    }
    return enums.value.type
})
const allowedFileTypes = computed(() => {
    switch (formData.value.type) {
        case 'document':
            return 'application/pdf'
    }
})
let formData = ref({
    id: null,
    series_id: route.params.workoutId,
    status: 'active',
    type: 'video',
    is_pinned: 0,
    is_paid: 0,
    episode_number: null,
    days_before_release: 0,
    price: 0,
    rejected_reason: null,
    title: {},
    description: {},
    content: {},
    cover: null,
    media: null,
    file: null,
    calories_spend_in_kcals: null,
    fat_burning_in_grams: 0,
    repetition: null,
    set: null,
    resting_repeat_time: null,
    resting_episode_time: 0,
    required_equipments: [],
    visibility: {
        visible: 'subscriber',
        price: null,
    },
})
let coverImage = ref(null)
let files = ref({})
let visibilitites = ref({
    free: t('workout.exercise.form.create.visibility.everyone'),
    subscriber: t('workout.exercise.form.create.visibility.subscriber'),
})
const tagTypeFilters = ref({
    'required-equipments': {
        type: 'required-equipments',
    },
})
const navActiveByLang = (locale) => {
    return 'nav-link' + (locale === currentLocale.value ? ' active' : '')
}
const switchLang = (locale) => {
    currentLocale.value = locale
}
let coverTmp = ref(null)
let fileTmp = ref(null)
let submitButton = ref({
    active: true,
})
let query = ref({
    filter: {},
    sort: ['-episode_number'],
    page: 1,
    per_page: 1,
})
const videoOptions = ref({
    autoplay: false,
    preload: true,
    // fluid: true,
    // withCredentials: true,
    html5: {
        vhs: {
            // withCredentials: true
        },
    },
    sources: [],
})

/** Validation */
const validationSchema = object({
    title: string().required(t('globals.validations.required')),
    description: string().required(t('globals.validations.required')).max(255),
    content: string().required(t('globals.validations.required')).max(255),
    type: string()
        .required()
        .label(t('workout.exercise.form.create.type.label'))
        .typeError(t('globals.validations.required')),
    is_paid: boolean().optional().label(t('workout.exercise.form.create.isPaid.label')),
    is_pinned: boolean().optional().label(t('workout.exercise.form.create.isPinned.label')),
    price: number()
        .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value
        })
        .when('is_paid', {
            is: true,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.nullable(),
        })
        .typeError(t('globals.validations.number')),
    // episode_number: number()
    //     .required()
    //     .label(t('workout.exercise.form.create.exerciseNumber.label'))
    //     .typeError(t('globals.validations.number')),
    days_before_release: number()
        .nullable()
        .label(t('workout.exercise.form.create.daysBeforeRelease.label'))
        .typeError(t('globals.validations.number')),
    rejected_reason: string().nullable().label(t('workout.exercise.form.create.rejectedReason.label')),
    calories_spend_in_kcals: number()
        .nullable()
        .integer()
        .label(t('workout.exercise.form.create.caloriesSpendInKcals.label'))
        .typeError(t('globals.validations.number')),
    // fat_burning_in_grams: number()
    //     .nullable()
    //     .integer()
    //     .label(t('form.workoutEpisode.fields.fatBurningInGrams.label'))
    //     .typeError(t('globals.validations.number')),
    repetition: number()
        .required()
        .integer()
        .label(t('workout.exercise.form.create.repetition.label'))
        .typeError(t('globals.validations.number')),
    set: number()
        .required()
        .integer()
        .label(t('workout.exercise.form.create.set.label'))
        .typeError(t('globals.validations.number')),
    resting_repeat_time: number()
        .nullable()
        .integer()
        .label(t('workout.exercise.form.create.restingRepeatTime.label'))
        .typeError(t('globals.validations.number')),
    // resting_episode_time: number()
    //     .nullable()
    //     .integer()
    //     .label(t('form.workoutEpisode.fields.restingEpisodeTime.label'))
    //     .typeError(t('globals.validations.number')),
    required_equipments: object().nullable().label(t('workout.exercise.form.create.requiredEquipments.label')),
})

/** Methods */
function nextStep() {
    // draftStore.updateDraft(draftOptions.value.type, draftOptions.value.uuid, formData.value)
}

function onInvalidSubmit({ values, errors, results }) {

    if (errors && Object.keys(errors)[0]) {
        let lastSection = document.getElementById("collapseTwo")
        let element = document.getElementById(Object.keys(errors)[0])
        let parent = document.getElementById(element.getAttribute('data-info'))

        if ((parent !== undefined || parent !== null) && element !== undefined) {
            lastSection.classList.remove("show")
            parent.classList.add("show")
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
    // console.log(values) // current form values
    // console.log(errors) // a map of field names and their first error message
    // console.log(results) // a detailed map of field names and their validation results
}

function handleChangeTags(values) {
    set(formData.value, values.type, values.tags)
}

function handleUpdateContent(content) {
    formData.value.content[currentLocale.value] = content
}

function handleChangeCoverImage(file) {
    formData.value.cover = file
    if (file !== null) {
        coverTmp.value = [
            {
                source: file,
                options: {
                    type: 'input',
                },
            },
        ]
    }
}

function handleChangeFile(file) {
    formData.value.file = file
    if (file !== null) {
        // Enable submit button
        submitButton.value.active = true
        fileTmp.value = [
            {
                source: file,
                options: {
                    type: 'input',
                },
            },
        ]
    }
}

function handleProcessing(_isProcessing = false) {
    submitButton.value.active = !!_isProcessing
}

function submit() {
    handleProcessing()
    if (formData.value.cover !== null) {
        uppy.addFile(formData.value.cover)
        uppy.upload().then((result) => {
            if (result.failed.length <= 0) {
                result.successful.forEach((item) => {
                    const fileUploaded = {
                        path: item?.s3Multipart?.key,
                        name: item?.name,
                        type: item?.type,
                        size: item?.size,
                    }
                    if (item.type.match('video/*')) {
                        formData.value.file = fileUploaded
                    } else {
                        formData.value.cover = fileUploaded
                    }
                })
            }
            updateOrCreateWorkout(formData)
        })
    } else {
        updateOrCreateWorkout(formData)
    }
}

function updateOrCreateWorkout(formData) {
    if (formData.value.id != null) {
        loadingStore.setLoading(true)
        useWorkoutEpisodeStore()
            .update(formData.value)
            .then(() => {
                return router.push({
                    name: 'kol-admin-view-workout-episodes',
                    params: {
                        workoutId: route.params.workoutId,
                    },
                })
            })
            .then(() => {
                NotificationService.notifyUserSuccess(t('workout.exercise.form.edit.message.success'))
                submitButton.value.active = true
            })
            .catch((error) => {
                // NotificationService.notifyUserError(error)
                submitButton.value.active = true
            })
    } else {
        loadingStore.setLoading(true)
        useWorkoutEpisodeStore()
            .create(formData.value)
            .then(() => {
                return router.push({
                    name: 'kol-admin-view-workout-episodes',
                    params: {
                        workoutId: route.params.workoutId,
                    },
                })
            })
            .then(() => {
                // Need to remove a draft ?
                // if (draftOptions.value.uuid !== null) {
                //     draftStore.deleteDraft('exercices', draftOptions.value.uuid)
                // }

                NotificationService.notifyUserSuccess(t('workout.exercise.form.create.message.success'))
                submitButton.value.active = true
            })
            .catch((error) => {
                submitButton.value.active = true
                // NotificationService.notifyUserError(error)
            })
    }
}

function lastEpisodeNumber() {
    useWorkoutEpisodeStore()
        .list(route.params.workoutId, query.value)
        .then((result) => {
            if (formData.value.id === null) {
                formData.value.episode_number = result.data[0] !== undefined ? result.data[0].episode_number + 1 : 1
            }
        })
        .catch(() => {})
}

/** Lifecycles */
watch(
    () => props.item,
    (item) => {
        coverImage.value = item.cover
        files.value = item.files

        formData.value = toRaw(item)
        formData.value.cover = null
        formData.value.series_id = route.params.workoutId

        if (Array.isArray(formData.value.description)) {
            formData.value.description = {}
        }

        if (Array.isArray(formData.value.content)) {
            formData.value.content = {}
        }

        videoOptions.value.sources.push({
            src: item.video_url,
            type: 'application/x-mpegURL',
            // withCredentials: true
        })
    }
)

onBeforeMount(() => {
    ;['type'].map((type) => {
        ApiService.get(`enum/series-episode-type`).then(({ data }) => {
            enums.value[type] = data
        })
    })

    // Get the next episode number
    lastEpisodeNumber()

    // if ('serviceWorker' in navigator) {
    //     UppyService.registerServiceWorker().then(() => UppyService.retryUpload(uppyVideo))

        uppyVideo.once('upload-progress', () => {
            handleProcessing()
        })
        uppyVideo.once('upload-success', () => {
            submitButton.value.active = true
        })
        uppyVideo.on('complete', (result) => {
            if (result.failed.length <= 0) {
                result.successful.forEach((item) => {
                    const fileUploaded = {
                        path: item?.s3Multipart?.key,
                        name: item?.name,
                        type: item?.type,
                        size: item?.size,
                    }
                    if (item.type.match('video/*')) {
                        formData.value.file = fileUploaded
                    } else {
                        formData.value.cover = fileUploaded
                    }
                })
            }
        })
    // }
})

// onMounted(() => {
//     if (draftOptions.value.uuid === null) {
//         console.log("new draft")
//         draftStore
//             .startNewDraft(draftOptions.value.type, formData.value)
//             .then((response) => {
//                 draftOptions.value.uuid = response.uuid
//             })
//     }
//     else {
//         console.log("exist draft")
//         draftStore
//             .getOneDraft(draftOptions.value.type, draftOptions.value.uuid)
//             .then((response) => {
//                 formData.value = {
//                     ...response
//                 }
//             })
//     }
// })
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style src="@uppy/drag-drop/dist/style.css"></style>
<style src="@uppy/progress-bar/dist/style.css"></style>

<style scoped>
.cover-image {
    max-height: 250px;
}
.preview .video-js,
.preview video.vjs-tech {
    max-height: 400px !important;
}
</style>

<style>
.uppy-Dashboard-AddFiles-info {
    display: none !important;
}
</style>