<template>
    <div
        v-if="props.loading && useLoadingStore().loaderVisible"
        class="loading d-flex flex-column justify-content-center align-items-center"
    >
        <div
            class="spinner-grow text-white"
            role="status"
            style="width: 5rem; height: 5rem"
        >
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script setup>
import {useLoadingStore} from "@/stores/frontend/loading";

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
})
</script>

<style scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(20, 20, 20, 0.5);
}
</style>