<template>
    <div v-if="contentShown" class="mt-1 mb-1" style="word-break: break-all;">
        <p
            v-for="(line, lineNumber) of lines"
            :key="lineNumber" class="fs-6 mb-1"
            :style="`${line.length === 0 ? 'line-height:0px;margin:.3em !important' : 'line-height:1.2'}`"
        >
            <template
                v-for="(lineItem, lineToken) of line"
                :key="lineToken"
            >
                <template v-if="lineItem.type === 'text'">
                    {{lineItem.content}}
                </template>
                <template v-if="lineItem.type === 'empty' && (line.length === 1 || (line.length > 1 && lineToken > 0))">
                    &nbsp;
                </template>

                <template v-if="lineItem.type === 'mention'">
                    <PostCreatorMention is-link :slug="lineItem.content" :creator="lineItem.mention" />
                </template>

<!--                {{lineItem}}-->
            </template>
<!--            <template v-if="line.type === 'text'">-->
<!--                <p-->
<!--                    v-for="(line, lineNumber) of token.content.split(/\r?\n/)"-->
<!--                    :key="lineNumber" class="fs-6 mb-1"-->
<!--                    :style="`${line.length === 0 ? 'line-height:0px;margin:.3em !important' : 'line-height:1.2'}`"-->
<!--                >-->
<!--                    &lt;!&ndash; <span v-html="urlify(line) + '&nbsp;'" /> &ndash;&gt;-->
<!--                    {{ line }}&nbsp;-->
<!--                </p>-->
<!--            </template>-->
<!--            <template v-else>-->

<!--                LINk_{{token.content}}-->
<!--            </template>-->

        </p>

<!--        <p-->
<!--            v-for="(line, lineNumber) of contentShown.split(/\r?\n/)"-->
<!--            :key="lineNumber" class="fs-6 mb-1"-->
<!--            :style="`${line.length === 0 ? 'line-height:0px;margin:.3em !important' : 'line-height:1.2'}`"-->
<!--        >-->
<!--            &lt;!&ndash; <span v-html="urlify(line) + '&nbsp;'" /> &ndash;&gt;-->
<!--            {{ line }}&nbsp;-->
<!--        </p>-->
<!--        <a v-if="hasShowMoreButton" href="javascript:void(0);" class="link-info fs-7" @click="toggleShowMore">-->
<!--            {{ t(`creator.post.readMore.${fullContentShown}`) }}-->
<!--        </a>-->
    </div>
</template>


<script setup>
import {computed, onBeforeMount, onMounted, ref, toRaw} from "vue";
import { useI18n } from 'vue-i18n'
import PostCreatorMention from "@/views/Common/Posts/PostCreatorMention.vue";

const { t } = useI18n()
const lengthReadMore = 400
const props = defineProps({
    text: {
        type: String,
        default: ""
    },
    mentions: {
        type: Array,
        default: () => []
    }
})

const fullContentShown = ref(false)
const hasShowMoreButton = computed(() => {
    if (props.text) {
        return props.text.length > lengthReadMore
    }
    return false
})
const contentShown = computed(() => {
    let text = toRaw(props.text)

    if (!hasShowMoreButton.value) {
        return text
    }

    return fullContentShown.value
        ? text
        : text.slice(0, lengthReadMore) + ' ...'
})
const lines = ref([])
const mentions = computed(() => {
    return props.mentions
    .filter((mention) => mention.link_type === 'creator')
    .map((mention) => {
        return {
            ...mention,
            slug: '@'+JSON.parse(mention.content)?.slug,
        }
    })
})

onMounted(() => {
    let lineParser = props.text?.split(/\r?\n/)

    lines.value = lineParser?.map((line) => {
        let mention = null
        let tokenParser = line.split(/\B(@[\w-]+)/gmi)
        return tokenParser.map((token) => {
            let type = 'text'
            if (mentions.value.some((mention) => mention.slug === token)) {
                mention = mentions.value.find((mention) => mention.slug === token)

                mention = JSON.parse(mention.content)
                type = 'mention'
            }

            if (token === ''){
                type = 'empty'
            }
            return {
                content: type === 'mention' ? token.replace('@', '') : token,
                type,
                mention
            }
        })
    })
})

// function urlify(text) {
//     var urlRegex = /(https?:\/\/[^\s]+)/g;
//     return text.replace(urlRegex, function(url) {
//         return '<a href="' + url + '" class="text-gray-500 text-decoration-none" target="_blank">' + url + '</a>';
//     })
// }

// Post read more
function toggleShowMore() {
    fullContentShown.value = !fullContentShown.value
}
</script>

<style scoped>

</style>
