const TOKEN_KEY = 'JWTOKEN_KEY'

class JwtService {
    static getToken() {
        return window.localStorage.getItem(TOKEN_KEY)
    }

    static setToken(_token = '') {
        window.localStorage.setItem(TOKEN_KEY, _token)
    }

    static destroyToken(_token = '') {
        window.localStorage.removeItem(TOKEN_KEY)
    }
}

export default JwtService
