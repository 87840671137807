import i18n from '@/translation'

const { t } = i18n.global

const KolAdminMenuConfig = [
    {
        heading: t('creator.menu.dashboard'),
        routeName: 'kol-admin-dashboard',
        fontIcon: 'fa-solid fa-house me-3',
        image: 'menu-dashboard.svg',
    },
    {
        heading: t('creator.menu.myPage'),
        routeName: 'kol-admin-my-page',
        fontIcon: 'fa-solid fa-table-columns me-3',
        image: 'menu-mypage.svg',
    },
    {
        heading: t('creator.menu.mySettings'),
        routeName: 'kol-admin-my-settings',
        fontIcon: 'fa-solid fa-cog me-3',
        image: 'menu-settings.svg',
    },
    {
        heading: t('creator.menu.myWorkouts'),
        routeName: 'kol-admin-my-workouts',
        fontIcon: 'fa-solid fa-chalkboard-teacher me-3',
        image: 'menu-workouts.svg',
        module: 'workouts'
    },
    // Masterclass
    {
        heading: t('creator.menu.masterclasses'),
        routeName: 'kol-admin-my-masterclass',
        fontIcon: 'fa-solid fa-star me-3',
        image: 'menu-masterclass.svg',
        imageLight: 'menu-masterclass-white.svg',
        module: 'masterclass'
    },
    // PODCASTS
    {
        heading: t('creator.menu.podcasts'),
        routeName: 'kol-admin-my-podcasts',
        fontIcon: 'fa-solid fa-podcast me-3 fs-5',
        module: 'podcasts'
    },
    {
        heading: t('creator.menu.medias'),
        routeName: 'kol-admin-medias-management',
        fontIcon: 'fa-solid fa-photo-video me-3',
        image: 'menu-medias.svg',
        module: 'workouts'
    },
    {
        heading: t('user.menu.myCalendar'),
        routeName: 'kol-admin-my-calendar',
        fontIcon: 'fa-solid fa-calendar-days me-3',
        image: 'menu-calendar.svg',
        noIkonstar: true
    },
    {
        // message
        heading: t('creator.menu.messages'),
        routeName: 'kol-admin-messages',
        fontIcon: 'fa-solid fa-envelope me-3',
        image: 'menu-messenger.svg',
        noIkonstar: true
    },
    {
        heading: t('creator.menu.gallery'),
        routeName: 'kol-my-gallery',
        fontIcon: 'fa-solid fa-image me-3',
        image: 'menu-gallery.svg',
        module: 'gallery'
    },
    {
        heading: t('creator.menu.recipes'),
        // routeName: 'kol-admin-my-recipes',
        routeName: 'kol-admin-my-private-recipes',
        image: 'menu-recipes.svg',
        fontIcon: 'fa-solid fa-table-list me-3',
        module: 'recipes'
    },
    // {
    //   heading: t('creator.menu.myPlans'),
    //   routeName: "kol-admin-my-plan-prices",
    //   fontIcon: "fa-solid fa-pencil me-3",
    // },
    // {
    //   heading: t('admin.menu.earlyAccesses'),
    //   routeName: "admin-early-accesses",
    //   fontIcon: "fa-solid fa-house",
    // },
    // {
    //   heading: "craft",
    //   route: "/crafted",
    //   pages: [
    //     {
    //       sectionTitle: "pages",
    //       route: "/pages",
    //       svgIcon: "/media/icons/duotune/general/gen022.svg",
    //       fontIcon: "bi-archive",
    //       sub: [
    //         {
    //           sectionTitle: "profile",
    //           route: "/profile",
    //           sub: [
    //             {
    //               heading: "profileOverview",
    //               route: "/crafted/pages/profile/overview",
    //             },
    //             {
    //               heading: "projects",
    //               route: "/crafted/pages/profile/projects",
    //             },
    //             {
    //               heading: "campaigns",
    //               route: "/crafted/pages/profile/campaigns",
    //             },
    //             {
    //               heading: "documents",
    //               route: "/crafted/pages/profile/documents",
    //             },
    //             {
    //               heading: "connections",
    //               route: "/crafted/pages/profile/connections",
    //             },
    //             {
    //               heading: "activity",
    //               route: "/crafted/pages/profile/activity",
    //             },
    //           ],
    //         },
    //         {
    //           sectionTitle: "wizards",
    //           route: "/wizard",
    //           sub: [
    //             {
    //               heading: "horizontal",
    //               route: "/crafted/pages/wizards/horizontal",
    //             },
    //             {
    //               heading: "vertical",
    //               route: "/crafted/pages/wizards/vertical",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       sectionTitle: "account",
    //       route: "/account",
    //       svgIcon: "/media/icons/duotune/communication/com006.svg",
    //       fontIcon: "bi-person",
    //       sub: [
    //         {
    //           heading: "accountOverview",
    //           route: "/crafted/account/overview",
    //         },
    //         {
    //           heading: "settings",
    //           route: "/crafted/account/settings",
    //         },
    //       ],
    //     },
    //     {
    //       sectionTitle: "authentication",
    //       svgIcon: "/media/icons/duotune/technology/teh004.svg",
    //       fontIcon: "bi-sticky",
    //       sub: [
    //         {
    //           sectionTitle: "basicFlow",
    //           sub: [
    //             {
    //               heading: "signIn",
    //               route: "/sign-in",
    //             },
    //             {
    //               heading: "signUp",
    //               route: "/sign-up",
    //             },
    //             {
    //               heading: "passwordReset",
    //               route: "/password-reset",
    //             },
    //           ],
    //         },
    //         {
    //           heading: "multiStepSignUp",
    //           route: "/multi-step-sign-up",
    //         },
    //         {
    //           heading: "error404",
    //           route: "/404",
    //         },
    //         {
    //           heading: "error500",
    //           route: "/500",
    //         },
    //       ],
    //     },
    //     {
    //       sectionTitle: "modals",
    //       route: "/modals",
    //       svgIcon: "/media/icons/duotune/art/art009.svg",
    //       fontIcon: "bi-shield-check",
    //       sub: [
    //         {
    //           sectionTitle: "general",
    //           route: "/general",
    //           sub: [
    //             {
    //               heading: "inviteFriends",
    //               route: "/crafted/modals/general/invite-friends",
    //             },
    //             {
    //               heading: "viewUsers",
    //               route: "/crafted/modals/general/view-user",
    //             },
    //             {
    //               heading: "upgradePlan",
    //               route: "/crafted/modals/general/upgrade-plan",
    //             },
    //             {
    //               heading: "shareAndEarn",
    //               route: "/crafted/modals/general/share-and-earn",
    //             },
    //           ],
    //         },
    //         {
    //           sectionTitle: "Form",
    //           route: "/Form",
    //           sub: [
    //             {
    //               heading: "newTarget",
    //               route: "/crafted/modals/Form/new-target",
    //             },
    //             {
    //               heading: "newCard",
    //               route: "/crafted/modals/Form/new-card",
    //             },
    //             {
    //               heading: "newAddress",
    //               route: "/crafted/modals/Form/new-address",
    //             },
    //             {
    //               heading: "createAPIKey",
    //               route: "/crafted/modals/Form/create-api-key",
    //             },
    //           ],
    //         },
    //         {
    //           sectionTitle: "wizards",
    //           route: "/wizards",
    //           sub: [
    //             {
    //               heading: "twoFactorAuth",
    //               route: "/crafted/modals/wizards/two-factor-auth",
    //             },
    //             {
    //               heading: "createApp",
    //               route: "/crafted/modals/wizards/create-app",
    //             },
    //             {
    //               heading: "createAccount",
    //               route: "/crafted/modals/wizards/create-account",
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     {
    //       sectionTitle: "widgets",
    //       route: "/widgets",
    //       svgIcon: "/media/icons/duotune/general/gen025.svg",
    //       fontIcon: "bi-layers",
    //       sub: [
    //         {
    //           heading: "widgetsLists",
    //           route: "/crafted/widgets/lists",
    //         },
    //         {
    //           heading: "widgetsStatistics",
    //           route: "/crafted/widgets/statistics",
    //         },
    //         {
    //           heading: "widgetsCharts",
    //           route: "/crafted/widgets/charts",
    //         },
    //         {
    //           heading: "widgetsMixed",
    //           route: "/crafted/widgets/mixed",
    //         },
    //         {
    //           heading: "widgetsTables",
    //           route: "/crafted/widgets/tables",
    //         },
    //         {
    //           heading: "widgetsFeeds",
    //           route: "/crafted/widgets/feeds",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   heading: "apps",
    //   route: "/apps",
    //   pages: [
    //     {
    //       sectionTitle: "customers",
    //       route: "/customers",
    //       svgIcon: "/media/icons/duotune/finance/fin006.svg",
    //       fontIcon: "bi-printer",
    //       sub: [
    //         {
    //           heading: "gettingStarted",
    //           route: "/apps/customers/getting-started",
    //         },
    //         {
    //           heading: "customersListing",
    //           route: "/apps/customers/customers-listing",
    //         },
    //         {
    //           heading: "customerDetails",
    //           route: "/apps/customers/customer-details",
    //         },
    //       ],
    //     },
    //     {
    //       sectionTitle: "subscriptions",
    //       route: "/subscriptions",
    //       svgIcon: "/media/icons/duotune/ecommerce/ecm002.svg",
    //       fontIcon: "bi-cart",
    //       sub: [
    //         {
    //           heading: "getStarted",
    //           route: "/apps/subscriptions/getting-started",
    //         },
    //         {
    //           heading: "subscriptionList",
    //           route: "/apps/subscriptions/subscription-list",
    //         },
    //         {
    //           heading: "addSubscription",
    //           route: "/apps/subscriptions/add-subscription",
    //         },
    //         {
    //           heading: "viewSubscription",
    //           route: "/apps/subscriptions/view-subscription",
    //         },
    //       ],
    //     },
    //     {
    //       heading: "calendarApp",
    //       route: "/apps/calendar",
    //       svgIcon: "/media/icons/duotune/general/gen014.svg",
    //       fontIcon: "bi-calendar3-event",
    //     },
    //     {
    //       sectionTitle: "chat",
    //       route: "/chat",
    //       svgIcon: "/media/icons/duotune/communication/com012.svg",
    //       fontIcon: "bi-chat-left",
    //       sub: [
    //         {
    //           heading: "privateChat",
    //           route: "/apps/chat/private-chat",
    //         },
    //         {
    //           heading: "groupChat",
    //           route: "/apps/chat/group-chat",
    //         },
    //         {
    //           heading: "drawerChat",
    //           route: "/apps/chat/drawer-chat",
    //         },
    //       ],
    //     },
    //   ],
    // },
]

export default KolAdminMenuConfig