<template>
    <div>
        <div v-click-outside="clearForm" class="position-relative w-100">
            <div class="w-100" :class="inputWrapperClass">
                <input
                    id="search"
                    v-model="query.q"
                    name="search"
                    class="form-control search-input"
                    type="search"
                    :placeholder="$t('home.v2.navbar.search')"
                    :aria-label="$t('home.v2.navbar.search')"
                    autocomplete="off"
                    @keyup="submitSearch"
                >
            </div>
            <div v-if="!loading && searchResult.length" class="position-absolute w-100 px-2 rounded-4" style="top:70px">
                <div class="result-card rounded-4">
                    <template v-for="creator in searchResult" :key="`creator-search-${creator.slug}`" >
                        <router-link
                            v-slot="{ navigate }"
                            :to="{
                                name: 'user-dashboard-with-slug',
                                params: {creatorSlug: creator.slug}
                             }"
                            custom
                        >
                            <div class="card border-0 rounded-0 bg-transparent cursor-pointer" @click="navigate">
                                <div class="card-body d-flex align-items-center">
                                    <!-- AVATAR -->
                                    <div class="flex-grow-0 flex-shrink-0" style="flex-basis: 70px;">
                                        <div class="avatar-cropper" style="max-height: 50px;max-width: 50px">
                                            <img
                                                :src="creator.avatar"
                                                class="avatar-pic"
                                                alt="avatar">
                                        </div>
                                    </div>
                                    <div class="d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center">
                                        <div class="fs-6 fw-medium">
                                            {{creator.username}}
                                        </div>
                                        <div class="fs-7">
                                            <i>@{{creator.slug}}</i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </router-link>
                    </template>

                    <router-link
                        v-slot="{ navigate }"
                        :to="{
                       name: routeName,
                       query: {q: query.q}
                    }"
                        custom
                    >
                        <div class="card border-0 rounded-0 bg-transparent cursor-pointer" @click="navigate">
                            <div class="card-body d-flex align-items-center justify-content-center">
                                {{ $t('globals.search.allResults') }}
                            </div>
                        </div>
                    </router-link>
                </div>



                <!--            <Pagination-->
                <!--                :from="paginationMeta.from"-->
                <!--                :to="paginationMeta.to"-->
                <!--                :total="paginationMeta.total"-->
                <!--                :total-pages="paginationMeta.last_page"-->
                <!--                :per-page="paginationMeta.per_page"-->
                <!--                :current-page="currentPage"-->
                <!--                :items-of="false"-->
                <!--                @page-changed="pageChanged"-->
                <!--                @to-first-page="pageChanged"-->
                <!--                @to-last-page="pageChanged"-->
                <!--            />-->
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useI18n} from 'vue-i18n'
import {useHead} from '@unhead/vue'

import debounce from "lodash.debounce";
import {useCreatorStore} from "@/stores/frontend/creator";

const props = defineProps({
    routeName: {
        type: String,
        default: 'search-creator'
    },
    inputWrapperClass: {
        type: String,
        default: 'px-2'
    }
})


const {t} = useI18n()

// useHead({
//     title: "__LANG_SEARCH",
// })

const query = ref({
    page: 1,
    per_page: 2,
    q: ''
})
const currentPage = ref(1)

const paginationMeta = ref({
    current_page: 1,
    from: 1,
    last_page: 1,
    links: [],
    path: null,
    per_page: 10,
    to: 1,
    total: 1,
})
const loading = ref(false)
const searchResult = ref([])

const submitSearch = debounce((value) => {
    loading.value = true
    // Reset page
    query.value.page = 1
    // Set string to search
    query.value.q = (value.searchString !== undefined) ? value.searchString : query.value.q
    // Refresh list
    searchCreator(null)
}, 500)

/**
 *
 * @param cursor
 * @param selected
 */
const searchCreator = (cursor = null) => {

    loading.value = true
    searchResult.value = []
    query.value.q = query.value.q.trim()

    if (query.value.q !== '' && query.value.q.length > 2) {
        useCreatorStore()
        .search(query.value)
        .then((result) => {
            searchResult.value = [...result.data]
            paginationMeta.value = {
                ...result.meta,
            }
            loading.value = false
        })
    }
    else{
        loading.value = false
    }

}

const clearForm = () => {
    searchResult.value = []
    query.value = {
        page: 1,
        per_page: 2,
        q: ''
    }
}


const pageChanged = (_page) => {
    loading.value = true
    // Set page in query param
    query.value.page = _page
    // Refresh list
    searchCreator(null)
    // Set current page
    currentPage.value = _page
}

</script>

<style scoped lang="scss">

#search-home-user {
    input.search-input {
        background-color: transparent;
        border-radius: 1em;
        width: 100%;

        font-weight: bold;
        transition: all 0.4s ease !important;
        color: white;

        &::placeholder {
            color: white;
        }

        &:focus, &:active {
            background-color: #fff;
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 30px;
            color: black;

            &::placeholder {
                color: black;
            }
            &::-webkit-search-cancel-button{
                -webkit-appearance: none;
                height: 24px;
                width: 24px;
                margin-left: .4em;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
                cursor: pointer;
            }
        }
    }
}

#search-home-user-offcanvas {
    input.search-input {
        background-color: white;
        width: 100%;

        font-weight: bold;
        transition: all 0.4s ease !important;
        color: black;

        &::placeholder {
            color: black;
        }

        &:focus, &:active {
            background-color: #fff;
            border: solid 1px #dee2e6;
            color: black;

            &::placeholder {
                color: black;
            }
            &::-webkit-search-cancel-button{
                -webkit-appearance: none;
                height: 24px;
                width: 24px;
                margin-left: .4em;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
                cursor: pointer;
            }
        }
    }
}
.result-card {
    [data-bs-theme=dark] & {
        background-color: #2d2d2d;
    }
    [data-bs-theme=light] & {
        background-color: #ffffff;
    }
}

#search-home-user-header {

    input.search-input {
        width: 216px;
        background-color: transparent;
        border-radius: .5em;
        //border: 1px solid black;
        transition: width 0.4s ease-in-out;
        //color: black;
        &::placeholder {
            color: white;
        }

        .navbar.scrolled & {
            border: 1px solid black;
            color: black;

            &::placeholder {
                color: black;
            }
        }

        &:focus, &:active {
            width: 300px;
            box-shadow: none;
            transition: width 0.4s ease-in-out;

            &::-webkit-search-cancel-button{
                -webkit-appearance: none;
                height: 24px;
                width: 24px;
                margin-left: .4em;
                background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
                cursor: pointer;
            }
        }
    }

}
</style>
