<template>
    <div class="text-center pb-2">
        <span class="btn btn-dark cursor-pointer" @click.prevent="$emit('click')">
            <i class="fa-solid fa-lock me-2"></i>
            <slot name="text">
                {{ $t(unlockText) }}
            </slot>
        </span>
    </div>
</template>

<script setup>
import {computed} from "vue";
import {useCreatorStore} from "@/stores/frontend/creator";

defineEmits(['click'])

const specificCreator = import.meta.env.VITE_CREATOR_NO_SUB

const unlockText = computed(() => {
    return parseInt(useCreatorStore().creator.id) !== parseInt(specificCreator)
        ? 'creator.post.unlock'
        : 'globals.specialUnlock'
})
</script>

<style scoped>

</style>