<script setup>
import { computed, toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: '',
    },
    successMessage: {
        type: String,
        default: '',
    },
    helper: {
        type: [Boolean, String],
        default: false,
    },
    big: {
        type: Boolean,
        default: false,
    },
    isGrouped: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
})

const ariaDescribedBy = computed(() => {
    return `${props.id}Help`
})

const name = toRef(props, 'name')
const { errorMessage } = useField(name, undefined, {
    initialValue: props.value,
})
</script>

<template>
    <div :class="{ 'form-group': true, 'form-floating': big }">
        <template v-if="label && type !== 'hidden' && !big">
            <div class="d-flex">
                <div class="flex-grow-1">
                    <label class="form-label" :for="id">
                        {{ label }}
                        <span v-if="required" class="text-danger">*</span>
                    </label>
                </div>
                <div>
                    <slot name="right-label" />
                </div>
            </div>
        </template>

        <template v-if="isGrouped">
            <div class="input-group">
                <slot name="prefix" />

                <slot />

                <slot name="postfix" />
            </div>
        </template>

        <template v-else>
            <slot name="prefix" />

            <slot />

            <slot name="postfix" />
        </template>

        <label v-if="label && type !== 'hidden' && big" :for="id">
            {{ label }}
            <span v-if="required" class="text-danger">*</span>
        </label>

        <div class="d-flex text-start">
            <div class="flex-grow-1" :class="{'ms-2': big}">
                <div v-if="helper" :id="ariaDescribedBy" class="form-text">
                    <i class="fs-8">{{ helper }}</i>
                </div>
            </div>
            <slot name="extra-info" />
        </div>
        <div v-if="errorMessage" class="invalid-feedback">
            {{ errorMessage }}
        </div>
    </div>
</template>