import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import advancedFormat from "dayjs/plugin/advancedFormat"

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'

import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'

dayjs.extend(localizedFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

dayjs.locale(localStorage.getItem('user-preferred-lang') ?? 'en')
const moneyFormat = {
    en: 'en-GB',
    fr: 'fr-FR',
    es: 'es-ES'
}
const filters = {
    formatDate(_string, _format = 'L', option = '', timezone = null) {
        dayjs.locale(localStorage.getItem('user-preferred-lang') ?? 'en')

        let date = dayjs(_string)

        if (timezone !== null) {
            date = date.tz(timezone)
        }
        if (option === 'fromNow') {
            return date.fromNow()
        } else {
            return date.format(_format)
        }
    },

    getDiff(_string, timezone = null){
        dayjs.locale(localStorage.getItem('user-preferred-lang') ?? 'en')

        let date = dayjs(_string)

        if (timezone !== null) {
            date = date.tz(timezone)
        }
        return date.diff(dayjs())
    },

    isAfterNow(_string) {
        dayjs.locale(localStorage.getItem('user-preferred-lang') ?? 'en')

        return dayjs(_string).isAfter(dayjs())
    },

    isBeforeNow(_string) {
        dayjs.locale(localStorage.getItem('user-preferred-lang') ?? 'en')

        return dayjs(_string).isBefore(dayjs())
    },

    truncateText(text, length = 50, clamp = '...') {
        return text.length > length ? text.slice(0, length) + clamp : text
    },

    formatDuration(_duration, _from = 'seconds', _format = '') {
        _duration = parseInt(_duration)

        if (_format === '') {
            _format = _duration >= 3600 ? 'HH:mm:ss' : 'mm:ss'
        }
        return dayjs.duration(_duration, _from).format(_format)
    },

    formatDateTimeForInput(_string) {
        return dayjs(_string).local().format('YYYY-MM-DDTHH:mm:ss')
    },

    formatDateTimeFromDateTimeLocal(_string) {
        return dayjs(_string).local()
    },

    formattedPrice(_price, _locale, currency = 'EUR') {
        return new Intl.NumberFormat(moneyFormat[_locale], { style: 'currency', currency }).format((_price))
    },


    guessTimezone() {
        return dayjs.tz.guess()
    },

    capitalized(_string) {
        if (_string && _string.length) {
            const capitalizedFirst = _string[0].toUpperCase()
            const rest = _string.slice(1)
            return capitalizedFirst + rest
        }
    },

    kFormatter(num) {
        return Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
            : Math.sign(num) * Math.abs(num)
    },

    /**
     * checkWebpSupport(function (feature, isSupported) {
     *  if (isSupported) {
     *      // webp is supported
     *  }
     * }, 'lossy');
     * @param feature
     * @param callback
     */
    checkWebpSupport(callback, feature = 'basic') {
        let kTestImages = {
            basic: "UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==",
            lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
            lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
            alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
            animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
        };
        let img = new Image();
        img.onload = function () {
            let result = (img.width > 0) && (img.height > 0);
            callback(feature, result);
        };
        img.onerror = function () {
            callback(feature, false);
        };
        img.src = "data:image/webp;base64," + kTestImages[feature];
    }
}

export default filters