import {useUserStore} from "@/stores/user";
import i18n from "@/translation";
import router from "@/router";
import {computed} from "vue";

export function usePreviewMode() {

    const userStore = useUserStore()

    // const isPreviewMode = computed(() => {
    //     return useUserStore().user.preview !== undefined && useUserStore().user.preview
    // })

    /**
     * Check if platform is in preview mode
     * @return {boolean}
     */
    function isPreviewMode() {
        return useUserStore().user.preview !== undefined && useUserStore().user.preview !== false
    }

    function goPreviewMode(_route, params = {}){

        if (userStore.user.creator !== null && userStore.user.creator.id > 0) {
            if (userStore.user.preview !== undefined && userStore.user.preview === true) {
                // sidebarMenu.value = MenuDesktop.getSidebarMenu(t)
                router.push({ name: 'kol-admin-my-feed' })
                userStore.setPreview(false)
            } else {
                // sidebarMenu.value = MenuDesktop.getSidebarMenu(t, true)

                router.push({
                    name: _route,
                    params
                })
                userStore.setPreview(true)
            }
        }
    }

    return { isPreviewMode, goPreviewMode }
}