import { useUserStore } from '@/stores/user'
import RolesHelper from "@/helpers/Roles";

export const hasSubscriptionMiddleware = (to, from, next) => {
    const user = useUserStore()


    // if (!user.user.has_subscription && user.role !== RolesHelper.Roles.Guest) {
    //     return next({ name: 'login' })
    // }

    return next()
}