import ApiService from '@/services/ApiService'
import { useUserStore } from '@/stores/user'
import { useCreatorStore } from '@/stores/frontend/creator'
import RolesHelper from "@/helpers/Roles";

import JwtService from "@/services/JwtService";

export const creatorFinderMiddleware = (to, from, next) => {
    const { creatorSlug: slug } = to.params
    let isAuthenticated = !!JwtService.getToken()

    // FOR NOW CREATORS CANNOT VIEW OTHER CREATORS
    // if ((useUserStore().role === RolesHelper.Roles.Creator && !useUserStore().user.is_account_manager) && slug !== useUserStore().creator.slug){
    //     return next({name: 'home'})
    // }

    useCreatorStore()
        .findCreatorBySlug(slug)
        .then(async (creator) => {
            if (!isAuthenticated) {
                await useUserStore().loginGuest()
            }

            // Spe creator watch a creator
            if (useUserStore().user.role === RolesHelper.Roles.Creator) {
                useUserStore().user.is_creator = true
                useUserStore().user.role = RolesHelper.Roles.User
            }

            useUserStore().setSelectedCreator(creator)
            useCreatorStore().setCreator(creator)

            return next()
        })
        .catch((e) => {
            // Try to catch :creatorSlug/feed but under construction
            if (e.status === 222 && to.name === 'user-dashboard-with-slug') {
                return next({
                    path: `/${to.params.creatorSlug}/`
                })
            }
            // CREATOR EXISTS BUT UNDER CONSTRUCTION
            if (e.status === 222) {
                return next()
            }
            return next({
                name: 'NotFound',
                params: { pathMatch: to.path.substring(1).split('/') },
                query: to.query,
                hash: to.hash,
            })
        })
}