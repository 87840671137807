import {computed, onMounted, onUnmounted, ref} from "vue";

import Echo from '@ably/laravel-echo';
import * as Ably from 'ably';

import JwtService from "@/services/JwtService";
import {useCreatorStore} from "@/stores/frontend/creator";
import {useUserStore} from "@/stores/user";
import {useReverbClientStore} from "@/stores/reverbClient";

import {v4} from "uuid";
import { useI18n } from 'vue-i18n'
import ApiService from "@/services/ApiService";

export function useAblyClient(options = {
    channel: "",
    type: "",
    userId: 0,
    autoConnect: false,
    noInit: false,
}) {

    const { t } = useI18n()

    const creatorStore = useCreatorStore()

    let echo = null;
    const isIniting = ref(false)
    let channel = null;
    const hasJoined = ref(false);

    const {setChannel, addReaction} = useReverbClientStore()

    const userId = ref(options.userId)

    let uuid = localStorage.getItem('presence-uuid')
    if (userId.value === null) {
        uuid = uuid ?? v4()
        localStorage.setItem('presence-uuid', uuid)
        userId.value = uuid
    }
    const isBanned = ref(false)

    const setIsBanned = (_isBanned) => {
        isBanned.value = _isBanned
    }
    const getIsBanned = () => {
        return isBanned.value
    }
    const bannedUsers = ref([])

    const messages = ref([]);
    const currentViewers = ref([]);

    const viewersCount = computed(() => currentViewers.value.length);

    const joinPrivateChannel = (_channel) => {
    }

    const joinPresenceChannel = (_channel) => {

        if (echo === null) {
            initEcho()
        }

        channel = echo.join(_channel)
        .here((users) => {
            hasJoined.value = true
            currentViewers.value = [
                ...users.filter((item) => item.id !== userId.value)
            ]
        })
        .joining((user) => {
            // currentViewers.value.push(user)
            // console.log('joined', user.username);
        })
        .leaving((user) => {
            // console.log('leaving', user.username);
            currentViewers.value = currentViewers.value.filter((item) => item.id !== user.id)
        })
        .listenForWhisper('message', (message) => {
            messages.value.push(message)
            // console.log(message)
        })
        .listenForWhisper('banned', ({id: bannedUserId}) => {
            if (bannedUserId === userId.value) {
                isBanned.value = true
                useUserStore().user.is_logged_in = {
                    ...useUserStore().user.is_logged_in,
                    [creatorStore.creator.ulid]: true
                }
            }
            bannedUsers.value.push(bannedUserId)

            messages.value.map((message) => {
                if (message.ikid === bannedUserId){
                    message.text = t(`user.live.mutedMessage`)
                }
                return message
            })

            // console.log("bannedUser", bannedUserId)
        })
        .listenForWhisper('unbanned', ({id: unbannedUser}) => {
            if (unbannedUser === userId.value) {
                isBanned.value = false
                delete useUserStore().user.is_logged_in[creatorStore.creator.ulid]
            }
            bannedUsers.value = bannedUsers.value.filter((item) => item !== unbannedUser)

            // console.log("unbannedUser", unbannedUser)
        })
        .listenForWhisper('reaction', (reaction) => {
            addReaction(v4(), reaction);
        })
        .error((error) => {
            console.error('error', error);
        });

        setChannel(channel)
    }

    const banUser = (_userID) => {
        channel !== null && channel.whisper('banned', {id: _userID})
    }

    const unbanUser = (_userID) => {
        // console.log('UNBAN USER REVERB', _userID, channel)
        channel !== null && channel.whisper('unbanned', {id: _userID})
    }

    const sendClientMessage = (_message) => {
        channel !== null && channel.whisper('message', _message)
    }

    const initEcho = ({
        onConnected = () => {},
        onError = () => {},
        onDisconnected = () => {}
    } = {}) => {

        if (useUserStore().isGuest){
            return;
        }

        window.Ably = Ably; // make globally accessible to Echo

        isIniting.value = true

        echo = new Echo({
            broadcaster: 'ably',
            key: import.meta.env.VITE_ABLY_KEY,
            clientId: userId.value.toString(),
            // wsHost: import.meta.env.VITE_REVERB_HOST,
            // wsPort: import.meta.env.VITE_REVERB_PORT,
            // wssPort: import.meta.env.VITE_REVERB_PORT,
            // forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
            // enabledTransports: ['ws', 'wss'],
            authEndpoint: import.meta.env.VITE_API_BASE_URL + '/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: 'Bearer ' + JwtService.getToken()
                },
            },
            // authorizer: (channel, options) => {
            //     return {
            //         authorize: (socketId, callback) => {
            //             ApiService.post('broadcasting/auth', {
            //                 socket_id: socketId,
            //                 channel_name: channel.name
            //             })
            //             .then(response => {
            //                 callback(false, response.data);
            //             })
            //             .catch(error => {
            //                 callback(true, error);
            //             });
            //         }
            //     };
            // },
        });

        echo.connector.ably.connection.on('connecting', (payload) => {

            /**
             * All dependencies have been loaded and Channels is trying to connect.
             * The connection will also enter this state when it is trying to reconnect after a connection failure.
             */

            // console.log('connecting...');

        });

        echo.connector.ably.connection.on('connected', (payload) => {

            /**
             * The connection to Channels is open and authenticated with your app.
             */

            onConnected()
            isIniting.value = false
            // console.log('connected!', payload);
        });

        echo.connector.ably.connection.on('unavailable', (payload) => {

            /**
             *  The connection is temporarily unavailable. In most cases this means that there is no internet connection.
             *  It could also mean that Channels is down, or some intermediary is blocking the connection. In this state,
             *  pusher-js will automatically retry the connection every 15 seconds.
             */
            onError()
            // console.log('unavailable', payload);
        });

        echo.connector.ably.connection.on('failed', (payload) => {

            /**
             * Channels is not supported by the browser.
             * This implies that WebSockets are not natively available and an HTTP-based transport could not be found.
             */
            onError()
            // console.log('failed', payload);

        });

        echo.connector.ably.connection.on('disconnected', (payload) => {

            /**
             * The Channels connection was previously connected and has now intentionally been closed
             */
            onDisconnected()
            // console.log('disconnected', payload);

        });
    }

    onMounted(() => {
        if (!!JwtService.getToken()) {

            // console.log('SHOULD AUTO INIT')

            initEcho({
                onConnected: () => {
                    // if (options.autoConnect) {
                        switch (options.type) {
                            case 'private':
                                joinPrivateChannel(options.channel);
                                break;
                            case 'presence':
                                // console.log('SHOULD AUTO CONNECT')
                                joinPresenceChannel(options.channel);
                                break;
                            default:
                                break
                        }
                    // }
                    isIniting.value = false
                }
            })
        }
    })

    onUnmounted(() => {
        echo !== null && echo.leave(options.channel);
    })

    return {
        messages,
        currentViewers,
        viewersCount,
        hasJoined,
        isBanned,
        bannedUsers,
        channel,

        sendClientMessage,
        joinPrivateChannel,
        joinPresenceChannel,
        banUser,
        unbanUser,
        setIsBanned,
        getIsBanned,
    }

}
