<script setup>
import {computed, ref, toRef, watch} from 'vue'
import {useField} from 'vee-validate'
import BaseField from '@/components/Form/BaseField.vue'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js'
// Import styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import TextCharacterCounter from '@/components/TextCharacterCounter.vue'

const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    value: {
        type: [String, Number],
        default: '',
    },
    id: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: '',
    },
    rows: {
        type: Number,
        default: 3,
    },
    fileTypes: {
        type: String,
        default: 'image/jpeg, image/png',
    },
    helper: {
        type: [Boolean, String],
        default: false,
    },
    modelValue: {
        type: [String, Number, Array],
        default: '',
    },
    big: {
        type: Boolean,
        default: false,
    },
    truncated: {
        type: Boolean,
        default: false,
    },
    isGrouped: {
        type: Boolean,
        default: false,
    },
    bottomBorder: {
        type: Boolean,
        default: false,
    },
    onChange: {
        type: Function,
        default: null,
    },
    autocomplete: {
        type: String,
        default: '',
    },
    whiteBg: {
        type: Boolean,
        default: true,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    max: {
        type: [String, Number],
        default: null,
    },
    min: {
        type: [String, Number],
        default: null,
    },
    showMaxInlineMode: {
        type: Boolean,
        default: false,
    },
    class: {
        type: [String, Object],
        default: '',
    },
    styles: {
        type: [String, Object],
        default: '',
    },
    maxLength: {
        type: [String, Number],
        default: '',
    },
    noCounter: {
        type: Boolean,
        default: false,
    },
    dataInfo: {
        type: String,
        default: '',
    },
})

const emit = defineEmits(['input:keydown', 'input:keyup', 'update:modelValue', 'focusout', 'update:file'])

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

const pond = ref()

const ariaDescribedBy = computed(() => {
    return `${props.id}Help`
})

const name = toRef(props, 'name')
const {value, errorMessage, handleBlur, handleChange} = useField(name, undefined, {
    initialValue: props.modelValue,
    syncVModel: true,
})

if (props.onChange) {
    watch(value, (value, oldValue) => {
        props.onChange(value, oldValue)
    })
}

let totalFile = ref(1)

const inputRef = ref(null)

defineExpose({
    input: inputRef,
})

</script>

<template>
    <div class="col">
        <BaseField
            :id="id"
            :type="type"
            :name="name"
            :label="label"
            :success-message="successMessage"
            :helper="helper"
            :big="big"
            :truncated="truncated"
            :is-grouped="isGrouped"
            :required="required"
            :class="props['class']"
            :style="props['styles']"
        >
            <template #right-label>
                <slot name="right-label"/>
            </template>

            <template #prefix>
                <slot name="prefix"/>
            </template>

            <template #postfix>
                <slot name="postfix"/>
            </template>

            <file-pond
                v-if="type === 'file'"
                ref="pond"
                :name="name"
                :label-idle="placeholder"
                :allow-multiple="false"
                instant-upload="false"
                :accepted-file-types="fileTypes"
                :files="value"
                credits=""
                @addfile="$emit(`update:${name}`, $refs.pond.getFile().file)"
                @removefile="$emit(`update:${name}`, null)"
            />
            <file-pond
                v-if="type === 'multipleFiles'"
                ref="pond"
                :name="name"
                :label-idle="placeholder"
                :allow-multiple="true"
                instant-upload="false"
                :accepted-file-types="fileTypes"
                :files="value"
                :allow-reorder="true"
                credits=""
                @addfile="() => {
                    if(totalFile >= $refs.pond.getFiles().length) {
                        $emit(`update:${name}`, $refs.pond.getFiles())
                    }
                    totalFile +=  1
                }"
                @removefile="(error, file) => {
                    $emit(`removeFile:${name}`, file)
                }"
                @reorderfiles="() => {
                    $emit(`update:${name}`, $refs.pond.getFiles())
                }"
            />
            <input
                v-else-if="type !== 'textarea' && type !== 'file' && type !== 'multipleFiles'"
                :id="id"
                ref="inputRef"
                class="form-control text-dark"
                :class="[
                    { 'is-invalid': errorMessage },
                    { 'rounded-end-0': isGrouped },
                    { 'border-0 border-bottom shadow-none rounded-0': bottomBorder },
                    { 'rounded-3 ': !bottomBorder },
                    { 'px-4': big },
                    whiteBg ? 'bg-white text-dark' : '',
                ]"
                :style="whiteBg ? '' : 'background-color:#EBEBEB'"
                :data-info="dataInfo"
                :name="name"
                :type="type"
                :value="value"
                :placeholder="placeholder"
                :aria-describedby="ariaDescribedBy"
                :autocomplete="autocomplete"
                :disabled="disabled"
                :readonly="readonly"
                :min="min"
                :max="max"
                @input="handleChange"
                @blur="handleBlur"
                @keydown="emit('input:keydown', $event)"
                @keyup="emit('input:keyup', $event)"
                @focusout="emit('focusout', $event)"
            />
            <textarea
                v-if="type === 'textarea'"
                :id="id"
                ref="inputRef"
                class="form-control"
                :class="[
                    { 'is-invalid': errorMessage },
                    truncated ? 'text-truncate' : ''
                ]"
                v-bind="$attrs"
                :name="name"
                :rows="rows"
                :data-info="dataInfo"
                :maxlength="maxLength"
                :value="value"
                :placeholder="placeholder"
                :aria-describedby="ariaDescribedBy"
                @input="handleChange"
                @blur="handleBlur"
                @keydown="emit('input:keydown', $event)"
                @keyup="emit('input:keyup', $event)"
                @focusout="emit('focusout', $event)"
            ></textarea>

            <template #extra-info>
                <text-character-counter
                    v-if="type !== 'file' && !showMaxInlineMode && !noCounter && (max > 0 || min > 0)"
                    :current-length="(value !== undefined && value !== null && value?.length) ? value?.length : 0"
                    :max="max"
                    :min="min"
                    class="d-block small text-secondary my-2"
                    :class="$breakpoints.mdAndDown.value ? 'col-2' : ''"
                />
            </template>
            <text-character-counter
                v-if="type !== 'file' && showMaxInlineMode && (max > 0 || min > 0)"
                inline-mode
                :current-length="value?.length"
                :max="max"
                :min="min"
                style="z-index: 999"
                class="input-group-text border-0 rounded-end bg-white-dark-mode fs-8 p-0 m-2 position-absolute bottom-0 end-0"
            />
        </BaseField>
    </div>
</template>
