import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'
import { QueryParamBuilder } from '@/helpers/QueryParamBuilder'
import { useUserStore } from './user'

export const useWorkoutProgramStore = defineStore('workoutProgram', () => {
    const userStore = useUserStore()

    /**
     * Get workout program listing
     * @param filter
     * @param cursor
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getListing(query = {}, cursor = null) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/workout-program`
        
        let queryFilter = ''
        
        query.per_page = 3
        
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }
        if (cursor != null) {
            queryFilter += '&cursor=' + cursor
        }
        return ApiService.getAll(apiUrl + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Get a workout program
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function getById(workoutProgramId) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/workout-program`
        return ApiService.get(apiUrl, workoutProgramId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Join to workout program
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function joinProgram(workoutProgramId, data) {
        let apiUrl = `creator/${userStore.selectedCreator.id}/workout-program/${workoutProgramId}/select`
        return ApiService.post(apiUrl, data)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Like a workout program
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function like(workoutProgramId) {
        return ApiService.post('workout-program/' + workoutProgramId + '/toggle-like')
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function create(data) {
        return ApiService.post('workout-program', data)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function getAll(query = {}, cursor = null) {
        let apiUrl = `workout-program`
        let queryFilter = ''
        if (query) {
            queryFilter = QueryParamBuilder.encode(query)
        }
        if (cursor != null) {
            queryFilter += '&cursor=' + cursor
        }
        return ApiService.getAll(apiUrl + queryFilter)
            .then(({ data }) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function creatorGetById(workoutProgramId) {
        return ApiService.get('workout-program', workoutProgramId)
            .then((result) => {
                return result
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function editById(workoutProgramId, data) {
        return ApiService.patch('workout-program/' + workoutProgramId, data)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    /**
     * Delete a workout program
     * @returns {Promise<axios.AxiosResponse>}
     */
    async function softDelete(workoutProgramId) {
        return ApiService.delete('workout-program/' + workoutProgramId)
            .then((data) => {
                return data
            })
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        getListing,
        getById,
        joinProgram,
        like,

        create,
        getAll,
        creatorGetById,
        editById,
        softDelete,
    }
})
