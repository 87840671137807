import * as Sentry from "@sentry/vue";


export function useSentry() {

    const setUser = (user = null) => {
        if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
            const scope = Sentry.getCurrentScope();
            scope.setUser({
                ...user,
            });
        }
    }

    const setSelectedCreator = (creator = null) => {
        if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
            const scope = Sentry.getCurrentScope();
            scope.setContext("selectedCreator", creator);
        }
    }

    const setUserRole = (role = null) => {
        if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
            const scope = Sentry.getCurrentScope();
            scope.setContext("userRole", {role});
        }
    }

    return {
        setUser,
        setUserRole,
        setSelectedCreator
    }
}
