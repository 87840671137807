<template>
    <div class="video-card-container">
        <div class="video-card" role="button" @click.prevent="$emit('view:video')">
            <img
                v-if="!imageUrl"
                class="video-card-image"
                src="@/assets/images/masterclass_placeholder.jpg"
                role="presentation"
            />
            <img
                v-else
                :src="imageUrl"
                class="video-card-image"
                role="presentation"
            />
            <i class="fa-solid fa-play fs-1 text-white video-card-play"></i>
        </div>
        <div class="d-flex align-items-center">
            <div class="video-card-information">
                <div class="title">
                    <i v-if="userStore.cannot('goto', item) && locked === 'subscriber'" class="fa-solid fa-lock me-2"></i>
                    {{ title }}
                </div>
                <div class="sub-title">
                    {{ $t('workout.exercise.form.list.exercise.label') }} {{ episodeIndex }} - {{ $filters.formatDuration(duration) }}
                </div>
            </div>
            <button v-if="hasTips" class="btn btn-tertiary btn-tips rounded-pill ms-auto" @click="$emit('view:tips')">
                {{ $t('workout.exercise.form.list.button.viewTips.label') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user'

const userStore = useUserStore()

const props = defineProps({
    item: {
        type: Object,
        default: () => {}
    },
    title: {
        type: String,
        required: true,
    },
    episodeIndex: {
        type: Number,
        required: true,
    },
    duration: {
        type: Number,
        default: 0,
        required: true,
    },
    imageUrl: {
        type: String,
        required: true,
    },
    hasTips: {
        type: Boolean,
        default: false,
    },
    locked: {
        type: String,
        default: "subscriber",
    },
})
defineEmits(['view:video', 'view:tips'])
</script>

<style scoped>
.video-card-container {
    /* display: block;
    text-decoration: none;
    margin-top: 1.25rem; */
    margin-top: 1.25rem;
}
.video-card {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
.video-card-image {
    width: 100%;
    object-fit: cover;
    border-radius: 0.625rem;
    height: 15rem;
    filter: brightness(50%);
}
.video-card-play {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}
.video-card-information {
    display: block;
    margin-top: 0.25rem;
}
.title {
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    color: var(--text-color);
}
.sub-title {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--text-color);
}
.btn-tips {
    background-color: transparent;
    padding: 0.25rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    min-width: 5.313rem;
}
</style>