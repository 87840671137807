import { staticPages } from '@/config/StaticPageConfig'

const pageRoutes = []

for (const [key, value] of Object.entries(staticPages)) {
    for (const [lang, config] of Object.entries(value)) {
        pageRoutes.push({
            path: `/${lang}/${config.view}`,
            name: `static-${lang}-${key}`,
            component: () => import(`@/views/Pages/${lang}/${config.view}.vue`),
            meta: {
                layout: 'static',
                page: key,
                title: config.pageTitle,
                lang,
            },
        })
    }
}

const _staticRoutes = [
    {
        path: '/',
        component: () => import('@/components/Layout/StaticLayout.vue'),
        children: pageRoutes,
    },
    {
        path: '/support',
        component: () => import('@/components/Layout/StaticLayout.vue'),
        children: [
            {
                path: 'contact',
                name: 'static-contact-page',
                component: () => import('@/views/Pages/en/contact.vue'),
                meta: {
                    layout: 'static',
                    page: 'static-contact-page',
                    title: 'Contact Us',
                    lang: 'en',
            },
            },
        ],
    },
]

export default _staticRoutes
