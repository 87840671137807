import { defineStore } from 'pinia'
import {computed, ref} from 'vue'

const DEFAULT_SOURCE = {
    episodeId: null,
    status: 'false',
    source: '',
    thumb: '',
    title: null
}

export const useGlobalAudioPlayerStore = defineStore('globalAudioPlayer', () => {

    const media = ref(DEFAULT_SOURCE)

    const currentEpisode = computed(() => media.value.episodeId)

    const init = (_source = null, autoplay = false) => {
        stop()
        media.value = {...DEFAULT_SOURCE}

        if (_source !== '') {
            media.value = {
                status: autoplay ? 'playing' : 'paused',
                source: _source.src,
                thumb: _source.thumb,
                title: _source.title,
                episodeId: _source.episodeId
            }
            if (autoplay) {
                play()
            }
        }
    }
    const play = () => {
        media.value.status = 'playing'
    }

    const pause = () => {
        media.value.status = 'paused'
    }

    const stop = () => {
        media.value = {...DEFAULT_SOURCE}
    }

    const toggle = () => {
        media.value.status = media.value.status !== 'playing' ? 'playing' : 'paused'
    }


    return {
        media,
        currentEpisode,

        init,
        play,
        pause,
        stop,
        toggle,
    }
})