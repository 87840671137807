<template>
    <footer
        class="p-4 col-11 col-md-10 col-lg-10 col-xxl-8 mx-auto mt-5 footerMobile"
        :class="[
            ($breakpoints.mdAndDown.value) ? '' : '',
            border ? 'border-2 border-top' : ''
        ]"
    >
        <div class="row">
            <!-- Logo -->
            <div 
                :class="($breakpoints.lgAndUp.value) ? 'col-6' : 'col mb-2'"
            >
                <router-link to="/">
                    <img
                        src="@/assets/images/landing/logo-ikonstar-seul-black.svg"
                        alt=""
                        style="max-width: 180px"
                        class="mb-3"
                    />
                </router-link>
                <p class="text-dark">
                    {{ $t('globals.tagLine') }}
                </p>
                <LanguageSelector class="ms-3 mt-0" is-home-page></LanguageSelector>

            </div>

            <!-- Product -->
            <!-- <div 
                class="justify-content-start flex-column d-flex" 
                :class="($breakpoints.lgAndUp.value) ? 'col' : ''"
            >
                <h4 
                    class="text-black pb-3 fs-6"
                    :class="($breakpoints.mdAndDown.value) ? 'border-2 border-bottom' : ''"
                    @click="collapse('product')"
                >
                    {{ $t('globals.product.title') }}
                </h4>
                <div class="product" :class="($breakpoints.mdAndDown.value && !displayed.product) ? 'hidden' : ''">
                    <ul
                        :class="($breakpoints.lgAndUp.value) ? 'no-style-list' : ''"
                    >
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.product.forIkons') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.product.forFans') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.product.forCorp') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.product.become') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.product.download') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <!-- Blog -->
            <!-- <div 
                class="justify-content-start flex-column d-flex" 
                :class="($breakpoints.lgAndUp.value) ? 'col' : ''"
            >
                <h4 
                    class="text-black pb-3 fs-6"
                    :class="($breakpoints.mdAndDown.value) ? 'border-2 border-bottom' : ''"
                    @click="collapse('blog')"
                >
                    {{ $t('globals.blog.title') }}
                </h4>
                <div class="blog" :class="($breakpoints.mdAndDown.value && !displayed.blog) ? 'hidden' : ''">
                    <ul
                        :class="($breakpoints.lgAndUp.value) ? 'no-style-list' : ''"
                    >
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.blog.fitness') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.blog.food') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.blog.health') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.blog.workouts') }}
                            </a>
                        </li>
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.blog.nutrition') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <!-- Support -->
            <div 
                class="justify-content-start flex-column d-flex" 
                :class="($breakpoints.lgAndUp.value) ? 'col' : ''"
            >
                <h4 
                    class="text-black pb-3 fs-6"
                    :class="($breakpoints.mdAndDown.value) ? 'border-2 border-bottom' : ''"
                    @click="collapse('support')"
                >
                    {{ $t('globals.support.title') }}
                </h4>
                <div class="support" :class="($breakpoints.mdAndDown.value && !displayed.support) ? 'hidden' : ''">
                    <ul
                        :class="($breakpoints.lgAndUp.value) ? 'no-style-list' : ''"
                    >
                        <li>
                            <a href="#" class="text-decoration-none text-black">
                                {{ $t('globals.support.helpCenter') }}
                            </a>
                        </li>
                        <li>
                            <router-link
                                :to="{name: 'static-contact-page'}"
                                class="text-decoration-none text-black"
                            >
                                {{ $t('globals.support.contact') }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- About -->
            <div 
                class="justify-content-start flex-column d-flex" 
                :class="($breakpoints.lgAndUp.value) ? 'col' : ''"
            >
                <h4 
                    class="text-black pb-3 fs-6"
                    :class="($breakpoints.mdAndDown.value) ? 'border-2 border-bottom' : ''"
                    @click="collapse('about')"
                >
                    {{ $t('globals.about.title') }}
                </h4>
                <div class="about" :class="($breakpoints.mdAndDown.value && !displayed.about) ? 'hidden' : ''">
                    <ul
                        :class="($breakpoints.lgAndUp.value) ? 'no-style-list' : ''"
                    >
                        <li
                            v-for="(page, key) in pages"
                            :key="key"
                        >
                            <router-link
                                :to="{name: page.route}"
                                class="text-decoration-none text-black"
                            >
                                {{ page.title }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col text-center text-body-tertiary">
                <small>
                    {{ year }} © IKONSTAR. {{ t('globals.allRightsReserved') }}
                </small>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref, computed } from 'vue'
import dayjs from 'dayjs/esm/index.js'

import {useI18n} from 'vue-i18n'
import useStaticPageStore from '@/stores/staticPage'
import {staticPages} from '@/config/StaticPageConfig'
import i18n from '@/translation'
import LanguageSelector from "@/components/Layout/Sidebar/_partials/LanguageSelector.vue";

const {getRouteNameForPage, currentLanguage} = useStaticPageStore()
const {t} = useI18n()

defineProps({
    border: {
        type: Boolean,
        default: true
    }
})

const year = computed(() => dayjs().format('YYYY'))

const pages = computed(() => {
    let test = Object.values(staticPages)
        .map((entry) => {
            return {
                title: entry[i18n.global.locale.value].title,
                route: getRouteNameForPage(entry.en.view)
            }
        })

    return test
})

let displayed = ref({
    product: false,
    blog: false,
    support: false,
    about: false,
})

function collapse(elem) {
    displayed.value[elem] = (displayed.value[elem] === false)
}

</script>

<style>
.no-style-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.hidden {
    display: none;
}
@media (max-width: 640px) {
    .footerMobile {
        position: relative !important;
    }
}
</style>