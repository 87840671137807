<template>
    <span
        v-tooltip="{title: $t('globals.tooltips.like')}"
        class="d-flex text-decoration-none text-black-dark-mode justify-content-center align-items-center cursor-pointer"
        @click.prevent="toggleLike"
    >
        <span class="d-flex align-items-center" style="font-size: 14px; color: #737373">
<!--            <div v-show="loading">-->
<!--                <span class="spinner-border spinner-border-sm me-2" aria-hidden="true"></span>-->
<!--                <span class="visually-hidden" role="status">Loading...</span>-->
<!--            </div>-->
            <i v-show="currentLiked" class="fa-solid fa-heart fs-5 me-2" style="color: #fd313e"></i>
            <i v-show="!currentLiked" class="fa-regular fa-heart fs-5 me-2"></i>
            {{ t('creator.post.like') }}
        </span>
        <template v-if="showCounter">
            <span class="ms-1" style="font-size: 14px; color: #737373">
                ({{ counter }})
            </span>
        </template>
    </span>

    <!--    <div @click="animate">-->
<!--        <div ref="like" class="heart" :class="{'is_animating': animating, 'liked': liked}"></div>-->
<!--    </div>-->
</template>

<script setup>
// import {onMounted, ref} from "vue";
import {useI18n} from 'vue-i18n'
import {ref, toRaw, toRef} from "vue";
import debounce from "lodash.debounce";
import { useUserStore } from '@/stores/user'

const {t} = useI18n()

const {liked} = defineProps({
    class: {
        type: String,
        default: "d-flex text-decoration-none text-black-dark-mode justify-content-center align-items-center cursor-pointer"
    },
    loading: {
        type: Boolean,
        default: false
    },
    liked: {
        type: Boolean,
        default: false
    },
    showCounter: {
        type: Boolean,
        default: false
    },
    counter: {
        type: Number,
        default: 0
    },
})
const emits = defineEmits(['clicked'])
//
// const like = ref(null)
// const animating = ref(false)
const currentLiked = ref(liked)
const userStore = useUserStore()

//
// const animate = () => {
//     animating.value = !liked.value
//     toggleLike()
// }
//
const toggleLike = () => {
    if (!userStore.cannot('like')){
        currentLiked.value = !currentLiked.value
    }
    emits('clicked')
}

const emitLike = debounce(() => {
    emits('clicked')
}, 1000)

//
// onMounted(() => {
//     like.value.addEventListener('animationend', function(){
//         animating.value = false
//     });
// })

</script>

<!--<style scoped>-->
<!--.heart {-->
<!--    cursor: pointer;-->
<!--    height: 50px;-->
<!--    width: 50px;-->
<!--    background-image:url('https://abs.twimg.com/a/1446542199/img/t1/web_heart_animation.png');-->
<!--    background-position: left;-->
<!--    background-repeat:no-repeat;-->
<!--    background-size:2900%;-->
<!--}-->

<!--.heart:hover, .heart.liked {-->
<!--    background-position:right;-->
<!--}-->

<!--.is_animating {-->
<!--    animation: heart-burst .8s steps(28) 1;-->
<!--}-->

<!--@keyframes heart-burst {-->
<!--    from {background-position:left;}-->
<!--    to { background-position:right;}-->
<!--}-->
<!--</style>-->
