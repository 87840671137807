<template>
    <div class="row overflow le-flex shadow-sm">
        <div
            id="header"
            class="le-flex"
            :style="{ 'background-image': headerImg }"
        >
            <Form v-if="isCreator" class="header-container cursor-pointer">
                <label class="header" for="headerFile">

                    <span class="header-hover cursor-pointer">
                        {{ $t('creator.creator.form.headerImg.edit') }}
                    </span>
                    <input id="headerFile" ref="headerRef" type="file" name="headerFile" class="input" :accept="`${acceptedTypes.photo.join(', ')}`"  @change="submitHeader" />
                </label>
            </Form>

        </div>
        <div id="profile" class="le-flex bg-white-dark-mode">
            <div v-if="!isCreator" class="image" :class="{'creator-is-live': (!isSpecific && isLive.status === 'active')}">
                <template v-if="!isSpecific && isLive.status === 'active'">
                    <span @click="openLiveStream($breakpoints.mdAndDown.value ? 'user-live-mobile' : 'user-live')">

                        <div class="avatar-wrapper">
                            <img :src="creatorStore.creator.avatar" alt="avatar" />
                            <div v-if="!isSpecific && isLive.status === 'active'" id="status" class="position-absolute text-white text-center fw-medium">
                                <span class="ms-1"> LIVE </span>
                            </div>
                        </div>
                    </span>
                </template>

                <template v-else>
                    <div class="avatar-wrapper">
                        <img :src="creatorStore.creator.avatar" alt="avatar" />
                        <div v-if="!isSpecific && isLive.status === 'active'" id="status" class="position-absolute text-white text-center fw-medium">
                            <span class="ms-1"> LIVE </span>
                        </div>
                    </div>
                </template>
            </div>

            <div v-if="isCreator" id="creator-avatar-changer" class="image">
                <Form class="avatar-container">
                    <label id="avatar-label" class="avatar" for="file" @click.prevent="changeAvatar">
                        <div class="avatar-wrapper avatar-changer cursor-pointer">
                            <span class="avatar-hover">
                                {{ $t('creator.creator.form.avatar.edit') }}
                            </span>
                            <img :src="AvatarHelper.getCreatorAvatar(userStore.user.avatar, userStore.user.username)" alt="avatar" />
                        </div>
                    </label>
                    <input id="avatar-file" ref="avatarRef" type="file" name="file" class="input" :accept="`${acceptedTypes.photo.join(', ')}`" @change="submitAvatar" />
                </Form>
            </div>

            <div class="d-flex justify-content-end align-items-center mt-n5">
                <div v-if="!isCreator && enableFollowButton && !userStore.inPreviewMode" class="mb-2 me-2">
                    <span
                        class="btn btn-outline-dark rounded-3 fw-light"
                        :class="isFollower ? 'btn-unfollow-hover' : ''"
                        @click.prevent="toggleFollow"
                        @mouseenter="hover = true"
                        @mouseleave="hover = false"
                    >
                        {{ !isFollower ? 'Follow' : hover ? 'Unfollow' : 'Following' }}
                    </span>
                </div>

                <span
                    v-else-if="isCreator"
                    class="btn btn-outline-danger rounded-3 fw-light mb-2 me-2"
                    @click="createLiveStream($breakpoints.mdAndDown.value ? 'kol-admin-live-mobile' : 'kol-admin-live', 'live-streaming')"
                >
                    <i class="fa-solid fa-video"></i>
                    <span v-if="$breakpoints.lgAndUp.value" class="ms-2">Live</span>
                </span>
                <!-- <span
                    v-if="isCreator"
                    class="btn btn-outline-warning rounded-3 fw-light mb-2 me-2"
                    @click="createLiveStream($breakpoints.mdAndDown.value ? 'kol-admin-live-ivs-mobile' : 'kol-admin-live-ivs', 'creator-ivs-channel')"
                >
                    <i class="fa-solid fa-video"></i>
                    <span v-if="$breakpoints.lgAndUp.value" class="ms-2">Live IVS</span>
                </span> -->
                <share-social :key="creatorStore.creator.id" :item="{type: 'creator', data: creatorStore.creator, creator: true}" />

                <div v-if="isCreator" class="d-none d-lg-flex">
                    <router-link
                        class="btn btn-outline-dark fw-light mb-2 me-2"
                        :to="{ name: 'kol-admin-post-add' }"
                    >
                        <i class="fa-light fa-photo-film-music me-1"></i>
                        {{ $t('creator.post.form.add.titleNewPost') }}
                    </router-link>
                    <!-- <PostFormInline></PostFormInline>-->
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                    <div class="name">
                        {{ $filters.capitalized(creatorStore.creator.username) }}
                        <i
                            v-if="creatorStore.creator.is_verified"
                            v-tooltip="{title: $t('globals.tooltips.verifiedAccount')}"
                            class="fs-5 fa-solid fa-badge-check text-primary"
                        ></i>
                    </div>
                    <div class="nickname">
                        @{{ creatorStore.creator.slug }}
                    </div>
<!--                    <div class="nickname">-->
<!--                        500K Followers-->
<!--                    </div>-->
<!--                    <div class="nickname">-->
<!--                        2K Subscribers-->
<!--                    </div>-->
                </div>
                <div class="">
                    <!-- <router-link
                        v-if="!isCreator"
                        class="btn btn-outline-danger fw-light me-2"
                        :to="{path: '/live' }"
                    >
                        LIVE
                    </router-link> -->
                    <!-- <router-link
                        v-if="!isCreator"
                        class="btn btn-outline-warning fw-light me-2"
                        :to="{path: '/live-ivs' }"
                    >
                        LIVE IVS
                    </router-link> -->
<!--                    <div class="me-2">-->
<!--                        (5232) <i class="fa-light fa-image"></i> |-->
<!--                        (5232) <i class="fa-light fa-film"></i>-->
<!--                    </div>-->
                </div>
            </div>
            <div v-if="!isCreator && isSpecific && userStore.cannot('goto')" class="d-flex justify-content-between">
                <div class="w-100 text-center pb-3 fw-bold cursor-pointer" @click="ctaModalStore.showModal()">
                    Réservez votre place pour le combat de samedi !
                </div>
            </div>

        </div>
    </div>

</template>

<script setup>
import {useCreatorStore} from "@/stores/frontend/creator";
import {useUserStore} from "@/stores/user";
import {computed, onBeforeMount, onDeactivated, onMounted, onUnmounted, ref, watch} from "vue";
import ShareSocial from "@/components/ShareSocial.vue";
import ApiService from "@/services/ApiService";
import {useRegistrationCtaModalStore} from "@/stores/registrationCtaModal";
import router from "@/router";
import {useFormModalStore} from "@/stores/formModal";
import {useI18n} from "vue-i18n";
import {useLoadingStore} from "@/stores/frontend/loading";
import { Form } from 'vee-validate'
import UppyService from '@/services/UppyService'
import AvatarHelper from '@/helpers/AvatarHelper'

import acceptedTypes from '@/config/PostAcceptedFileTypes'
import NotificationService from '@/services/NotificationService'
import dayjs from 'dayjs'
import {useCalendarStore} from "@/stores/calendar";
import "@sjmc11/tourguidejs/src/scss/tour.scss" // Styles
import {TourGuideClient} from "@sjmc11/tourguidejs/src/Tour"
import debounce from 'lodash.debounce'

const { t } = useI18n()

const userStore = useUserStore()
const creatorStore = useCreatorStore()
const ctaModalStore = useRegistrationCtaModalStore()
const formModalStore = useFormModalStore()
const loadingStore = useLoadingStore()

const specificCreator = import.meta.env.VITE_CREATOR_NO_SUB

const isSpecific = computed(() => {
    return parseInt(useCreatorStore().creator.id) === parseInt(specificCreator)
})

const hover = ref(false)
const isLive = ref(false)
const enableFollowButton = ref(false)
const isFollower = ref(false)

const emits = defineEmits(['avatar-changed', 'avatar-canceled'])

let tg = null

const clickChangeAvatar = debounce(() => {
    if (tg.isVisible) {
        tg.finishTour()
        .then(() => {
            document.getElementById('avatar-file').click();
        })
    }
    else{
        document.getElementById('avatar-file').click();
    }
}, 100)

function changeAvatar() {
    clickChangeAvatar()
}


const props = defineProps({
    isCreator: {
        type: Boolean,
        default: false
    },
    changeAvatar: {
        type: Boolean,
        default: false
    }
})

const unwatchAvatar = watch(
    () => props.changeAvatar,
    (changeAvatar) => {
        if (changeAvatar) {
            tg.start()
        }
    }
)

// Toggle following
function toggleFollow() {
    if (useUserStore().isGuest){
        useRegistrationCtaModalStore().showModal()
        return false;
    }

    // Unfollow
    if (isFollower.value) {
        formModalStore.showFormModal({
            title: t('dashboard.user.unfollow.title', { creator: '@' + creatorStore.creator.username }),
            fields: [],
            submit: t('dashboard.user.unfollow.submit'),
            cancel: t('dashboard.user.unfollow.cancel'),
            onSubmit: postFollowingStatus,
            onCancel: () => {},
        })
    } else {
        postFollowingStatus()
    }
}

// Process follow/unfollow
function postFollowingStatus() {
    // enableFollowButton.value = false
    // loadingStore.setLoading(true)
    formModalStore.hideFormModal()
    ApiService.post(`me/follow/${creatorStore.creator.id}`)
    .then(() => {
        isFollower.value = !isFollower.value
        userStore.fetchUser(false)
        .finally(() => {
            // loadingStore.setLoading(false)
            // router.go(0)
        })
    })
}

function checkLiveStatus() {
    ApiService.get(`my/${creatorStore.creator.id}/direct-live`)
    .then((response) => {
        // console.log(response)
        isLive.value = response.data
    })
    .catch(() => {
        isLive.value = {status: 'null'}
    })
}

const currentHeaderImg = ref(creatorStore.creator.headerImg?.thumb !== '' ? creatorStore.creator.headerImg.thumb : '/images/ikonstar-placeholder.jpg')
const currentUserHeaderImg = computed(() => userStore.user.headerImg !== '' ? userStore.user.headerImg : '/images/ikonstar-placeholder.jpg')

const headerImg = computed(() => {
    return props.isCreator
        ? `url('${currentUserHeaderImg.value}')`
        : `url('${currentHeaderImg.value}')`
})
function loadHeaderImage() {

    // currentHeaderImg.value = creatorStore.creator.headerImg.thumb !== ''
    //     ? creatorStore.creator.headerImg.thumb
    //     : '/images/masterclass_placeholder.jpg'

    let img = new Image();
    img.onload = () => {
        currentHeaderImg.value = img.src;
    }
    img.src = props.isCreator
        ? creatorStore.creator.headerImg.original
        : userStore.user.headerImg
}


function openLiveStream(_route, _params = null) {
    if (
        (userStore.cannot('goto') && _route === 'user-private-video-call-event')
        || (userStore.cannot('goto') && ['user-live-mobile', 'user-live'].includes(_route) && isLive.value.visible !== 'free' )
    ) {
        // console.log('CTA')
        ctaModalStore.showModal()
        return false
    }
    let redirect = {}
    redirect.name = userStore.user.preview !== undefined && userStore.user.preview ? 'preview-' + _route : _route
    if (_params) {
        redirect.params = _params
    }
    router.push(redirect)
}

function checkFollowStatus() {

    // Check if user is subscriber
    if (!userStore.inPreviewMode) {
        // if (!userStore.isGuest) {
            enableFollowButton.value = true
        // }
        // Check if user follow this creator
        userStore.user.creators_following?.map((_creator, key) => {
            if (_creator.id === creatorStore.creator.id) {
                isFollower.value = true
                // is not the first creator followed?
                if (_creator.is_ikonstar) {
                    enableFollowButton.value = false
                }
            }
        })

        // Check if user is subscribed on it
        let isSub = userStore.user.creators_subscribed_to?.find((creator) => creator.id === creatorStore.creator.id)
        if (isSub !== undefined) {
            // isFollower.value = true
            // enableFollowButton.value = false
        }
    }
}

onMounted(() => {
    checkLiveStatus()
    checkFollowStatus()
    loadHeaderImage()
})


let uppy = null
const avatarRef = ref(null)

function submitAvatar() {
    if (avatarRef.value.files && avatarRef.value.files[0]) {
        const file = avatarRef.value.files[0]

        loadingStore.setLoading(true, true)

        uppy.addFile(file)
        uppy.upload()
            .then((result) => {
                if (result.successful.length > 0) {
                    const data = result.successful[0]
                    ApiService.post('/me/set-avatar', {
                        path: data?.s3Multipart?.key,
                        name: data?.name,
                        type: data?.type,
                        size: data?.size,
                    })
                        .then((response) => {
                            useUserStore().setNewAvatar(response.data.avatar)
                            NotificationService.notifyUserSuccess(t('creator.creator.form.avatar.message.success'))
                            emits('avatar-changed')
                        })
                        .catch(() => {})
                        .finally(() => {
                            loadingStore.setLoading(false)
                        })
                    return;
                }
                loadingStore.setLoading(false)
            })
            .catch(() => {
                loadingStore.setLoading(false)
            })

        uppy.on('error', () => {
            loadingStore.setLoading(false)
        })
    }
}
const headerRef = ref(null)
function submitHeader() {
    if (headerRef.value.files && headerRef.value.files[0]) {
        const file = headerRef.value.files[0]

        loadingStore.setLoading(true, true)

        uppy.addFile(file)
        uppy.upload()
            .then((result) => {
                if (result.successful.length > 0) {
                    const data = result.successful[0]
                    ApiService.post('/me/set-header-img', {
                        path: data?.s3Multipart?.key,
                        name: data?.name,
                        type: data?.type,
                        size: data?.size,
                    })
                        .then((response) => {
                            useUserStore().setNewHeaderImg(response.data.headerImg)
                            NotificationService.notifyUserSuccess(t('creator.creator.form.headerImg.message.success'))
                        })
                        .catch(() => {})
                        .finally(() => {
                            loadingStore.setLoading(false)
                        })
                    return;
                }
                loadingStore.setLoading(false)
            })
            .catch(() => {
                loadingStore.setLoading(false)
            })

        uppy.on('error', () => {
            loadingStore.setLoading(false)
        })
    }
}


const calendarStore = useCalendarStore()
function createLiveStream(_route, _type) {

    return router.push({ name: _route, params: { eventId: 0 } })


    // loadingStore.setLoading(true)

    // // Check if Crator is active
    // if (ccreatorStore.creator.status !== 'active' || creatorStore.creator.stripe_account_id === null) {

    //     NotificationService.notifyUserError(t('globals.accountNotSetupLive'))
    //     loadingStore.setLoading(false)
    //     return
    // }

    // let _title = _type === 'live-streaming' ? 'live-stream-direct' : 'creator-ivs-channel'
    // let start = dayjs().format()
    // let end = dayjs().add(2, 'hour').format()

    // // Event information
    // let data = {
    //     creator_id: userStore.creator.ulid,
    //     title: _title,
    //     description: 'live stream',
    //     starting_at: start,
    //     ending_at: end,
    //     type: _type,
    //     status: 'pending',
    // }

    // // Api add event
    // calendarStore
    //     .add('', data)
    //     .then((result) => {
    //         let _eventId = result.data.id
    //         loadingStore.setLoading(false)
    //         return router.push({ name: _route, params: { eventId: _eventId } })
    //     })
    //     .catch(() => {
    //         loadingStore.setLoading(false)
    //         NotificationService.notifyUserError(t('calendar.global.notification.add.error'))
    //     })
}

onBeforeMount(() => {
    uppy = UppyService.init()
    tg = new TourGuideClient({
        dialogZ: 1030,
        debug: false,
        backdropClass: 'tour-backdrop',
        showStepDots: false,
        showButtons: false,
        showStepProgress: false,
        steps: [{
            content: t('creator.tutorial.avatar.content'),
            title: t('creator.tutorial.avatar.title'),
            target: "#creator-avatar-changer",
        }]
    });

    tg.onAfterExit(() => {
        emits('avatar-canceled')
    })
})

const removeDomClass = (_class) => {

    let elements = document.getElementsByClassName(_class)

    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
}
onUnmounted(() => {
    if (tg) {
        tg.destroyListeners()
        tg = null
    }
    removeDomClass('tg-dialog')
    removeDomClass('tg-backdrop')
    if (uppy) {
        uppy.close({reason: 'unmount'})
    }
    unwatchAvatar()
})

onDeactivated(() => {
    if (tg) {
        tg.destroyListeners()
        tg = null
    }
    removeDomClass('tg-dialog')
    removeDomClass('tg-backdrop')
    if (uppy) {
        uppy.close({reason: 'unmount'})
    }
    unwatchAvatar()
})
</script>

<style lang="scss">
.tour-backdrop {
    z-index: 1030;
}
.tg-backdrop{
    pointer-events: none;
}
</style>
<style lang="scss" scoped>
.le-flex {
    align-items: stretch;
    border: 0 solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    list-style: none;
    min-height: 0px;
    min-width: 0px;
    padding: 0px;
    position: relative;
    text-decoration: none;
    z-index: 0;
}
#header{
    width:100%;
    min-width:300px;
    height:250px;
    background-size:cover;
    background-position: center center;
    transition: all .08s linear;

    .header-container {
        .header-hover {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            color: white;
            background-color: rgba(0, 0, 0, 0.8);
            transition: 0.2s all;
        }
        &:hover .header-hover {
            opacity: 1;
        }
        .input {
            display: none;
        }
    }
}

#profile{
    display: flex;
    align-items: stretch;
    width:100%;
    min-width:300px;
    position:relative;
    box-sizing:border-box;
    padding-top:55px;
    padding-left:25px;
    font-family: 'Open Sans', sans-serif;


    .image {
        position:absolute;
        top:-70px;
        left: 20px;
        height:120px;
        width:120px;
        border-radius:50%;

        .avatar-wrapper {
            position: relative;
            width: 120px;
            height: 120px;
            overflow: hidden;
            border-radius: 50%;

            &.avatar-changer {
                .avatar-hover {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 120px;
                    opacity: 0;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    color: white;
                    background-color: rgba(0, 0, 0, 0.3);
                    transition: 0.2s all;
                    z-index: 1032;
                }
                &:hover .avatar-hover {
                    opacity: 1;
                }
            }
        }

        .input {
            display: none;
        }

        &.creator-is-live {
            top:-86px;
            cursor: pointer;
            overflow: hidden;
            border: 4px solid;
            border-color: #2cc57e;
            width: 132px;
            height: 132px;
            padding-top: 2px;
            padding-left: 3px;

            .avatar-wrapper {
                position: relative;
                width: 118px;
                height: 118px;
                overflow: hidden;
                border-radius: 50%;
                padding: 1px;

                img {
                    height:118px;
                    width:118px;
                    border: none;
                }

                div#status {
                    bottom: -2px;
                    left: 0;
                    width: 116px;
                    height: 30px;
                    font-size: 1em;
                    background-color: #2cc57e;
                    z-index: 5;
                    margin: 0px;
                    line-height: 26px;
                }
            }
        }

        img {
            border:2px solid white;
            width:inherit;
            height:inherit;
            border-radius:50%;
            object-fit: cover;
        }
    }
    .name{
        font-size:1.3rem;
        font-weight:500;
        //color:#444;
    }
    .nickname{
        //color:#888;
        font-size:0.9rem;
        padding-bottom:7px;
    }
    .location{
        color:#555;
        font-size:0.9rem;
        padding-left:0px;
        position:relative;
        left:-5px;
        .material-icons{
            position:relative;
            top:3px;
            font-size:1rem;
        }
    }
}

.shadow{
    box-shadow: 0px 0px 10px 1px rgba(black,0.5);
}

.overflow{
    overflow:hidden;
}

.following,
.followers{
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
    color:#bbb;
    font-weight:300;
}
.followers{
    float:right;
    padding-right:30px;
}
.bottom{
    margin-top:10px;
}

.count{
    color:black;
    font-family: 'Open Sans', sans-serif;
    font-size:0.9rem;
    font-weight:700;
}

.verified-badge {
    color: #1d9bf0;
}
.btn-unfollow-hover:hover, .btn-unfollow-hover:active {
    background-color: white !important;
    color: #e62c34 !important;
    border-color: #e62c34 !important;
    /*border-color: #641313;*/
}
</style>