<template>
    <section>
        <div class="row">
            <div class="col-12">
                <StarTitle tag="h2">
                    {{ $t('creator.planPerk.title.edit') }}
                </StarTitle>
            </div>
        </div>
        <Spacer size="0.938" />
        <div class="row">
            <div class="col-12">
                <div class="list-group mb-3">
                    <div v-for="(perk, key) in perks" :key="key" class="list-group-item list-group-item-action">
                        <div class="d-flex w-100 justify-content-between gap-3 p-0 m-0">
                            <div class="d-flex flex-column align-items-center justify-content-center">
                                <button class="btn btn-danger rounded btn-sm my-2" @click="deletePerk(perk)">
                                    <i class="fa fa-light fa-trash"></i>
                                </button>
                            </div>
                            <div class="d-flex flex-column justify-content-center flex-grow-1">
                                <div
                                    v-for="(lang, keyLang) in settingsStore.availableLanguages"
                                    :key="`text-perks-${keyLang}`"
                                    class="py-1"
                                >
                                    <i>{{lang.code.toUpperCase()}}</i>: {{ perk.text[lang.code] }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-end flex-column">
                                <button
                                    class="btn btn-tertiary rounded btn-sm my-2 text-nowrap"
                                    @click="editPerk(perk)"
                                >
                                    {{ $t('creator.planPerk.form.edit.button.edit.label', { lang: '' }) }}
                                </button>
<!--                                <div v-for="(lang, keyLang) in settingsStore.availableLanguages" :key="keyLang">-->
<!--                                    <button-->
<!--                                        class="btn btn-tertiary rounded btn-sm my-2 text-nowrap"-->
<!--                                        @click="editPerkByLang(perk, lang.code)"-->
<!--                                    >-->
<!--                                        {{ $t('creator.planPerk.form.edit.button.edit.label', { lang: lang.name_native }) }}-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                                <div></div>-->
                            </div>
                        </div>
                    </div>
                </div>

                <PrimaryButton :on-click="addPerk">
                    {{ $t('creator.planPerk.form.add.title') }}
                </PrimaryButton>
            </div>
        </div>
    </section>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import i18n from '@/translation'

import StarTitle from '@/components/Text/Title.vue'
import Spacer from '@/components/Spacer.vue'
import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'

import { useUserStore } from '@/stores/user'
import { useFormModalStore } from '@/stores/formModal'
import { useGlobalSettingsStore } from '@/stores/globalSettings'

import ApiService from '@/services/ApiService'
import NotificationService from '@/services/NotificationService'
import { string } from 'yup'

/** Locale **/
const { t } = i18n.global

/** Stores **/
const userStore = useUserStore()
const formModalStore = useFormModalStore()
const settingsStore = useGlobalSettingsStore()

/** Init paremeters **/
const loading = ref(true)
const perks = ref([])
const creatorId = userStore.creator.id
const userPreferredLanguage = userStore.preferredLanguage
const selectedPerk = ref(null)
const selectedLang = ref(null)

/** Methods **/
// Reload plan perks
function refreshData() {
    ApiService.get(`creator/${creatorId}/plan-perks`).then((response) => {
        perks.value = response.data
        loading.value = false
    })
}

// Modal to create a new plan perk
function addPerk() {
    formModalStore.showFormModal({
        title: t('creator.planPerk.form.add.title'),
        fields: [
            {
                type: 'text',
                name: 'text-en',
                label: t('creator.planPerk.form.add.text.label') + ` (EN)`,
                defaultValue: '',
                rules: userStore.currentLang === 'en'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'en',
            },
            {
                type: 'text',
                name: 'text-fr',
                label: t('creator.planPerk.form.add.text.label') + ` (FR)`,
                defaultValue: '',
                rules: userStore.currentLang === 'fr'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'fr',
            },
            {
                type: 'text',
                name: 'text-es',
                label: t('creator.planPerk.form.add.text.label') + ` (ES)`,
                defaultValue: '',
                rules: userStore.currentLang === 'es'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'es',
            },
        ],
        submit: t('creator.planPerk.form.edit.button.submit.label'),
        onSubmit: processPerk,
    })
}

// Process form to create a new plan perk
function processPerk(params) {

    ApiService.post(`creator/${creatorId}/plan-perks`, {
        creator_id: userStore.creator.ulid,
        text: Object.entries(params)
        .filter(([key, value]) => !!value)
        .reduce((obj, [key, value]) => ({...obj, [key.replace('text-','')]: value}), {})
    })
        .then(() => {
            formModalStore.hideFormModal()
            refreshData()
            NotificationService.notifyUserSuccess(t('creator.planPerk.form.add.message.success'))
        })
        .catch(() => {
            formModalStore.hideFormModal()
            NotificationService.notifyUserError(t('creator.planPerk.form.add.message.error'))
        })
}

function editPerk(perk, lang) {
    selectedPerk.value = perk
    selectedLang.value = lang

    formModalStore.showFormModal({
        title: t('creator.planPerk.form.edit.title', { lang: '' }),
        fields: [
            {
                type: 'text',
                name: 'text-en',
                label: t('creator.planPerk.form.add.text.label') + ` (EN)`,
                defaultValue: perk.text.en,
                rules: userStore.currentLang === 'en'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'en',
            },
            {
                type: 'text',
                name: 'text-fr',
                label: t('creator.planPerk.form.add.text.label') + ` (FR)`,
                defaultValue: perk.text.fr,
                rules: userStore.currentLang === 'fr'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'fr',
            },
            {
                type: 'text',
                name: 'text-es',
                label: t('creator.planPerk.form.add.text.label') + ` (ES)`,
                defaultValue: perk.text.es,
                rules: userStore.currentLang === 'es'
                    ? string().required().label(t('creator.planPerk.form.add.text.label'))
                    : string().nullable().label(t('creator.planPerk.form.add.text.label')),
                required: userStore.currentLang === 'es',
            },
        ],
        submit: t('creator.planPerk.form.edit.button.submit.label'),
        cancel: t('creator.planPerk.form.edit.button.cancel.label'),
        onSubmit: processPerkEdit,
    })
}
function editPerkByLang(perk, lang) {
    selectedPerk.value = perk
    selectedLang.value = lang

    formModalStore.showFormModal({
        title: t('creator.planPerk.form.edit.title', { lang: lang.toUpperCase() }),
        fields: [
            {
                type: 'text',
                name: 'text',
                label: t('creator.planPerk.form.edit.text.label'),
                defaultValue: perk.text[lang],
                rules: string().required().label(t('creator.planPerk.form.edit.text.label')),
                required: true,
            },
        ],
        submit: t('creator.planPerk.form.edit.button.submit.label'),
        cancel: t('creator.planPerk.form.edit.button.cancel.label'),
        onSubmit: processPerkEdit,
    })
}

function processPerkEdit(params) {
    ApiService.patch(`creator/${creatorId}/plan-perks/${selectedPerk.value.id}`, {
        creator_id: userStore.creator.ulid,
        text: Object.entries(params)
        .filter(([key, value]) => !!value)
        .reduce((obj, [key, value]) => ({...obj, [key.replace('text-','')]: value}), {})
    })
        .then(() => {
            formModalStore.hideFormModal()
            refreshData()
            NotificationService.notifyUserSuccess(t('creator.planPerk.form.edit.message.success'))
        })
        .catch(() => {
            formModalStore.hideFormModal()
            NotificationService.notifyUserError(t('creator.planPerk.form.edit.message.error'))
        })
}

function deletePerk(perk) {
    selectedPerk.value = perk

    formModalStore.showFormModal({
        title: t('creator.planPerk.form.delete.title'),
        fields: [],
        submit: t('creator.planPerk.form.delete.button.submit.label'),
        cancel: t('creator.planPerk.form.delete.button.cancel.label'),
        onSubmit: processDeletePerk,
    })
}

function processDeletePerk() {
    ApiService.delete(`creator/${creatorId}/plan-perks/${selectedPerk.value.id}`)
        .then(() => {
            formModalStore.hideFormModal()
            refreshData()
            NotificationService.notifyUserSuccess(t('creator.planPerk.form.delete.message.success'))
        })
        .catch(() => {
            formModalStore.hideFormModal()
            NotificationService.notifyUserError(t('creator.planPerk.form.delete.message.error'))
        })
}

onBeforeMount(() => {
    refreshData()
})
</script>