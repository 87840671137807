import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService'

const useVideoAssetStore = defineStore('videoAssets', () => {
    async function list(params = {}) {
        return ApiService.getAll('/video-assets', {
            params,
        })
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function create(payload) {
        return ApiService.post('/video-assets', payload)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function get(id) {
        return ApiService.get('video-assets', id)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function update(id, payload) {
        return ApiService.patch('video-assets/' + id, payload)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function deleteAsset(id) {
        return ApiService.delete('video-assets/' + id)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function creatorList(creatorId, params = {}) {
        return ApiService.getAll('/creator/' + creatorId + '/video-assets', {
            params,
        })
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    async function creatorGet(creatorId, id) {
        return ApiService.get('/creator/' + creatorId + '/video-assets', id)
            .then(({ data }) => data)
            .catch(({ data }) => {
                throw data.message
            })
    }

    return {
        list,
        create,

        get,
        update,
        deleteAsset,

        creatorList,
        creatorGet,
    }
})

export default useVideoAssetStore
